export default {
  control: {
    border: '2px solid transparent',
    borderRadius: 8,
    fontFamily: 'monospace',
    color: 'transparent',
    minHeight: 48,
    height: 48
  },

  highlighter: {
    border: '2px solid transparent',
    borderRadius: 8,
    color: 'transparent',
    fontFamily: 'monospace',
    minHeight: 48,
    height: 48
  },

  input: {
    color: 'blue',
    border: '2px solid #EBEEF0',
    borderRadius: 8,
    fontFamily: 'monospace',
    backgroundColor: 'field',
    minHeight: 48,
    height: 48
  },

  suggestions: {
    list: {
      borderRadius: 8,
      backgroundColor: 'white',
      border: '1px solid black',
      fontSize: 16,
    },

    item: {
      padding: '8px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',

      '&focused': {
        backgroundColor: '#2f3b52',
        color: 'white'
      },
    },
  }
}