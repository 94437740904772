import React from "react";
import { connect } from "react-redux";
import { createEntity, deleteEntity,/* , getEntityItems, */ partialEntity, readEntities, getEntityItems } from "../../redux/api";
import { loadCompanyUsers } from "../../redux/reducers/company-reducer";
import CompanyNotificationRow from "./company-notification-row";
import { groupFacilities } from "../../shared/utils";
import { validateObjPerms } from "../../redux/reducers/global-catalog.reducer";
// import CompanyAlertRow from "./company-alert-row";

class NotificationListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // openInviteModal: false,
      notifications: []
    };
  }

  componentDidMount() {
    if(this.props.companyType === 1){
      this.props.loadCompanyTags({ company_id: this.props.companyId });
    } else {
      this.props.loadCompanyFacilities({ company_id: this.props.companyId });
    }

    this.props.listNotificationCatalog({
      company_id: this.props.companyId
    }, {
      onSuccess: async response => {
        let result = response.notifications;
        await asyncForEach(result, async (notification) => {
          this.props.getDetailNotification({
            company_id: this.props.companyId,
            notification_id: notification.id,
            user_id: this.props.userId
          }, {
            onSuccess: r => {
              this.setState({
                notifications: this.state.notifications.concat([{
                  ...notification,
                  groups: r.groups.map( item => {
                    item.edit = false;
                    item.users = item.users.map( info => `${info}`)
                    item.facilities = item.facilities.map( info => `${info}`)
                    return item;
                  })
                }])
              })
            }
          });
        });
      }
    });
  }

  validShow = (perm) => {
    let response = this.props.validateObjPerms([perm], {
      display: true,
    });
    if (Object.keys(response).length) {
      return true;
    }
    return false;
  };

  render() {

    let allGroups = [];

    this.state.notifications.map( item => {
      allGroups = allGroups.concat(item.groups);
    });

    return (
      <React.Fragment>
        {
          this.state.notifications.sort((a, b) => a.id - b.id).map(notification => (
            <CompanyNotificationRow  
              notification={notification}
              onNotificationDataChange={this.handleNotificationDataChange}
              users={this.props.companyUsers}
              facilities={this.props.facilitiesByTag}
              takenFacilities={(allGroups).map(g => g.facilities || []).flat()}
              takenUsers={(allGroups).map(g => g.users || []).flat()}
              isLoading={this.props.isLoading}
              isLt={this.props.companyType === 1}
              updateActivated={this.validShow({
                id: "5",
                perm: "u",
              })}
              deleteActivated={this.validShow({
                id: "5",
                perm: "d",
              })}
              createActivated={this.validShow({
                id: "5",
                perm: "c",
              })}
            />
          ))
        }
      </React.Fragment>
    );
  }

  handleNotificationDataChange = (e, type, notificationId) => {
    let notifications = [];
    let notificationIndex = 0;
    let groupIndex = 0;
    switch(type) {
      case "active":
        notifications = this.state.notifications;
        notificationIndex = notifications.findIndex(a => a.id === notificationId.id);
        this.props.updateNotification({
          company_id: this.props.companyId,
          notification_id: notificationId.id,
          user_id: this.props.userId,
          action: 'activation',
          enable: !notifications[notificationIndex].is_active
        }, {
          onSuccess: r => {
            notifications[notificationIndex].is_active = !notifications[notificationIndex].is_active;
            this.setState({notifications});
          }
        }) 
        break;
      case "user":
        notifications = this.state.notifications;
        notificationIndex = notifications.findIndex(a => a.id === notificationId.id);
        groupIndex = notifications[notificationIndex].groups.findIndex(a => a.id === notificationId.group);
        notifications[notificationIndex].groups[groupIndex].users = e.target.value
        this.setState({notifications});
        break;
      case "facility":
        notifications = this.state.notifications;
        notificationIndex = notifications.findIndex(a => a.id === notificationId.id);
        groupIndex = notifications[notificationIndex].groups.findIndex(a => a.id === notificationId.group);
        notifications[notificationIndex].groups[groupIndex].facilities = e.target.value
        this.setState({notifications});
        break;
      case "edit":
        notifications = this.state.notifications;
        notificationIndex = notifications.findIndex(a => a.id === notificationId.id);
        groupIndex = notifications[notificationIndex].groups.findIndex(a => a.id === notificationId.group);
        notifications[notificationIndex].groups[groupIndex].edit = !notifications[notificationIndex].groups[groupIndex].edit;
        this.setState({notifications});
        break;
      case "new_group":
        notifications = this.state.notifications;
        notificationIndex = notifications.findIndex(a => a.id === notificationId.id);

        this.props.createNewGroup({
          company_id: this.props.companyId,
          notification_id: notificationId.id,
          user_id: this.props.userId,
          action: 'new_group'
        }, {
          onSuccess: r => {
            if (r.notification_group_id) {
              notifications[notificationIndex].groups.push({
                id: r.notification_group_id,
                users: [],
                facilities: [],
                edit: true
              });

              this.setState({notifications});
            }
          }
        });
        break;
      case "delete":
        notifications = this.state.notifications;
        notificationIndex = notifications.findIndex(a => a.id === notificationId.id);

        this.props.deleteGroup({
          company_id: this.props.companyId,
          group_id: notificationId.group,
        }, {
          onSuccess: r => {
            notifications[notificationIndex].groups = notifications[notificationIndex].groups.filter(item => item.id !== notificationId.group);
            this.setState({notifications});
          }
        })
        break;
      case "cancel":
        notifications = this.state.notifications;
        notificationIndex = notifications.findIndex(a => a.id === notificationId.id);
        groupIndex = notifications[notificationIndex].groups.findIndex(a => a.id === notificationId.group);

        this.props.getDetailNotification({
          company_id: this.props.companyId,
          notification_id: notificationId.id,
          user_id: this.props.userId
        }, {
          onSuccess: r => {
            (r.groups || []).map (item => {
              if (item.id === notificationId.group) {
                notifications[notificationIndex].groups[groupIndex] = {id: item.id, edit: false, users: item.users, facilities: item.facilities}
                this.setState({notifications});
              }
            });
          }
        });
        break;
      case "save": 
        notifications = this.state.notifications;
        notificationIndex = notifications.findIndex(a => a.id === notificationId.id);
        groupIndex = notifications[notificationIndex].groups.findIndex(a => a.id === notificationId.group);
        
        let users = notifications[notificationIndex].groups[groupIndex].users.map( item => Number(item))
        let facilities = notifications[notificationIndex].groups[groupIndex].facilities.map( item => Number(item))

        this.props.updateGroup({
          company_id: this.props.companyId,
          group_id: notificationId.group,
          users, 
          facilities
        }, {
          onSuccess: r => {
            notifications[notificationIndex].groups[groupIndex].edit = false;
            this.setState({notifications});
          }
        });
        break;
      default:
    }
  }
}

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

const mapStateToProps = state => {
  const companyFacilities = getEntityItems(state, "COMPANIES.FACILITIES");
  const facilitiesByTag = groupFacilities(companyFacilities);

  const companyTags = getEntityItems(state, "COMPANIES.TAGS");
  const baseTags = companyTags.filter(t => t.type === 3);

  const isLoadingDetails = state?.api['COMPANIES.NOTIFICATIONS.DETAILS']?.status?.isFetching || false;

  let usersByTag = [
    { id: 9999999999, description: "Sin rol", items: [] },
  ];
  if(state.companyReducer.companyUsers.data && Array.isArray(state.companyReducer.companyUsers.data)){
    (state.companyReducer.companyUsers.data || []).forEach((u) => {
      if(u.role){
        let tagIndex = usersByTag.map((uT) => uT.id).indexOf(u.role);
          if (tagIndex !== -1) {
            usersByTag[tagIndex].items.push({
              id: u.users,
              description: u.username,
            });
          } else {
            let newTag = {
              id: u.role_detail.id,
              description: u.role_detail.description,
              items: [
                {
                  id: u.users,
                  description: u.username,
                },
              ],
            };
            usersByTag.push(newTag);
          }
      } else {
        usersByTag[0].items.push({
          id: u.users,
          description: u.username,
        });
      }
    });
    
    usersByTag = usersByTag.map((tag) => {
      let sortedItems = tag.items.sort((a, b) => {
        if (a.description.toLowerCase() > b.description.toLowerCase()) {
          return 1;
        }
        if (a.description.toLowerCase() < b.description.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      return { ...tag, items: sortedItems };
    });
    
    let tagless = usersByTag.shift();
    
    usersByTag = usersByTag.sort((a, b) => {
      if (a.description.toLowerCase() > b.description.toLowerCase()) {
        return 1;
      }
      if (a.description.toLowerCase() < b.description.toLowerCase()) {
        return -1;
      }
      return 0;
    });
    
    usersByTag.push(tagless);
  
    // return usersByTag;
  }
  
  return {
    facilitiesByTag,
    baseTags,
    companyUsers: usersByTag,
    companyId: state.globalCatalog.session.company.id,
    userId: state.globalCatalog.session.user.id,
    isLoading: isLoadingDetails,
    companyType: state.globalCatalog.session.company.type.id,
  };
};

const mapDispatchToProps = dispatch => {
  dispatch(loadCompanyUsers());
  return {
    loadCompanyTags: (params, opts) => dispatch(readEntities("COMPANIES.TAGS", params, opts)),
    loadCompanyFacilities: (params, opts) => dispatch(readEntities("COMPANIES.FACILITIES", params, opts)),
    listNotificationCatalog: (params = {}, opts = {}) =>
      dispatch(
        readEntities(
          "COMPANIES.NOTIFICATIONS",
          { ...params },
          { args: { ...params }, ...opts }
        )
      ),
    getDetailNotification: (params = {}, opts = {}) =>
      dispatch(
        readEntities(
          "COMPANIES.NOTIFICATIONS.DETAILS",
          { ...params },
          { args: { ...params }, ...opts }
        )
      ),
    createNewGroup: (params, opt) => dispatch(createEntity("COMPANIES.NOTIFICATIONS.DETAILS", params, opt)),
    deleteGroup: (params, opt) => dispatch(deleteEntity("COMPANIES.NOTIFICATIONS.DETAILS", params, opt)),
    updateGroup: (params, opt) => dispatch(partialEntity("COMPANIES.NOTIFICATIONS.DETAILS", params, opt)),
    updateNotification: (params, opt) => dispatch(partialEntity("COMPANIES.NOTIFICATIONS", params, opt)),
    listNotificationUsers: (params = {}, opts = {}) =>
      dispatch(
        readEntities(
          "COMPANIES.NOTIFICATIONS.USERS",
          { ...params },
          { args: { ...params }, ...opts }
        )
      ),
    createNotificationUsers: (params = {}, opts = {}) =>
      dispatch(
        createEntity(
          "COMPANIES.NOTIFICATIONS.USERS",
          { ...params },
          { args: { ...params }, ...opts }
        )
      ),
    validateObjPerms: (perms, obj) => validateObjPerms(perms, obj),
  };
};

const connectToStore = connect(mapStateToProps, mapDispatchToProps);

export default connectToStore(NotificationListView);
