import * as React from "react";
import moment from "moment";
import styled from "styled-components";
import ServiceDetailRow from "./service-detail-row";
import TableD from "../../shared/component/table-d";
import Caret from "../../shared/component/caret";
import InfiniteScroll from "react-infinite-scroll-component";

class ServiceDetailSearchResultRow extends React.Component {
  state = {
    showDetail: false
  }

  toggleShowDetail = () => {
    this.setState({showDetail: !this.state.showDetail})
  }

  render() {
    return(
      <div style={{border: "1px solid #ffffff73"}}>
        <ParentContainer>
          <div style={{width: "5%", textAlign: "center", cursor: "pointer"}} onClick={this.toggleShowDetail}>
            <Caret up={!this.state.showDetail} fill="#AFC8FF" />
          </div>
          <div>
            {this.props.order}
          </div>
          <div style={{paddingLeft: "30px"}}>
            {`   ${(this.props.detail || []).length} resultados encontrados`}
          </div>
        </ParentContainer>
        {
          ((this.props.detail || []).length && this.state.showDetail)
          ? this.props.isSingle
            ? <div 
                id="scrollableDiv" 
                style={{ 
                  width: "100%", 
                  display: "flex", 
                  flexDirection: "column" ,
                  maxHeight: "calc(100% - 117px)",
                  overflowY: "auto",
                }}
              >
                <InfiniteScroll
                  dataLength={(this.props.resultLength)}
                  next={this.props.scrollCallback}
                  hasMore={this.props.resultLength > (this.props.detail || []).length}
                  // hasMore={true}
                  loader={<h4>Cargando...</h4>}
                  // height={'100%'}
                  scrollableTarget="scrollableDiv"
                >
                    {
                      (this.props.detail || []).map((wS) => (
                        <ServiceDetailRow  
                          key={`transaction-row-${wS._id?.$oid || 0}`}
                          data={{
                            ...wS,
                            date: moment(wS.created).format('DD/MM/YYYY HH:mm:ssSSS (utc Z)'),
                          }}
                          openDetail={() => this.props.openDetail(wS._id?.$oid || 0, wS)}
                          downloadFile={(callback, type) => {this.props.downloadFile(wS._id?.$oid || 0, callback, type)}}
                          tableConfig={this.props.TABLECONFIG}
                        />
                      ))
                    }
                </InfiniteScroll>
              </div>
            : <TableD 
                tableConfig={{
                  ...this.props.TABLECONFIG,
                  headerStyle: {display: "none"}
                }}
              >
                {
                  (this.props.detail || []).map((wS) => (
                    <ServiceDetailRow  
                      key={`transaction-row-${wS._id?.$oid || 0}`}
                      data={{
                        ...wS,
                        date: moment(wS.created).format('DD/MM/YYYY HH:mm:ssSSS (utc Z)'),
                      }}
                      openDetail={() => this.props.openDetail(wS._id?.$oid || 0, wS)}
                      downloadFile={(callback, type) => {this.props.downloadFile(wS._id?.$oid || 0, callback, type, wS.file)}}
                    />
                  ))
                }
              </TableD>
          : ''
        }
      </div>
    )
  }
}

export default ServiceDetailSearchResultRow;

const ParentContainer = styled.div`
  align-items: center;
  display: flex;
  height: 35px;
  color: #fff;
  font-weight: bold;
  background-color: #3d5074;
  border: 
`;