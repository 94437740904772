//@flow
import * as React from "react";
import { ViewTitle, View } from "../../shared/styled/view";
import FilterBarCompact from "../../shared/component/filter/filter-bar-compact";
import TabSelectorRibbon from "../../shared/controls/tab-selector-ribbon";
import TableD from "../../shared/component/table-d";
import { connect } from "react-redux";
import {
  readCatalog,
  ApiOptions,
  partialEntity,
  readEntity,
  readEntities,
  createEntity,
  cancelRequest,
} from "../../redux/api";
import { getEntityItems } from "../../redux/api/helpers";
import AssembleShipmentListRow from "./assemble-shipment-list-row";
import * as Types from "./types";
import * as Utils from "./utils";
import PaginatorControlled from "../../shared/controls/paginator-controlled";
import AssembleShipmentAssignDialog from "./assemble-shipment-assign-dialog";
import { AssembleAvatar } from "../assemble/assemble-avatar";
import ReactTooltip from "react-tooltip";
import { getAssembleIconByAssemble } from "../shipment/unassigned/utils";
import BaseDialog from "../../shared/dialog/base-dialog";
import Checkbox from "../../shared/controls/checkbox";
import FinalizeTripModal from "../shipment/detail/finalize-trip-modal";
import FinalizeOrderModal from "../shipment/detail/finalize-order-modal";
import RadioGroup from "../../shared/component/radio-group";
import Modal from "react-modal";
import Button from "../../shared/controls/button";
import { SERVER } from "../../redux/api/api-urls";
import Axios from "axios";
import styled from "styled-components";
import Caret from "../../shared/component/caret";
import { setConfig } from "../../redux/reducers/configuration-reducer";
import ResetTripModal from "../shipment/detail/reset-trip-modal";
import StartTripModal from "../shipment/detail/start-trip-modal";
import SelectInputStyled from "../../shared/controls/select-input-styled";
import { isArray } from "lodash";
import * as moment from "moment";
import { setOrderSeleted } from '../../redux/reducers/global-catalog.reducer';
import Loader from "../../shared/icons/tat/loader";
import RadioInput from "../../shared/controls/radio-input";
import { getTimeZone, handleWSError } from '../../shared/utils';
import Selector from "../../shared/component/selector";
import ShipmentBoardRow from "../../shared/component/shipment-board-row";
import CatalogSelectionFooter from "../../shared/component/catalog-selection-footer";
import Tag from "../../shared/controls/tag";
import HelperFilesModal from '../../shared/component/helper_files_modal';
import { setNavbarAction, removeNavbarAction } from "../../redux/reducers/navbar-reducer";
import UserChipContainer from '../../shared/component/user-chip-container';
import ConfirmDialog from '../../shared/dialog/confirm-dialog';
import ErrorDialog from '../../shared/dialog/error-dialog';

type OwnProps = {|
  viewMode: "grid" | "list",
  onChange: () => void,
|};
type StateToProps = {|
  companyId: number,
  tripItemCount: number,
  tripRows: Types.AssembleShipmentListRowType[],
  companyData: { logo: string },
  tripBookingAvailability: any,
|};
type DispatchToProps = {|
  listTrips: (
    params: {
      company_id: number,
      view: string,
      offset: number,
      limit: number,
      status: string,
    },
    opts: ApiOptions
  ) => void,
  updateTrip: (
    params: {
      company_id: number,
      trip_id: number,
      action: string,
    },
    opts: ApiOptions
  ) => void,
  loadTrip: (
    params: {
      company_id: number,
      trip_id: number,
      view: string,
    },
    opts: ApiOptions
  ) => void,
  loadFilterFacilities: (
    params: {
      company_id: number,
      view: string,
      offset: number,
      limit: number,
      status: string,
    },
    opts: ApiOptions
  ) => void,
|};
type Props = {|
  ...StateToProps,
  ...DispatchToProps,
  ...OwnProps,
|};

type State = {
  limit: number,
  offset: number,
  showAssignDialog: boolean,
  currentTrip: any,
  selectedAssembly: number,
  textSearch: string,
  searchStr: string,
};

class AssembleShipmentList extends React.Component<Props, State> {
  state = {
    limit: 10,
    offset: 1,
    isLoadingTrips: false,
    isLoading: false,
    showAssignDialog: false,
    showConfirmDialog: false,
    showFinalizeOrderModal: false,
    showFinalizeTripModal: false,
    showResetTripModal: false,
    showStartTripModal: false,
    currentTrip: {},
    selectedAssembly: 0,
    textSearch: "",
    // filterParams: Utils.DefaultFilters,
    searchType: "1",
    getTripsCalled: false,
    buttonFilterName: "Aplicar",
    filterParams: {
      status: [],
      orderStatus: [],
      transportClass: [],
      fleet_providers: [],
      from_facilities: [],
      to_facilities: [],
      from_to_facilities: [],
      appointment: [],
      appointment_type: [],
      vehicle: [],
      baseTags: []
    },
    vehiclesToShow: [],
    searchStr: "",
    emails: [],
    hasAutoStart: true,
    selectedEquipment: 0,
    showSelectEquipmentModal: false, 
    openXlsErrorModal: false,
    showDonateModal: false,
    folioSelected: '',
    folioDataSelected: null,
    secondFolioSelected: '',
    secondFolioDataSelected: null,
    folios: [],
    containersSelected: '',
    transferData: null,
    firstContainerSelected: '',
    secondContainerSelected: '',
    transferEventsError: '',
    transferEventsSuccess: '',
    descriptionImport: [],
    tripIdToTransfer: '',
    collapseAll: true,
    updateTripsError: '',
    showError: false,
    isRestartingLoading: false,
    isTransferingEvents: false,
    showErrorMessage: '',
    
    selectionModeOn: false,
    selectedTripsId: [],

    selectedBaseTags: [],
    showEditBaseModal: false,
    showConfirmEditBaseModal: false,
    showSuccessModal: false,
    isHovered: false,
    openFinalizeTripModal: false,
    tripsToFinalize: 0,
    showSuccessFinalizeTripsModal: false,
    downloadingFile: false,
    showErrorMessageModal: null,
    msgError: ''
  };

  tripsCounter = 0;

  componentDidMount() {
    document.addEventListener("keydown", this.cancelSearch);

    this.props.setNavbarAction("assemblies-control", [
      {
        icon: <HelperFilesModal
          files={[]}
        />
      }
    ]);

    this.props.loadOrderStatus();

    this.props.loadCompanyTags({
      company_id: this.props.companyId
    });

    this.props.loadCompanyProviders({
      company_id: this.props.companyId,
      view: "booking",
    });

    this.props.loadTransportClass({
      company_id: this.props.companyId,
    });

    let filterParams = {
      ...this.state.filterParams,
      status: ["1", "5", "8", "3,4,10"],
    };

    if(this.props.tabFilters){
      filterParams = {...this.props.tabFilters}
    } else if(this.props.configuration?.filterParams){
      filterParams = {...this.props.configuration?.filterParams}
    } else {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      // debugger;
      let base = urlParams.get('base');
      if(base) {
        base = base.split(",");
        filterParams = {
          ...filterParams,
          baseTags: base
        }
        // this.setState({filterParams: {
        //   ...filterParams,
        //   baseTags: base
        // }})
      } else {
        let fleet_provider = urlParams.get('fleet_provider');
        if(fleet_provider){
          fleet_provider = fleet_provider.split(",");
          filterParams = {
            ...filterParams,
            fleet_providers: fleet_provider
          }
          // this.setState({filterParams: {
          //   ...filterParams,
          //   fleet_providers: fleet_provider
          // }})
        } else {
          let urlFacilities = urlParams.get('facilities');
          if(urlFacilities) urlFacilities = urlFacilities.split(",")
          filterParams = {
            ...filterParams,
            from_to_facilities: urlFacilities || []
          }
          // this.setState({filterParams: {
          //   ...filterParams,
          //   from_to_facilities: urlFacilities || []
          // }});
        }
      }
    }
    
    this.setState({ 
      ...this.props.configuration,  
      filterParams
    }, () =>
      this.props.loadFilterFacilities(
        {
          company_id: this.props.companyId,
          view: "controls",
          offset: this.state.offset,
          limit: this.state.limit,
          // status: "1,3,4,5",
          status: this.state.filterParams.status,
          fleet_providers: this.state.filterParams.fleet_providers,
          from_facilities: this.state.filterParams.from_facilities,
          to_facilities: this.state.filterParams.to_facilities,
          search: this.state.searchStr,
          appointment: this.state.filterParams.appointment[0] ? this.state.filterParams.appointment[0].toISOString() : "",
          appointment_type: (this.state.filterParams.appointment_type || []).join(",") || "1,2,3", //default todos los tipos, sin mostrar en interfaz
        },
        {
          onSuccess: (response) => {
            this.setState({
              originFacilities: response.from_facilities.map((facility) => ({
                id: facility.id,
                description: facility.name.concat(
                  " - ",
                  facility.code,
                  " - ",
                  facility.alias
                ),
              })),
              destinationFacilities: response.to_facilities.map((facility) => ({
                id: facility.id,
                description: facility.name.concat(
                  " - ",
                  facility.code,
                  " - ",
                  facility.alias
                ),
              })),
            });
          },
        }
      )
    );

    this.props.listVehicles(
      {
        company_id: this.props.companyId
      },
      {
        onSuccess: (vehiclesData) => {
          let newIndex = 9999999999;
          let vehiclesToShow = [];

          for (let i = 0; i < vehiclesData.length; i++) {

            let dataEconomic = vehiclesData[i];
            let vehicles = (dataEconomic.economic_numbers || []).map( (f) => ({
              id: f.id || 0,
              description: f.economic_number || '',
              isDisabled: !f.is_assembled || false,
              disableMessage: 'Camion sin ensamble'
            }));

            if (vehicles.length > 0) {
              vehiclesToShow.push({
                id: newIndex,
                description: `${dataEconomic.title || ''}` === 'N/A' ? 'Sin base' : `${dataEconomic.title || ''}`,
                items: vehicles
              });
              newIndex = newIndex - 1;
            }

          }

          this.setState({
            vehiclesToShow
          });

          this.loadTrips();
        },
        onError: (error) => {
          this.loadTrips();
        }
      }
    );
  }
  
  componentWillUnmount() {
    // console.log("DELETING TRIPS");
    // debugger;
    this.props.removeNavbarAction("assemblies-control");
    this.props.setBaseConfig({ ...this.state, getTripsCalled: false});
    
    document.removeEventListener("keydown", this.cancelSearch)
  }

  cancelSearch = (e) => {
    if(e.key === "Escape"){
      // console.log("CANCELLLIIIIIING");
      if(this.state.isLoadingTrips){
        this.props.cancelRequest("tripList");
        this.setState({isLoadingTrips: false});
      }
    }
  }

  // manageError = (err) => {
  //   this.setState({openXlsErrorModal: true, descriptionImport: err})
  // }
  closeXlsErrorModal = () => {
    this.setState({
      openXlsErrorModal: false,
      descriptionImport: []
    });
  };

  loadTrips = () => {
    this.setState({isLoadingTrips: true});
    
    this.props.cancelRequest("tripList");
    if(this.state.searchStr){
      this.props.listTrips(
        {
          company_id: this.props.companyId,
          view: "controls",
          offset: this.state.offset,
          limit: this.state.limit,
          // status: "1,3,4,5",
          status: '', //this.state.filterParams.status,
          fleet_providers: '', //this.state.filterParams.fleet_providers,
          from_facilities: '', //this.state.filterParams.from_facilities,
          to_facilities: '', //this.state.filterParams.to_facilities,
          from_to_facilities: '', //this.state.filterParams.from_to_facilities || [],
          search: this.state.searchStr,
          search_type: this.state.searchType === "1" ? 'folio' : this.state.searchType  === "2" ? 'vehiculo' : 'placas',
          appointment: '', /*this.state.filterParams.appointment[0] 
            ? "".concat(this.state.filterParams.appointment[0].toISOString(), ",", this.state.filterParams.appointment[1] ? this.state.filterParams.appointment[1].toISOString() : this.state.filterParams.appointment[0].toISOString())
            : "",*/
          appointment_type: '',//(this.state.filterParams.appointment_type || []).join(",") || "1,2,3",//default todos sin mostrar en interfaz
          orderStatus: '',//this.state.filterParams.orderStatus,
          baseTags: '',//this.state.filterParams.baseTags,
          vehicle: '',//this.state.filterParams.vehicle,
          transport_classes: '',//this.state.filterParams.transportClass || '',
          timezone: getTimeZone(),
          action: ''
        },
        { 
          alias: "tripList",
          onSuccess: () => {
            this.setState({isLoadingTrips: false, getTripsCalled: true});
          }
        }
      );
    } else {
      this.props.listTrips(
        {
          company_id: this.props.companyId,
          view: "controls",
          offset: this.state.offset,
          limit: this.state.limit,
          // status: "1,3,4,5",
          status: this.state.filterParams.status, 
          fleet_providers: this.state.filterParams.fleet_providers,
          from_facilities: this.state.filterParams.from_facilities,
          to_facilities: this.state.filterParams.to_facilities,
          from_to_facilities: this.state.filterParams.from_to_facilities || [],
          search: this.state.searchStr,
          search_type: '',
          appointment: this.state.filterParams.appointment[0] 
            ? "".concat(this.state.filterParams.appointment[0].toISOString(), ",", this.state.filterParams.appointment[1] ? this.state.filterParams.appointment[1].toISOString() : this.state.filterParams.appointment[0].toISOString())
            : "",
          appointment_type: (this.state.filterParams.appointment_type || []).join(",") || "1,2,3",//default todos sin mostrar en interfaz
          orderStatus: this.state.filterParams.orderStatus,
          baseTags: this.state.filterParams.baseTags,
          vehicle: this.state.filterParams.vehicle,
          transport_classes: this.state.filterParams.transportClass || '',
          timezone: getTimeZone(),
          action: ''
        },
        { alias: "tripList",
          onSuccess: () => {
            this.setState({isLoadingTrips: false, getTripsCalled: true}); 
          }
        }
      );
    }
  };
  loadTripBookingAvailability = () => {
    this.props.loadTrip(
      {
        company_id: this.props.companyId,
        trip_id: this.state.currentTrip.id,
        view: "booking",
      },
      {
        alias: "TripBookingAvailability",
      }
    );
  };
  acceptTripAction = () => {
    this.props.updateTrip(
      {
        company_id: this.props.companyId,
        trip_id: this.state.currentTrip.id,
        has_automatic_start: this.state.hasAutoStart ? "true" : "false",
        action: "confirm_booking",
      },
      { onSuccess: () => {this.loadTrips(); this.setState({showConfirmDialog: false, hasAutoStart: true, currentTrip: undefined})} }
    );
  }
  acceptTrip = (trip) => {
    this.props.getTripBalance({
      company_id: this.props.companyId,
      trip_id: trip.id
    },
    {
      onError: (e) => {
        let err = e.response?.data?.errors || []; 
        this.manageError(err);
      },
      onSuccess: () => {
        this.setState({
          currentTrip: trip,
          showConfirmDialog: true
        })
      }
    });
  };
  manageError = (err) => {
    this.setState({openXlsErrorModal: true, descriptionImport: err})
  }

  rejectTrip = (tripId: number) => {
    this.props.updateTrip(
      {
        company_id: this.props.companyId,
        trip_id: tripId,
        action: "reject_booking",
      },
      { onSuccess: this.loadTrips }
    );
  };
  cancelTrip = (tripId: number) => {
    this.props.updateTrip(
      {
        company_id: this.props.companyId,
        trip_id: tripId,
        // action: "cancel_booking",
        action: "reject_booking",
      },
      { onSuccess: this.loadTrips,
        onError: (error) => {
          this.setState({
            showErrorMessage: handleWSError(error.response?.data ?? {}),
          })
        } 
      }
    );
  };

  assignTrip = (trip: Types.AssembleShipmentListRowType) => {

    // let details = trip?.details || [];
    // let isValidDate = true;
    // if (isArray(details) && details.length > 0) {

    //   let origin = details[0]?.origin?.date;

    //   if (origin !== null && origin !== undefined && moment(origin).isBefore(moment(new Date()))) {
    //     isValidDate = false;
    //   }

    // }

    // if (isValidDate) {
      this.manageAssignModal({ target: { name: "open", value: trip } });
    // }
    
  };

  finalizeTrip = (trip) => {
    this.setState({
      showFinalizeTripModal: true,
      currentTrip: trip,
    })
  }

  resetTrip = (trip) => {
    this.setState({
      showResetTripModal: true,
      currentTrip: trip,
    })
  }

  startTrip = (trip) => {
    this.setState({
      showStartTripModal: true,
      currentTrip: trip
    })
  }

  finalizeOrder = (trip, order) => {
    this.setState({
      showFinalizeOrderModal: true,
      currentTrip: {...trip, maneuver: (trip.details.find(d => d.ticket === order) || {}), ticket: order},
    })
  }

  transferEvents = (trip, order) => {

    this.setState({
      showDonateModal: true
    });

    const { id = '' } = trip;

    this.props.getCandidates({
      company_id: this.props.companyId,
      trip_id: id,
    },
    {
      onSuccess: (val) => {

        const { candidates = [], donor: { equipments = [] } } = val;
        var candidatesToSave = []

        for (var i = 0; i < candidates.length; i++) {
          const { trip_id = '', code = '', from_facility, to_facility, containers } = candidates[i];
          candidatesToSave = candidatesToSave.concat({ id: trip_id, description: code, from_facility, to_facility, containers });
          //console.log({ id: trip_id, description: code, from_facility, to_facility, containers });
        }

        this.setState({
          containersSelected: equipments.map( item => {
            const { id, economic_number } = item;
            return `${economic_number}`;
          }).join(', '),
          transferData: val,
          folios: candidatesToSave,
          tripIdToTransfer: id
        });

      },
    });

  }

  resetTransferData = () => {
    this.setState({
      showDonateModal: false,
      folioSelected: '',
      folioDataSelected: null,
      secondFolioSelected: '',
      secondFolioDataSelected: null,
      folios: [],
      containersSelected: '',
      transferData: null,
      firstContainerSelected: '',
      secondContainerSelected: '',
      transferEventsSuccess: '',
      transferEventsError: ''
    })
  }

  manageCancelDialogs = (e) => {
    const { name, value } = e.target;
    switch(name) {
      case "resetTrip": 
        this.setState({
          isRestartingLoading: true
        });
        this.props.resetTrip(
          {
            company_id: this.props.companyId,
            trip_id: this.state.currentTrip.id,
          },
          {
            onSuccess: () => {
              this.setState({
                showResetTripModal: false,
                currentTrip: undefined,
                isRestartingLoading: false
              });
              this.loadTrips();
            },
            onError: (error) => {
              this.setState({
                showResetTripModal: false,
                showErrorMessage: handleWSError(error.response?.data ?? {}),
                isRestartingLoading: false
              })
            }
          }
        );
        break;
      case "startTrip": 
        this.props.loadStatusTrip(
          this.props.companyId,
          this.state.currentTrip.id,
          {
            onSuccess: (data) => {
              if (data && data.trip_status < 5) {
                
                this.props.startTrip(
                  {
                    company_id: this.props.companyId,
                    trip_id: this.state.currentTrip.id,
                  },
                  {
                    onSuccess: () => {
                      this.setState({
                        showStartTripModal: false,
                        currentTrip: undefined,
                      });
                      this.loadTrips();
                    },
                    onError: () => {
                      this.setState({ 
                        showError: true,
                        showStartTripModal: false,
                        currentTrip: undefined,
                      });
                    }
                  }
                );

              } else {
                this.setState({ 
                  showError: true,
                  showStartTripModal: false,
                  currentTrip: undefined,
                });
              }
            },
            onError: (error) => {
              this.setState({showErrorMessageModal: 'Ocurrió un error, intente de nuevo'});
            }
          }
        );
        break;
      case "finalizeTrip":
          this.props.finalizeTrip(
            {
              company_id: this.props.companyId,
              trip_id: this.state.currentTrip.id,
            },
            {
              onSuccess: () => {
                this.setState({
                  showFinalizeTripModal: false,
                  currentTrip: undefined,
                });
                this.loadTrips();
              },
              onError: (error) => {
                this.setState({
                  showFinalizeTripModal: false,
                  showErrorMessage: handleWSError(error.response?.data ?? {}),
                  currentTrip: undefined,
                })
              }
            }
          );
          break;
      case "finalizeOrder":
        // console.log(name, value);
        this.props.finalizeOrder(
          {
            company_id: this.props.companyId,
            id: this.state.currentTrip.maneuver.id,
            view: value,
          },
          {
            onSuccess: () => {
              this.setState({
                showFinalizeOrderModal: false,
                currentTrip: undefined,
              });
              this.loadTrips();
            },
            onError: (error) => {
              this.setState({
                showFinalizeOrderModal: false,
                showErrorMessage: handleWSError(error.response?.data ?? {}),
                currentTrip: undefined,
              })
            }
          }
        );
        break;
      case "close":
      case "cancel":
        this.setState({
          showFinalizeOrderModal: false,
          showFinalizeTripModal: false,
          showResetTripModal: false,
          showStartTripModal: false,
        });
        break;
      default: 
      this.setState({ [name]: value });
    }
  }

  manageAssignModal = (e: any) => {
    const { name, value } = e.target;
    switch (name) {
      case "open":
        this.setState(
          { showAssignDialog: true, currentTrip: value },
          this.loadTripBookingAvailability
        );
        break;
      case "close":
      case "cancel":
        this.setState({ showAssignDialog: false, currentTrip: undefined, selectedAssembly: 0, hasAutoStart: true });
        break;
      case "save":
        let equipmentList = this.props.tripBookingAvailability
          .find(a => a.id.toString() === this.state.selectedAssembly)
          .equipments.filter(e => e.type.toLowerCase() !== "dolly");
        if(this.state.currentTrip.details.some(d => d.orderContainers.length < equipmentList.length)){
          this.setState({
            showAssignDialog: false,
            showSelectEquipmentModal: true,
          })
        } else {
          this.setState({isLoading: true});
          this.props.updateTrip(
            {
              company_id: this.props.companyId,
              trip_id: value.tripId,
              assembly: value.assembly,
              has_automatic_start: this.state.hasAutoStart ? "true" : "false",
              action: "set_assembly",
            },
            { 
              onSuccess: () => {
                this.loadTrips();
                  this.state.currentTrip.details.forEach(d => {
                    d.orderContainers.forEach((c, i) => {
                      this.props.updateContainer({
                        company_id: this.props.companyId,
                        order_id: d.id,
                        container_id: c.id_container,
                        fleet_equipment: equipmentList[i].id || 0,
                      })
                    })
                  });
                  this.setState({
                    isLoading: false,
                    showAssignDialog: false,
                    selectedAssembly: 0,
                    currentTrip: undefined,
                    hasAutoStart: true,
                  });
              },
              onError: (e) => {
                let errors = e.response?.data?.errors || [];

                this.setState({
                  isLoading: false,
                  showAssignDialog: false,
                  selectedAssembly: 0,
                  currentTrip: undefined,
                  hasAutoStart: true,
                  updateTripsError: errors.map( e => e ).join(' ')
                });
              }
            }
          );
        }
        break;
      default:
        this.setState({ [name]: value });
    }
  };

  handleAutoStart = e => {
    this.setState({ hasAutoStart: !this.state.hasAutoStart });
  }

  ShipmentsFilters = (
    status: any[] = [],
    providers: any[] = [],
    //transportClasses: any[] = [],
    vehicles = []
  ) => {
    let statusCondensed = status.filter(s => !s.description.toLowerCase().includes("reservación confirmada"));
    let combined = status.filter(s => s.description.toLowerCase().includes("reservación confirmada")).map(s => s.id);
    statusCondensed.push({id: combined.join(), description: "Reservación confirmada"});
    
    let dateTypes = [
      {
        id: "1",
        description: "Carga"
      },
      {
        id: "2",
        description: "Descarga"
      },
      {
        id: "3",
        description: "Programa"
      },
      {
        id: "4",
        description: "Programa y cita de carga"
      },
    ];

    if ((this.state.filterParams?.from_facilities ?? []).length > 0 && (this.state.filterParams?.to_facilities ?? []).length > 0) {
      dateTypes = [
        {
          id: "1",
          description: "Carga"
        },
        {
          id: "2",
          description: "Descarga"
        },
        {
          id: "3",
          description: "Programa"
        },
        {
          id: "4",
          description: "Programa y cita de carga"
        },
      ];
    } else if ((this.state.filterParams?.from_facilities ?? []).length > 0) {
      dateTypes = [
        {
          id: "1",
          description: "Carga"
        },
        {
          id: "3",
          description: "Programa"
        },
        {
          id: "4",
          description: "Programa y cita de carga"
        },
      ];
    } else if ((this.state.filterParams?.to_facilities ?? []).length > 0) {
      dateTypes = [
        {
          id: "2",
          description: "Descarga"
        },
        {
          id: "3",
          description: "Programa"
        },
        {
          id: "4",
          description: "Programa y cita de carga"
        },
      ];
    }

    if ((this.state.filterParams?.from_to_facilities ?? []).length > 0) {
      dateTypes = [
        {
          id: "1",
          description: "Carga"
        },
        {
          id: "2",
          description: "Descarga"
        },
        {
          id: "3",
          description: "Programa"
        },
        {
          id: "4",
          description: "Programa y cita de carga"
        },
      ];
    }

    return [
      {
        title: "Base",
        items: this.props.companyTags.filter((f) =>
          f.type === 3
            ? true
            : false
        ),
        name: "baseTags",
        listType: 1,
      },
      {
        title: "Vehículo",
        items: vehicles,
        name: "vehicle",
        listType: 5,
      },
      {
        title: "Clase",
        items: this.props.transportClass,
        name: "transportClass",
        listType: 5,
      },
      {
        title: "Estatus viaje",
        items: statusCondensed,
        name: "status",
        listType: 5,
      },
      {
        title: "Estatus orden",
        items: this.props.orderStatus,
        name: "orderStatus",
        listType: 5,
      },
      {
        title: "Cliente",
        items: providers,
        name: "fleet_providers",
        listType: 5,
      },
      {
        title: "Origen",
        items:  this.props.filterInfo.map(fI => ({
          id: fI.id,
          description: fI.orbi_id || fI.corporation_name,
          items: fI.facilities.map(f => ({
            id: f.id,
            description: f.name
          }))
        })),
        name: "from_facilities",
        listType: 5,
      },
      {
        title: "Destino",
        items:  this.props.filterInfo.map(fI => ({
          id: fI.id,
          description: fI.orbi_id || fI.corporation_name,
          items: fI.facilities.map(f => ({
            id: f.id,
            description: f.name
          }))
        })),
        name: "to_facilities",
        listType: 5,
      },
      {
        title: "Origen/Destino",
        items:  this.props.filterInfo.map(fI => ({
          id: fI.id,
          description: fI.orbi_id || fI.corporation_name,
          items: fI.facilities.map(f => ({
            id: f.id,
            description: f.name
          }))
        })),
        name: "from_to_facilities",
        listType: 5,
      },
      {
        title: "Fecha",
        name: "appointment",
        listType: 7,
        time: true
      },
      {
        title: "Tipo fecha",
        items: dateTypes,
        name: "appointment_type",
        listType: 5,
      }
    ].filter( item => {
      if ((this.state.filterParams?.from_facilities ?? []).length > 0 || (this.state.filterParams?.to_facilities ?? []).length > 0) {
        return item.title !== 'Origen/Destino';
      } else if ((this.state.filterParams?.from_to_facilities ?? []).length > 0) {
        return !(item.title === 'Origen' || item.title === 'Destino');
      } else {
        return true;
      }
    });
  };

  getToken = () => {
    return {
      Authorization:
        "Bearer " +
        (JSON.parse(sessionStorage.getItem("orbinetwork_session")) || {})
          .access,
    };
  };

  
  getUrl = () => {
    if(this.state.searchStr){ 
      return `${SERVER}/api/v2/companies/${this.props.companyId}/trips/?view=${"controls"}&appointment=&appointment_type=&status=&order_status=&base_tags=&vehicle=&fleet_providers=&from_facilities=&to_facilities=&from_to_facilities=&limit=${this.state.limit}&offset=${this.state.offset}&search=${this.state.searchStr}&transport_classes=&action=${""}&search_type=${this.state.searchType === "1" ? 'folio' : this.state.searchType  === "2" ? 'vehiculo' :  'placas'}&timezone=${getTimeZone() || ""}&download=true`;
    } else{
      return `${SERVER}/api/v2/companies/${this.props.companyId}/trips/?view=${"controls"}&appointment=${this.state.filterParams.appointment[0] ? "".concat(this.state.filterParams.appointment[0].toISOString(), ",", this.state.filterParams.appointment[1] ? this.state.filterParams.appointment[1].toISOString() : this.state.filterParams.appointment[0].toISOString()) : ""}&appointment_type=${(this.state.filterParams.appointment_type || []).join(",") || "1,2,3"}&status=${this.state.filterParams.status}&order_status=${this.state.filterParams.orderStatus}&base_tags=${this.state.filterParams.baseTags}&vehicle=${this.state.filterParams.vehicle}&fleet_providers=${this.state.filterParams.fleet_providers}&from_facilities=${this.state.filterParams.from_facilities}&to_facilities=${this.state.filterParams.to_facilities}&from_to_facilities=${this.state.filterParams.from_to_facilities || ''}&limit=${this.state.limit}&offset=${this.state.offset}&search=${this.state.searchStr}&transport_classes=${this.state.filterParams.transport_classes || ''}&action=${""}&search_type=${""}&timezone=${getTimeZone() || ""}&download=true`;
    }
  };

  openDocument = (url) => {

    this.setState({ 
      downloadingFile: true
    }, () => {
      Axios({
        url: url,
        method: "GET",
        responseType: "blob",
        headers: this.getToken(),
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Trafico.xlsx");
        document.body.appendChild(link);
        link.click();
        this.setState({
          downloadingFile: false
        })
      }).catch((response) => {
        let error = (response.errors && response.errors.length > 0) ? response.errors.join() : response.error || 'Ocurrió un error, intente de nuevo';
  
        this.setState({
          downloadingFile: false,
          msgError: error
        });
      });

    });
  };

  handleEmailInput = (e) => {
    if (e) {
      // debugger;
      switch (e.target.name) {
        case "inviteTags":
          // debugger;
          let mails = e.target.value
            // .filter((it) => !it.processed)
            .map((i) => ({
              email: i.email,
              avatar: "hide",
              avatarType: "",
              userId: null,
              // processed: true,
            }));
          this.setState({ 
            emails: mails, 
            searchStr: e.target.value.map(e => e.email), 
            offset: 1,
            // filterParams: {
            //   baseTags: [],
            //   status: [],
            //   orderStatus: [],
            //   transportClass: [],
            //   fleet_providers: [],
            //   from_facilities: [],
            //   to_facilities: [],
            //   from_to_facilities: [],
            //   appointment: [],
            //   appointment_type: [],
            //   vehicle: [],
            // },
            buttonFilterName: "Aplicar"
          }, this.loadTrips);
          break;
        case "deleteTags":
          this.setState({ emails: [].concat(e.target.value), searchStr: e.target.value.map(e => e.email), offset: 1 }, this.loadTrips);
          break;
        default:
      }
    }
  };

  /// Transfer events 

  getFolioToTransfer = () => {
    if (this.state.transferData === null) return '';
    const { donor : { code = '' } } = this.state.transferData;
    return code;
  }

  getOriginAlias = () => {
    if (this.state.transferData === null) return '';
    const { donor: { from_facility: { alias = '' } } } = this.state.transferData;
    return alias;
  }

  getDestinyAlias = () => {
    if (this.state.transferData === null) return '';
    const { donor: { to_facility: { alias = '' } } } = this.state.transferData;
    return alias;
  }

  getContainerInfo = () => {
    return this.state.containersSelected;
  }

  getCandidatesOriginAlias = () => {
    if (this.state.folioDataSelected === null) return '';

    const { from_facility: { alias = '' } } = this.state.folioDataSelected;
    return alias;
  }

  getCandidatesDestinyAlias = () => {
    if (this.state.folioDataSelected === null) return '';

    const { to_facility: { alias = '' } } = this.state.folioDataSelected;
    return alias;
  }

  getSecondCandidatesOriginAlias = () => {
    if (this.state.secondFolioDataSelected === null) return '';

    const { from_facility: { alias = '' } } = this.state.secondFolioDataSelected;
    return alias;
  }

  getSecondCandidatesDestinyAlias = () => {
    if (this.state.secondFolioDataSelected === null) return '';

    const { to_facility: { alias = '' } } = this.state.secondFolioDataSelected;
    return alias;
  }

  showExtraInfoToTransferEvents = () => {

    if (this.state.transferData === null || this.state.folioDataSelected === null) return false;

    const { donor: { equipments = [] } } = this.state.transferData;
    const { containers = 0 } = this.state.folioDataSelected;

    return equipments.length === 2 && containers === 1;
  }

  getLoader = () => {
    return (
      <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200}}>
        Cargando...
      </div>
    );
  }

  getContainersItems = () => {
    if (this.state.transferData === null) return [];

    const { donor: { equipments = [] } } = this.state.transferData;

    return equipments.map( (item) => {
      const { id, economic_number, plates } = item;
      return { id, description: economic_number, plates };
    });
  }

  isConfirmTransferEvents = () => {

    if (this.props.getTransferDataLoading) { return false; }

    if (this.showExtraInfoToTransferEvents()) {
      return (this.state.folioSelected.length && this.state.folioSelected.length > 0 && this.state.firstContainerSelected.length && this.state.firstContainerSelected.length > 0 && this.state.secondFolioSelected.length && this.state.secondFolioSelected.length > 0 && this.state.secondContainerSelected.length && this.state.secondContainerSelected.length > 0 );
    } else {
      return (this.state.folioSelected.length && this.state.folioSelected.length > 0);
    }

  }

  getTransferEventsContent = () => {

    if (this.state.transferEventsSuccess !== '') {

      return (
        <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200, flexDirection: 'column'}}>
          { this.state.transferEventsSuccess }

          <div  style={{width: 20, height: 40}} />

          <Button
            settings={{
              text: "Ok",
              onClick: () => this.resetTransferData()
            }}
          />
        </div>
      );

    }

    const showExtraInfoToTransferEvents = this.showExtraInfoToTransferEvents();
    const listEquipments = this.getContainersItems();
    return(
      <div style={{width: '100%'}}>

        <div style={{fontSize: 14, marginBottom: 20, color: 'rgb(155, 161, 172)'}} >{'Selecciona el folio el cual recibirá los eventos.'} </div>

        {
          this.state.transferEventsError !== '' ? 
          <div className={'data-key'} style={{color: 'red'}}>{this.state.transferEventsError}</div> : <div/>
        }

        <div style={{width: '100%', height: 76, backgroundColor: 'rgb(214, 222, 230)', borderRadius: 4, marginBottom: 40}}>

          <div style={{marginLeft: 14, display: 'flex'}}><div className={'data-key'} style={{}}>Folios por transferir:</div> <div className={'data-value'} style={{marginLeft: 14}}>{this.getFolioToTransfer()}</div></div>
          <div style={{marginLeft: 14, display: 'flex'}}><div className={'data-key'} style={{}}>Origen:</div> <div className={'data-value'} style={{marginLeft: 14}}>{this.getOriginAlias()}</div></div>
          <div style={{marginLeft: 14, display: 'flex'}}><div className={'data-key'} style={{}}>Destino:</div> <div className={'data-value'} style={{marginLeft: 14}}>{this.getDestinyAlias()}</div></div>

        </div>

        <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>

          <div style={{width: '40%'}}>
            <div
              style={{
                marginLeft: -5,
                marginTop: -10
              }}
            >
              <SelectInputStyled
                selectedItems={this.state.folioSelected}
                items={this.state.folios.filter( (item) => {
                  const { id = '' } = item;

                  if (this.state.secondFolioDataSelected !== null) {
                    return `${this.state.secondFolioDataSelected.id}` !== `${id}`;
                  } else {
                    return true;
                  }
                })}
                onChange={ (e) => {

                  var folio = null;

                  for (var i = 0; i < this.state.folios.length; i++) {
                    if (`${this.state.folios[i].id}` === `${e.target.value}`) {
                      folio = this.state.folios[i];
                    }
                  }

                  this.setState({ [e.target.name]: e.target.value, folioDataSelected: folio })

                }}
                className={'transfer-events'}
                name={"folioSelected"}
                label={"Folio candidato:"}
                multiple={false}
              />
            </div>
            <div style={{display: 'flex'}}><div className={'data-key'} style={{}}>Origen:</div> <div className={'data-value'} style={{marginLeft: 14}}>{this.getCandidatesOriginAlias()}</div></div>
            <div style={{display: 'flex'}}><div className={'data-key'} style={{}}>Destino:</div> <div className={'data-value'} style={{marginLeft: 14}}>{this.getCandidatesDestinyAlias()}</div></div>
            <div style={{display: 'flex'}}><div className={'data-key'} style={{marginBottom: 15}}>Remolques:</div> 
            
              {
                showExtraInfoToTransferEvents ? 
                <div
                  style={{
                    marginLeft: 5,
                    marginTop: -10,
                    width: '100%'
                  }}
                >
                  <SelectInputStyled
                    showSearch={false}
                    showDeselector={false}
                    selectedItems={this.state.firstContainerSelected}
                    items={listEquipments}
                    onChange={ (e) => {

                      if (e.target.value.length && e.target.value.length > 0) {

                        const val = e.target.value;
                        if (JSON.stringify(val) === JSON.stringify(this.state.secondContainerSelected)) {

                          var tempContainer = '';
                          for (var i = 0; i < listEquipments.length; i++) {
                            if (`${listEquipments[i].id}` !== `${val[0]}`) {
                              tempContainer = `${listEquipments[i].id}`;
                            }
                          }

                          this.setState({[e.target.name]: val, secondContainerSelected: [tempContainer]})
                        } else {
                          this.setState({[e.target.name]: val});
                        }

                      }

                    }}
                    className={'transfer-events-container'}
                    name={"firstContainerSelected"}
                    label={""}
                    multiple={false}
                  />
                </div>
                :
                <div className={'data-value'} style={{marginLeft: 14}}>{this.getContainerInfo()}</div>
              }
            </div>
          </div>

          {
            showExtraInfoToTransferEvents ? 
            <div style={{width: '40%', marginLeft: 50}}>
              <div
                style={{
                  marginLeft: -5,
                  marginTop: -10
                }}
              >
                <SelectInputStyled
                  selectedItems={this.state.secondFolioSelected}
                  items={this.state.folios.filter( (item) => {
                    const { containers = 0, id = '' } = item;

                    if (this.state.folioDataSelected !== null) {
                      return containers === 1 && `${this.state.folioDataSelected.id}` !== `${id}`;
                    } else {
                      return containers === 1;
                    }
                  })}
                  onChange={ (e) => {

                    var folio = null;
                    var tempContainer = '';

                    for (var i = 0; i < this.state.folios.length; i++) {
                      if (`${this.state.folios[i].id}` === `${e.target.value}`) {
                        folio = this.state.folios[i];
                      }
                    }

                    if (this.state.firstContainerSelected !== '' && this.state.firstContainerSelected.length > 0) {

                      for (var i = 0; i < listEquipments.length; i++) {
                        if (`${listEquipments[i].id}` !== this.state.firstContainerSelected[0]) {
                          tempContainer = [`${listEquipments[i].id}`];
                        }
                      }

                    }

                    if (folio === null) {
                      tempContainer = '';
                    }

                    this.setState({ [e.target.name]: e.target.value, secondFolioDataSelected: folio, secondContainerSelected: tempContainer })

                  }}
                  className={'transfer-events'}
                  name={"secondFolioSelected"}
                  label={"Folio candidato:"}
                  multiple={false}
                />
              </div>
              <div style={{display: 'flex'}}><div className={'data-key'} style={{}}>Origen:</div> <div className={'data-value'} style={{marginLeft: 14}}>{this.getSecondCandidatesOriginAlias()}</div></div>
              <div style={{display: 'flex'}}><div className={'data-key'} style={{}}>Destino:</div> <div className={'data-value'} style={{marginLeft: 14}}>{this.getSecondCandidatesDestinyAlias()}</div></div>
              <div style={{display: 'flex'}}><div className={'data-key'} style={{marginBottom: 15}}>Remolques:</div> 
                <div
                  style={{
                    marginLeft: 5,
                    marginTop: -10,
                    width: '100%'
                  }}
                >
                  <SelectInputStyled
                    showSearch={false}
                    showDeselector={false}
                    selectedItems={this.state.secondContainerSelected}
                    items={listEquipments}
                    onChange={ (e) => {

                      if (e.target.value.length && e.target.value.length > 0) {

                        const val = e.target.value;

                        if (JSON.stringify(val) === JSON.stringify(this.state.firstContainerSelected)) {

                          var tempContainer = '';
                          for (var i = 0; i < listEquipments.length; i++) {
                            if (`${listEquipments[i].id}` !== `${val[0]}`) {
                              tempContainer = `${listEquipments[i].id}`;
                            }
                          }

                          this.setState({[e.target.name]: val, firstContainerSelected: [tempContainer]})
                        } else {
                          this.setState({[e.target.name]: val})
                        }

                      }

                    }}
                    className={'transfer-events-container'}
                    name={"secondContainerSelected"}
                    label={""}
                    multiple={false}
                  />
                </div>
              </div>
            </div>
            : 
            <div></div> 
          }
          
        </div>

      </div>
            
    );
  }

  buildTransferEventsModal = () => {
    return(
      <Modal
        isOpen={this.state.showDonateModal}
        portalClassName="dialog confirm-dialog"
        overlayClassName="overlay"
        className="content transfer-events successModal"
      >
        <div className="title">Transferir eventos</div>
        <div className="close" onClick={ () => 
          this.resetTransferData()
        }
        ></div>
        <div className="message">
          
          {
            this.props.getTransferDataLoading ? 
            this.getLoader() : this.getTransferEventsContent()
          }

        </div>

        {
          this.state.transferEventsSuccess !== '' ? <div/> :
          <div className="actions confirmActions">
            <Button
              settings={{
                type: 'primary outline dark',
                text: "Cancelar",
                onClick: () => this.resetTransferData()
              }}
            />
            <Button
              disabled={!this.isConfirmTransferEvents() || this.state.isTransferingEvents}
              settings={{
                text: this.state.isTransferingEvents ? <Loader/> : "Confirmar",
                onClick: () => {
                  var candidates = [];
                  var fleet_equipments = [];

                  var folioCandidate = this.state.folioSelected[0];
                  candidates = [folioCandidate];

                  if (this.state.firstContainerSelected.length && this.state.firstContainerSelected.length > 0) {

                    const firstContainer = this.state.firstContainerSelected[0];

                    fleet_equipments = [firstContainer];
                  }
                  
                  if (this.state.secondContainerSelected.length && this.state.secondContainerSelected.length > 0 &&
                    this.state.secondFolioSelected.length && this.state.secondFolioSelected.length > 0) {

                    const secondContainer = this.state.secondContainerSelected[0];
                    const secondFolioCandidate = this.state.secondFolioSelected[0];

                    candidates = candidates.concat(secondFolioCandidate);
                    fleet_equipments = fleet_equipments.concat(secondContainer);

                  }

                  const { tripIdToTransfer = '' } = this.state;

                  this.setState({
                    isTransferingEvents: true
                  });

                  this.props.transferEvents(
                    {
                      company_id: this.props.companyId,
                      trip_id: tripIdToTransfer,
                      candidates,
                      fleet_equipments
                    },
                    {
                      onSuccess: (val) => {

                        this.setState({
                          transferEventsSuccess: 'Los eventos fueron transferidos correctamente',
                          transferEventsError: '',
                          folioSelected: '',
                          folioDataSelected: null,
                          secondFolioSelected: '',
                          secondFolioDataSelected: null,
                          folios: [],
                          containersSelected: '',
                          transferData: null,
                          firstContainerSelected: '',
                          secondContainerSelected: '',
                          isTransferingEvents: false
                        }, () => {
                          this.loadTrips();
                        });

                      },
                      onError: (error) => {
                        this.setState({
                          isTransferingEvents: false,
                          transferEventsSuccess: '',
                          transferEventsError: 'Ocurrió un error al transferir los eventos'
                        });
                      }
                    }
                  );
                }
              }}
            />
          </div>
        }
      </Modal>
    );
  } 

  downloadFile = (url) => {
    let array = url.split("/");
    let lastSegment = array.length > 0 ? array[array.length - 1] : ".";

    fetch(url)
      .then(response => response.blob())
      .then(blob => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = lastSegment;
          link.click();
      })
      .catch(console.error);
  }

  cancelCFDI = (tripID) => {
    this.props.cancelCFDI({
      company_id: this.props.companyId,
      request_type: 'orders',
      trip_id: tripID,
      // clave_motivo: this.state.motive
    }, {
      onSuccess: () => {
        this.loadTrips();
      }, 
      onError: () => {

      }
    })
  }

  validateStatusTrip = (action, object, callback) => {

    this.props.loadStatusTrip(
      this.props.companyId,
      object.id,
      {
        onSuccess: (data) => {
          if (action === 'Cambiar ensamble') {
            if (data && data.trip_status === 5) {
              this.setState({showErrorMessageModal: 'El viaje ya se encuentra en proceso y no se permite dicha interacción'});
            } else if (data && data.trip_status < 4) {
              this.setState({showErrorMessageModal: 'El viaje ya no se encuentra en el estatus que permite esta interacción'});
            } else {
              if (callback) { callback() }
            }
          }
        },
        onError: (error) => {
          this.setState({showErrorMessageModal: 'Ocurrió un error, intente de nuevo'});
        }
      }
    );

  }

  //SELECTOR
  toggleSelectMode = () => {
    this.setState({
      selectionModeOn: !this.state.selectionModeOn,
      selectedTripsId: []
    });
  };

  handleSelectTrip = (e) => {

    let selectedTripsId = [...this.state.selectedTripsId];
    if (e.target.value) {
      selectedTripsId.push(e.target.name);
    } else {
      selectedTripsId.splice(
        selectedTripsId.findIndex((f) => f === e.target.name),
        1
      );
    }
    this.setState({ selectedTripsId });

  };

  getSelectedFunction = (item) => {
    return this.state.selectedTripsId.find((sU) => sU === item.id) ? true : false
  }

  selectAllTrips = () => {
    if (this.props.tripRows.length === (this.state.selectedTripsId.length)) {
      this.setState({ selectedTripsId: []});
    } else {
      let selectedTripsId = [];

      this.props.tripRows
        .map((item) => {
          selectedTripsId.push(item.id);
          return null;
        });
      
      this.setState({ selectedTripsId});
    }
  };

  //EditBase
  saveBaseTags = () => {
    this.setState({isLoading: true})
    this.props.updateTripBase(
      {
        company_id: this.props.companyId,
        trip_id: this.state.selectedTripsId[0],
        action: "set_base",
        base: this.state.selectedBaseTags[0],
        trips: this.state.selectedTripsId
      },
      {
        onSuccess: () => { 
          
          this.loadTrips();
          this.toggleSelectMode();
          this.setState({ 
            isLoading: false,
            showConfirmEditBaseModal: false,
            selectedBaseTags: [],
            showSuccessModal: true
           });
        },
        onError: (e) => {
          this.setState({ 
            isLoading: false,
            showConfirmEditBaseModal: false,
            selectedBaseTags: []
           });
          let err = e.response?.data?.errors || []; 
          this.manageError(err);
        },
      }
    );
  }
  closeEditBaseModal = () => {
    this.setState({
      showEditBaseModal: false,
      selectedBaseTags: []
    })
  }
  handleTagClick = (e) => {
    if (e.target.value) this.setState({ selectedBaseTags: [] });
    else this.setState({ selectedBaseTags: [e.target.id] });
  };
  showConfirmEditBaseModal = () => {
    this.setState({
      showEditBaseModal: false,
      showConfirmEditBaseModal: true
    })
  }

  finalizeTripsSuccess = () => {
    this.tripsCounter = 0;
    this.setState({
      tripsToFinalize: 0,
      openFinalizeTripModal: false,
      showSuccessFinalizeTripsModal: true,
      selectionModeOn: false
    }, () => {
      this.loadTrips();
    });
  }

  render() {
    return (
      <View>
        {
          this.state.isLoadingTrips &&
          <div
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              backgroundColor: "#00000080",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1001,
              backdropFilter: "blur(3px)"
            }}
          >
            <div style={{display: "flex", alignItems: "center"}}>
              <Loader width="100px" height="100px"/>
              <div style={{color: "#fff", fontSize: "36px"}}>
                Cargando
              </div>
            </div>
            <div style={{color: "#fff", fontSize: "30px"}}>
              Si desea cancelar la consulta, presione ESC
            </div>
          </div>
        }
        <ViewTitle className="margin-title">
          <div style={{flex: "1"}}>
          Control de embarques
          </div>
          <div style={{display: "flex", flexDirection: "column", alignItems: "end"}}>
            <FilterBarCompact
              limit={350}
              limitErrorItem={this.state.currentViewOption === 2 ? "" : this.state.searchType === "1" ? "folios" : "vehículos"}
              clearMultiText={() => {
                this.setState({ emails: [], searchStr: "" });
              }}
              // searchStr={this.state.searchStr}
              searchStr={this.state.emails}
              // searchAction={(e) => {
              //   this.setState(
              //     {
              //       [e.target.name]: e.target.value,
              //     },
              //     () => this.loadTrips()
              //   );
              // }}
              searchPlaceholder={this.state.searchType === "1" ? "Buscar orden ó viaje" : "Buscar vehículo"}
              searchAction={this.handleEmailInput}
              multiText={true}
              content={this.ShipmentsFilters(
                this.props.tripStatuses,
                this.props.companyProviders,
                // this.props.facilities,
                this.state.vehiclesToShow,
              )}
              filterAction={() => {
                // this.loadAssemblies();
                if(this.state.buttonFilterName === "Aplicar"){
                  this.setState(
                    {
                      offset: 1,
                      buttonFilterName: "Restablecer"
                    },
                    () => {console.log("SEARCHING-FILTER"); this.loadTrips(); }
                  );
                } else {
                  this.setState(
                    {
                      emails: [], 
                      searchStr: '', 
                      filterParams: {
                        baseTags: [],
                        status: [],
                        orderStatus: [],
                        transportClass: [],
                        fleet_providers: [],
                        from_facilities: [],
                        to_facilities: [],
                        from_to_facilities: [],
                        appointment: [],
                        appointment_type: [],
                        vehicle: [],
                      },
                      buttonFilterName: "Aplicar"
                    },
                    () => {console.log("SEARCHING-FILTER-CLEAR"); this.loadTrips(); }
                  );
                }
              }}
              onChange={(e) => {

                this.state.filterParams = {
                  ...this.state.filterParams,
                  [e.target.name]: e.target.value,
                };

                if (!((this.state.filterParams?.from_facilities ?? []).length > 0 && (this.state.filterParams?.to_facilities ?? []).length > 0)) {

                  if (e.target.name === 'from_facilities') {
                    let newAppointmentType = (this.state.filterParams?.appointment_type ?? []).filter( item => { return `${item}` !== '2'})
                    this.state.filterParams.from_to_facilities = [];
                    this.state.filterParams.appointment_type = newAppointmentType;
                  }
  
                  if (e.target.name === 'to_facilities') {
                    let newAppointmentType = (this.state.filterParams?.appointment_type ?? []).filter( item => { return `${item}` !== '1'})
                    this.state.filterParams.from_to_facilities = [];
                    this.state.filterParams.appointment_type = newAppointmentType;
                  }

                }

                if ((this.state.filterParams?.from_to_facilities ?? []).length > 0) {
                  this.state.filterParams.from_facilities = [];
                  this.state.filterParams.to_facilities = [];
                }

                this.setState({
                  emails: [], 
                  searchStr: '', 
                  buttonFilterName: "Aplicar"
                });
              }}
              buttonFilterName={this.state.buttonFilterName}
              selectedItems={this.state.filterParams}
            />
            <div style={{display: "flex", transform: `translateY(${this.state.emails.length ? "30" : "10"}px)`}}>
              <RadioInput
                className="filter-radio"
                name="searchType"
                title="Órdenes"
                value="1"
                selected={this.state.searchType}
                onClick={(e) => {this.setState({searchType: e.target.value})}}
              />
              <RadioInput
                className="filter-radio"
                name="searchType"
                title="Vehículos"
                value="2"
                selected={this.state.searchType}
                onClick={(e) => {this.setState({searchType: e.target.value})}}
              />
            </div>
          </div>
        </ViewTitle>
        <TabSelectorRibbon
          onChange={(e) => {this.props.saveFilters(this.state.filterParams); this.props.onChange(e)}}
          bgColor="#212A3C"
          selectedUnderlineColor="#3D77F7"
          openDocument={this.openDocument}
          getUrl={this.getUrl}
          items={[
            { id: "orders", description: "ÓRDENES" },
            { id: "list", description: "VIAJES" },
            { id: "grid", description: "TRÁFICO" },
            { id: "appointments", description: "CITAS" },
          ]}
          activeId={this.props.viewMode}
          downloadingFile={this.state.downloadingFile}
        />
        <TableD 
          tableConfig={
            // Utils.TableConfig
            {
              ...Utils.TableConfigWithCFDI, 
              columns: [
                {
                  ...Utils.TableConfigWithCFDI.columns[0],
                  text: <CaretContainer
                      onClick={() => this.setState({ collapseAll: !this.state.collapseAll })}
                      onMouseEnter={() => this.setState({ isHovered: true })}
                      onMouseLeave={() => this.setState({ isHovered: false })}
                    > 
                      <Caret up={this.state.collapseAll} fill="#AFC8FF" isHovered={this.state.isHovered}/>
                    </CaretContainer>
                }
              ].concat(Utils.TableConfigWithCFDI.columns.slice(1))
            }
          }
        >
          <Selector
            selectedItemClass="selected"
            selectionModeClass="selection"
            selectedItems={this.state.selectedTripsId}
            onSelect={this.handleSelectTrip}
            selectionModeOn={this.state.selectionModeOn} 
            toggleSelectionMode={this.toggleSelectMode}
          >
            {(this.props.tripRows || []).map((t, i, a) => (
              <ShipmentBoardRow
                key={ t.id}
                id={t.id}
                selected={this.getSelectedFunction(t)}
              >
                <AssembleShipmentListRow  
                  tableConfig={
                    // Utils.TableConfig
                    {
                      ...Utils.TableConfigWithCFDI, 
                      columns: [
                        {
                          ...Utils.TableConfigWithCFDI.columns[0],
                          text: <CaretContainer
                              onClick={() => this.setState({ collapseAll: !this.state.collapseAll })}
                              onMouseEnter={() => this.setState({ isHovered: true })}
                              onMouseLeave={() => this.setState({ isHovered: false })}
                            >
                              <Caret up={this.state.collapseAll} fill="#AFC8FF" isHovered={this.state.isHovered}/>
                            </CaretContainer>
                        }
                      ].concat(Utils.TableConfigWithCFDI.columns.slice(1))
                    }
                  }
                  collapsed={this.state.collapseAll}
                  key={`${t.id}-${t.status.id}-${i}-${a.length}`}
                  history={this.props.history}
                  assembly={this.getAssembleItem(t)}
                  {...Utils.mapTripActions(t, {
                    accept: this.acceptTrip,
                    reject: this.rejectTrip,
                    cancel: this.cancelTrip,
                    assign: this.assignTrip,
                    reset: this.resetTrip,
                    start: this.startTrip,
                    finalizeTrip: this.finalizeTrip,
                    finalizeOrder: this.finalizeOrder,
                    transferEvents: this.transferEvents,
                    redirect: this.props.history.push,
                    downloadFile: this.downloadFile,
                    cancelCFDI: this.cancelCFDI,
                    validateStatusTrip: this.validateStatusTrip
                  })}
                  cfdi={t.cfdi}
                  validateCFDI={ (response) => this.props.validateCFDI({trip_id: t.id, company_id: this.props.companyId}, response) }
                  tripId={`${t.id}`}
                  setOrderDetail={orderToSelect => {
                    this.props.setOrderDetail(orderToSelect)
                  }}
                  details={t.details.map(d => {
                    return {
                      ...d,
                      assembly: this.getAssembleItem(d)
                    }
                  })}
                />
              </ShipmentBoardRow>
            ))}
          </Selector>
        </TableD>
        <PaginatorControlled
          itemCount={this.props.tripItemCount}
          limit={this.state.limit}
          offset={this.state.offset}
          onChange={(e) =>
            this.setState({ [e.target.name]: e.target.value }, () =>{
              if (this.state.getTripsCalled) {
                console.log("SEARCHING-FILTER-PAGINATOR");
                this.loadTrips();
              }
            })
          }
        />
        <CatalogSelectionFooter
          show={this.state.selectionModeOn}
          selectedElements={this.state.selectedTripsId.length}
          selectAll={this.selectAllTrips}
          actions={[
            {
              description: "Cerrar",
              f: () => this.setState({selectionModeOn: false, selectedTripsId: []}),
            },
            {
              description: "Editar base",
              f: () => {this.setState({showEditBaseModal: true})},
              disabled: !this.state.selectedTripsId.length
            },
            {
              description: "Finalizar viajes sin datos",
              f: () => this.setState({openFinalizeTripModal: true}),
              disabled: !this.state.selectedTripsId.length
            }
          ]}
        />
        {this.state.showAssignDialog && (
          <AssembleShipmentAssignDialog
            assemblies={ (this.props.tripBookingAvailabilityLoading === false) ? this.props.tripBookingAvailability : []}
            show={this.state.showAssignDialog}
            manager={this.manageAssignModal}
            logo={this.props.companyData.logo}
            trip={this.state.currentTrip}
            selectedAssembly={this.state.selectedAssembly}
            hasAutoStart={this.state.hasAutoStart}
            handleAutoStart={this.handleAutoStart}
            isLoading={this.state.isLoading}
          />
        )}
        {this.state.showResetTripModal && (
          <ResetTripModal
            show={this.state.showResetTripModal}
            data={{
              logo: this.state.currentTrip.companyLogo,
              name: this.state.currentTrip.companyName,
              trip: this.state.currentTrip.tripNumber,
            }}
            onChange={this.manageCancelDialogs}
            isRestartingLoading={this.state.isRestartingLoading}
          />
        )}
        {this.state.showStartTripModal && (
          <StartTripModal
            show={this.state.showStartTripModal}
            data={{
              logo: this.state.currentTrip.companyLogo,
              name: this.state.currentTrip.companyName,
              trip: this.state.currentTrip.tripNumber,
            }}
            onChange={this.manageCancelDialogs}
          />
        )}
        {this.state.showFinalizeTripModal && (
          <FinalizeTripModal
            show={this.state.showFinalizeTripModal}
            data={{
              logo: this.state.currentTrip.companyLogo,
              name: this.state.currentTrip.companyName,
              trip: this.state.currentTrip.tripNumber,
            }}
            onChange={this.manageCancelDialogs}
          />
        )}
        {this.state.showFinalizeOrderModal && (
          <FinalizeOrderModal
            show={this.state.showFinalizeOrderModal}
            data={{
              logo: this.state.currentTrip.companyLogo,
              name: this.state.currentTrip.companyName,
              trip: this.state.currentTrip.tripNumber,
              card: this.state.currentTrip.ticket
            }}
            onChange={this.manageCancelDialogs}
          />
        )}
        {this.state.showConfirmDialog && (
          <BaseDialog
            closeAction={() => {this.setState({showConfirmDialog: false, hasAutoStart: true, currentTrip: undefined})}}
            acceptAction={this.acceptTripAction}
            open={this.state.showConfirmDialog}
            title={"Confirmar reservación"}
            contentObject={this.getModalContent()}
            showCancel={true}
            cancelText="Cerrar"
            cancelButtonType="primary outline dark"
            acceptText="Confirmar"
            isAcceptLoading={this.props.isConfirmingLoading}
            // disabledAccept={!this.props.selectedAssembly}
            acceptButtonType="primary"
            style={{ content: { width: "480px", marginLeft: "-240px" } }}
          ></BaseDialog>
        )}
        {this.state.showSelectEquipmentModal && (
          <BaseDialog
            closeAction={() => {
              this.setState({
                showSelectEquipmentModal: false, 
                selectedAssembly: 0,
                currentTrip: undefined,
                selectedEquipment: 0,
                hasAutoStart: true
              })
            }}
            acceptAction={this.assignContainers}
            open={this.state.showSelectEquipmentModal}
            title={"Seleccionar equipo"}
            contentObject={this.getEquipmentModalContent()}
            showCancel={false}
            cancelText="Cerrar"
            cancelButtonType="primary outline dark"
            acceptText="Guardar"
            disabledAccept={!this.state.selectedEquipment}
            acceptButtonType="primary"
            style={{ content: { width: "600px", marginLeft: "-300px" } }}
          ></BaseDialog>
        )}
        {this.state.showConfirmEditBaseModal && (
          <BaseDialog
            closeAction={() => {
              this.setState({
                showConfirmEditBaseModal: false, 
                selectedBaseTags: [],
              })
            }}
            acceptAction={this.saveBaseTags}
            open={this.state.showConfirmEditBaseModal}
            title={"Confirmar"}
            message={"Esta interacción eliminará las etiquetas que los viajes tenían y aplicara la nueva base a todos los viajes seleccionados."}
            showCancel={true}
            cancelText="Cancelar"
            cancelButtonType="primary outline dark"
            acceptText="Guardar"
            // disabledAccept={!this.state.selectedEquipment}
            acceptButtonType="primary"
            isAcceptLoading={this.state.isLoading}
            style={{ content: { width: "600px", marginLeft: "-300px" } }}
          ></BaseDialog>
        )}
        {this.state.showSuccessModal && (
          <BaseDialog
            closeAction={() => {
              this.setState({
                showSuccessModal: false, 
              })
            }}
            acceptAction={() => {
              this.setState({
                showSuccessModal: false, 
              })
            }}
            open={this.state.showSuccessModal}
            title={"Éxito"}
            message={"Edición realizada con éxito."}
            showCancel={false}
            cancelText="Cancelar"
            cancelButtonType="primary outline dark"
            acceptText="Aceptar"
            // disabledAccept={!this.state.selectedEquipment}
            acceptButtonType="primary"
            style={{ content: { width: "600px", marginLeft: "-300px" } }}
          ></BaseDialog>
        )}

        <Modal
          isOpen={this.state.showEditBaseModal}
          portalClassName="dialog confirm-dialog"
          overlayClassName="overlay"
          className="content invite-operator successModal"
        >
          <div className="title">Editar base</div>
          <div className="close" onClick={this.closeEditBaseModal}></div>
          <div className="message">
            <div style={{maxHeight: "300px", overflow: "auto"}}>
              {
                this.props.companyTags
                  .filter((f) => f.type === 3)
                  .map(tag => {
                    return <Tag
                      id={tag.id}
                      color={tag.color}
                      title={tag.title}
                      type={tag.type}
                      isFull={this.state.selectedBaseTags.includes(tag.id)}
                      parentBackground={'light'}
                      className={''}
                      onClick={this.handleTagClick}
                    />
                  })
              }
            </div>
          </div>
          <div className="actions successActions">
            <Button
              settings={{
                text: "Continuar",
                onClick: this.showConfirmEditBaseModal,
                disabled: !this.state.selectedBaseTags.length
              }}
            />
          </div>
        </Modal>
        
        <Modal
          isOpen={this.state.openXlsErrorModal}
          portalClassName="dialog confirm-dialog"
          overlayClassName="overlay"
          className="content invite-operator successModal"
        >
          <div className="title">Error</div>
          <div className="close" onClick={this.closeXlsErrorModal}></div>
          <div className="message">{this.state.descriptionImport.map(e => <div>{e}</div>)}</div>
          <div className="actions successActions">
            <Button
              settings={{
                text: "Aceptar",
                onClick: this.closeXlsErrorModal,
              }}
            />
          </div>
        </Modal>

        <Modal
          isOpen={this.state.updateTripsError !== ''}
          portalClassName="dialog confirm-dialog"
          overlayClassName="overlay"
          className="content invite-operator successModal"
        >
          <div className="title">Error</div>
          <div className="close" onClick={() => this.setState({updateTripsError: ''})}></div>
          <div className="message">{this.state.updateTripsError}</div>
          <div className="actions successActions">
            <Button
              settings={{
                text: "Aceptar",
                onClick: () => this.setState({updateTripsError: ''}),
              }}
            />
          </div>
        </Modal>

        <Modal
          isOpen={this.state.showError}
          portalClassName="dialog confirm-dialog"
          overlayClassName="overlay"
          className="content invite-operator successModal"
        >
          <div className="title">Error</div>
          <div className="close" onClick={() => {
            this.loadTrips();
            this.setState({showError: false})
          }}></div>
          <div className="message">No es posible iniciar el viaje manualmente, ya que tiene inicio automático o el viaje ya fue iniciado previamente.</div>
          <div className="actions successActions">
            <Button
              settings={{
                text: "Cerrar",
                onClick: () => {
                  this.loadTrips();
                  this.setState({showError: false})
                },
              }}
            />
          </div>
        </Modal>

        <Modal
          isOpen={this.state.showErrorMessage !== ''}
          portalClassName="dialog confirm-dialog"
          overlayClassName="overlay"
          className="content invite-operator successModal"
        >
          <div className="title">Error</div>
          <div className="close" onClick={() => {
            this.loadTrips();
            this.setState({showErrorMessage: ''})}
          }></div>
          <div className="message">{this.state.showErrorMessage}</div>
          <div className="actions successActions">
            <Button
              settings={{
                text: "Cerrar",
                onClick: () => {
                  this.loadTrips();
                  this.setState({showErrorMessage: ''})
                },
              }}
            />
          </div>
        </Modal>

        <ConfirmDialog
          open={this.state.openFinalizeTripModal}
          isAcceptLoading={this.state.tripsToFinalize !== 0}
          title="Finalizar viajes"
          message={"Esta interacción finalizará sin datos todas las órdenes de los viajes seleccionados, ¿Estás seguro que deseas continuar? "}
          contentObject={
            <UserChipContainer
              deleteTag={(id) => this.handleProductChipClick(id)}
              items={
                !Array.isArray(this.props.tripRows)
                  ? []
                  : this.props.tripRows
                      .filter((u) =>
                        this.state.selectedTripsId.includes(u.id)
                      )
                      .map((u) => {
                        return {
                          id: u.id,
                          name: u.tripNumber,
                          avatarType: "contained",
                        };
                      })
              }
            />
          }
          acceptText="Aceptar"
          class="delete-facility"
          showCancel={true}
          cancelText="Cancelar"
          cancelButtonType="secondary"
          closeAction={() => this.setState({openFinalizeTripModal: false})}
          acceptAction={() => {

            if (this.state.tripsToFinalize !== 0) { 
              this.setState({
                openFinalizeTripModal: false,
                selectionModeOn: false
              });
              return;
            }

            let trips = this.props.tripRows.filter((u) =>
              this.state.selectedTripsId.includes(u.id)
            );

            if (trips.length === 0) {
              this.setState({
                openFinalizeTripModal: false,
                selectionModeOn: false
              });
              return;
            }

            this.setState({
              tripsToFinalize: trips.length
            });

            for (var i = 0; i < trips.length; i++) {
              this.props.finalizeTrip( 
                {
                  company_id: this.props.companyId,
                  trip_id: trips[i].id,
                },
                {
                  onSuccess: () => {
                    this.tripsCounter = this.tripsCounter + 1
                    
                    if (this.tripsCounter === this.state.tripsToFinalize) {
                      this.finalizeTripsSuccess()
                    }
                  },
                  onError: () => {
                    this.tripsCounter = this.tripsCounter + 1;

                    if (this.tripsCounter === this.state.tripsToFinalize) {
                      this.finalizeTripsSuccess()
                    }
                  }
                }
              );
            }
          }}
        />

        <BaseDialog
          closeAction={() => {
            this.setState({
              showSuccessFinalizeTripsModal: false, 
              selectionModeOn: false
            })
          }}
          acceptAction={() => {
            this.setState({
              showSuccessFinalizeTripsModal: false, 
              selectionModeOn: false
            })
          }}
          open={this.state.showSuccessFinalizeTripsModal}
          title={"Éxito"}
          message={"Se finalizaron los viajes correctamente"}
          showCancel={false}
          cancelText="Cancelar"
          cancelButtonType="primary outline dark"
          acceptText="Aceptar"
          acceptButtonType="primary"
          style={{ content: { width: "600px", marginLeft: "-300px" } }}
        />

        <ErrorDialog
          open={this.state.showErrorMessageModal !== null}
          message={this.state.showErrorMessageModal}
          acceptText="Cerrar"
          acceptAction={() => {
            this.loadTrips();
            this.setState({ showErrorMessageModal: null });
          }}
          closeAction={() => {
            this.loadTrips();
            this.setState({ showErrorMessageModal: null });
          }}
        />

        <ErrorDialog
          open={this.state.msgError !== ''}
          message={this.state.msgError}
          acceptText="OK"
          acceptAction={() => this.setState({msgError: ''})}
          closeAction={() => this.setState({msgError: ''})}
        />

        { this.buildTransferEventsModal() }

      </View>
    );
  }

  handleProductChipClick = (id) => {
    let selectedTripsId = [...this.state.selectedTripsId];
    selectedTripsId.splice(
      selectedTripsId.findIndex((f) => f === id),
      1
    );
    this.setState({ selectedTripsId });
  };

  assignContainers = () => {
    let equipmentList = this.props.tripBookingAvailability
      .find(a => a.id.toString() === this.state.selectedAssembly)
      .equipments.filter(e => e.type.toLowerCase() !== "dolly");
    this.props.updateTrip(
      {
        company_id: this.props.companyId,
        trip_id: this.state.currentTrip.id,
        assembly: this.state.selectedAssembly,
        has_automatic_start: this.state.hasAutoStart ? "true" : "false",
        action: "set_assembly",
      }, {
        onSuccess: () => {
          this.loadTrips();
          this.state.currentTrip.details.forEach(d => {
            if(d.orderContainers.length < equipmentList.length){
              d.orderContainers.forEach((c) => {
                this.props.updateContainer({
                  company_id: this.props.companyId,
                  order_id: d.id,
                  container_id: c.id_container,
                  fleet_equipment: this.state.selectedEquipment,
                })
              })
            } else {
              d.orderContainers.forEach((c, i) => {
                this.props.updateContainer({
                  company_id: this.props.companyId,
                  order_id: d.id,
                  container_id: c.id_container,
                  fleet_equipment: equipmentList[i].id || 0,
                })
              })
            }
          });
          this.setState({
            showSelectEquipmentModal: false, 
            selectedAssembly: 0,
            currentTrip: undefined,
            selectedEquipment: 0,
            hasAutoStart: true,
          });
        },
        onError: () => {
          this.setState({
            showSelectEquipmentModal: false, 
            selectedAssembly: 0,
            currentTrip: undefined,
            selectedEquipment: 0,
            hasAutoStart: true,
          });
        }
      }
    );
  }

  getEquipmentModalContent = () => {
    let equipments = this.props.tripBookingAvailability
      .find(a => a.id.toString() === this.state.selectedAssembly)
      .equipments.filter(e => e.type.toLowerCase() !== "dolly")
      // debugger;
    return (
      <>
        <div style={{fontSize: "17px"}}>
          Selecciona el equipo que deseas utilizar
        </div>
        <div
          style={{
            width: "100%",
            marginTop: "5px",
            display: "flex",
            justifyContent: "space-around"
          }}
        >
          <RadioGroup
            items={
              equipments.map(e => ({
                value: e.id.toString(),
                label: e.economic_number.concat(" - ", e.plates),
              }))
            }
            radioName={"selectedEquipment"}
            defaultValue={this.state.selectedEquipment}
            onClickHandler={this.handleInput}
          />
        </div>
      </>
    )
  }

  handleInput = (e) => {
    let property = e.target.name;
    let value = e.target.value;
    this.setState({ [property]: value });
  }

  getModalContent = () => {
    return (
      <div className="balance-modal-content">
        <div style={{fontSize: "17px"}}>
          Al confirmar el viaje <b>{this.state.currentTrip.tripNumber}</b> se cobrarán: <span style={{color: "#3d77f7"}}>{this.props.tripBalance["trip"] && this.props.tripBalance["trip"].credits}</span>
        </div>
        <div className="tripTable">
          <div className="tripTable-header">
            <div>
              Folios
            </div>
            <div>
              Orbibucks
            </div>
          </div>
          <div className="tripTable-body">
            {
              this.props.tripBalance["trip"] &&
              this.props.tripBalance["trip"].orders.map(o => {
                return (
                  <div className="tripTable-row">
                    <div>{o.code}</div>
                    <div>{o.credits}</div>
                  </div>
                )
              })
            }
          </div>
        </div>
        <div>
          Tu nuevo saldo será: {this.props.tripBalance["trip"] && this.props.tripBalance["trip"].final_balance}
        </div>
        {
          (this.state.currentTrip.assemble &&
          this.state.currentTrip.details.some(d => (
            d.destination?.date || d.origin?.date
          ))) &&
          <div style={{marginTop: "10px"}}>
            <Checkbox
              name="auto"
              onChange={this.handleAutoStart}
              item={{
                id: 1,
                description: "Inicio automático",
                active: this.state.hasAutoStart,
                style: false
              }}
            />
          </div>
        }
        
      </div>
    )
  }

  getAssembleItem = (r) => {
    return (
      <div data-place="right" data-tip data-for={`order-assemble-${r.id}`}>
        {r.assemble &&
          (Object.keys(r.assemble.vehicles).length > 0 ||
            Object.keys(r.assemble.equipments).length > 0 ||
            Object.keys(r.assemble.operators).length > 0) && (
            <ReactTooltip 
              id={`order-assemble-${r.id}`}
              clickable={true}
              effect="solid"
              delayHide={200}
            > 
              {Object.values(r.assemble.vehicles)
                .flat()
                .map((e) => (
                  <AssemblyOption
                    onClick={() => {
                      if (e.fleet_data_id) {
                        this.props.history.push(
                          `/company/fleet/vehicle/profile/${
                            e.fleet_data_id
                          }/?isShared=0`
                        );
                      }
                    }}
                  >
                    <AssembleAvatar
                      fleetTypeId={e.type}
                      description={`${e.economic_number} - ${e.plates}`}
                      //  color?: string,
                      showIcon={true}
                      //  icon?: any,
                    />
                  </AssemblyOption>
                ))}
              {Object.values(r.assemble.equipments)
                .flat()
                .map((e) => (
                  <AssemblyOption
                    onClick={() => {
                      if (e.fleet_data_id) {
                        this.props.history.push(
                          `/company/fleet/equipment/profile/${
                            e.fleet_data_id
                          }/?isShared=0`
                        );
                      }
                    }}
                  >
                    <AssembleAvatar
                      fleetTypeId={e.type}
                      description={`${e.economic_number} - ${e.plates}`}
                      //  color?: string,
                      showIcon={true}
                      //  icon?: any,
                    />
                  </AssemblyOption>
                ))}
              {Object.values(r.assemble.operators)
                .flat()
                .map((e) => (
                  <AssemblyOption
                    onClick={() => {
                      if (e.operator_id) {
                        this.props.history.push(
                          `/company/fleet/operator/profile/${
                            e.operator_id
                          }/?isShared=0`
                        );
                      }
                    }}
                  >
                    <AssembleAvatar
                      fleetTypeId={e.type}
                      description={`${e.first_name} ${e.last_name}`}
                      //  color?: string,
                      showIcon={true}
                      //  icon?: any,
                    />
                  </AssemblyOption>
                ))}
            </ReactTooltip>
          )}
        <div>
          {getAssembleIconByAssemble(r.assemble, r.is_unhooked)}
          {r.assemble && Object.keys(r.assemble.vehicles).length && (
            <div>{
              Object.values(r.assemble.vehicles)
                .flat()
                .map((e) => e.economic_number)
              }</div>
          )}
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state): StateToProps => {
  const tripBookingAvailabilityLoading = state.api["TripBookingAvailability"]?.status?.isFetching;
  const isConfirmingLoading = state.api["COMPANIES.TRIPS"]?.status?.isFetching || false;
  const companyId = state.globalCatalog.session.company.id;
  const tripItemCount = state.api["tripList"]?.count || 0;
  const trips = getEntityItems(state, "tripList");
  const tripBalance = getEntityItems(state, "COMPANIES.TRIPS.BALANCE");
  const tripRows = trips.map(Utils.mapTrips);
  const companyData = state.globalCatalog.session.company;
  const tripBookingAvailability = getEntityItems(
    state,
    "TripBookingAvailability"
  ).map(Utils.mapTripBookingAvailability);

  const getTransferDataLoading = state.api["COMPANIES.TRIPS.CANDIDATES"]?.status?.isFetching;

  const tripStatuses = getEntityItems(
    state,
    "COMPANIES.CATALOGS.TRIPSTATUS"
  ).map((f) => ({
    description: f.description,
    id: f.id || 0,
  }));
  
  let orderStatus = getEntityItems(
    state,
    "COMPANIES.CATALOGS.ORDERSTATUS"
  ).map((f) => ({
    description: f.description,
    id: f.id || 0,
  }));

  if (orderStatus && orderStatus.length && orderStatus.length > 0) {
    orderStatus = orderStatus.filter(status => {
      return ![4,10,12].includes(status.id);
    });
  }

  // let transportClass = [];
  // console.log("STATE", state)

  let transportClass = getEntityItems(
    state,
    "COMPANIES.SHIPMENTS.LT.FILTERS"
  )/* .map((f) = ({
    description: f.orbi_id,
    id: f.id,
    items: f.transport_classes.map((tc) => ({
      id: tc.id,
      description: tc.code,
    })),
  })); */
  let tempTransportClass = [];

  (transportClass || []).forEach(e => {
    (e.transport_classes_tags || []).forEach(t => {
      if(tempTransportClass.find(toFind => toFind.description === `${e.orbi_id} - ${t.tags.title}`) === undefined){
        const classes = (e.transport_classes_tags || []).filter(tag => tag.tags.title === t.tags.title)
        
        let selectedClasses = []
        classes.forEach(s => {
          (e.transport_classes || []).forEach(classe => {
            if(classe.id === s.transport_class){
              selectedClasses.push(classe)
            }
          })
        })

        tempTransportClass.push(
          { 
            description: `${e.orbi_id} - ${t.tags.title}`,
            id: e.id,
            items: selectedClasses.map((tc) => ({
              id: tc.id,
              description: tc.code,
            })),
          }
        )
      }
    })
  });

  transportClass = tempTransportClass

  const companyProviders = getEntityItems(state, "COMPANIES.NETWORK").map(
    (f) => ({
      description: f.fleet_provider.orbi_id || f.fleet_provider.corporation_name,
      id: f.fleet_provider.fleet_provider_id || 0,
    })
  );

  let ownCompanyType = (state.globalCatalog.session.company.type || { id: 0 })
    .id;

  const facilities =
    ownCompanyType !== 1
      ? state.facilityReducer.facilities.data &&
        state.facilityReducer.facilities.data
          .map((f) => ({
            id: f.id,
            description: f.name.concat(" - ", f.code, " - ", f.alias),
            name: f.name,
            code: f.code,
            alias: f.alias,
          }))
          .sort(function(a, b) {
            if (a.name > b.name) {
              return 1;
            }
            if (a.name < b.name) {
              return -1;
            }
            return 0;
          })
      : (getEntityItems(state, "COMPANIES.CONTRACTS.FILTERS.FACILITIES") || [])
          .map((f) => ({
            id: f.id,
            description: f.name.concat(" - ", f.code, " - ", f.alias),
            name: f.name,
            code: f.code,
            alias: f.alias,
          }))
          .sort(function(a, b) {
            if (a.name > b.name) {
              return 1;
            }
            if (a.name < b.name) {
              return -1;
            }
            return 0;
          });
    
  const companyTags = getEntityItems(state, "COMPANIES.TAGS");

  return {
    isConfirmingLoading,
    tripBookingAvailabilityLoading,
    configuration: state.configuration.AssembleShipmentList,
    tripBookingAvailability,
    tripRows,
    companyId,
    tripItemCount,
    companyData,
    tripStatuses,
    orderStatus,
    transportClass,
    companyProviders,
    facilities,
    tripBalance,
    getTransferDataLoading,
    companyTags
  };
};
const mapDispatchToProps = (dispatch): DispatchToProps => {
  dispatch(readEntities("COMPANIES.CATALOGS.TRIPSTATUS"));
  return {
    cancelRequest: (entityPath: string) => dispatch(cancelRequest(entityPath)),
    setOrderDetail: (payload) => dispatch(setOrderSeleted(payload)),
    validateCFDI: (params, opt) => dispatch(readEntity("COMPANIES.SAT.TAXINFO.VALIDATION", params, opt)),
    updateTripBase: (params, opt) =>
      dispatch(partialEntity("COMPANIES.TRIPS", params, opt)),
    loadCompanyTags: (params, opts) =>
    dispatch(
      readEntities(
        "COMPANIES.TAGS",
        params,
        opts
      )
    ),
    setBaseConfig: (config) => dispatch(setConfig("AssembleShipmentList", config)),
    loadOrderStatus: () => dispatch(readEntities("COMPANIES.CATALOGS.ORDERSTATUS")),
    listVehicles: (params, opts) =>
      dispatch(
        readCatalog(
          "COMPANIES.CATALOGS.VEHICLE",
          params,
          opts
        )
      ),
    getCandidates: (params, opts) =>
      dispatch(readEntity("COMPANIES.TRIPS.CANDIDATES", params, opts)),
    transferEvents: (params, opts) =>
      dispatch(partialEntity("COMPANIES.TRIPS.CANDIDATES", params, opts)),
    getTripBalance: (params, opts) =>
      dispatch(readEntity("COMPANIES.TRIPS.BALANCE", params, opts)),
    listTrips: (params, opts) =>
      dispatch(readCatalog("COMPANIES.TRIPS", params, opts)), 
    updateTrip: (params, opts) =>
      dispatch(partialEntity("COMPANIES.TRIPS", params, opts)),
    updateContainer: (params, opts) =>
      dispatch(partialEntity("COMPANIES.ORDERS.CONTAINERS", params, opts)),
    loadTrip: (params, opts) =>
      dispatch(readEntity("COMPANIES.TRIPS", params, opts)),
    loadCompanyProviders: (params, opts) =>
      dispatch(readEntities("COMPANIES.NETWORK", params, opts)),
    loadTransportClass: (params, opt) => 
      dispatch(readEntities("COMPANIES.SHIPMENTS.LT.FILTERS", params, opt)),
    // loadFilterFacilities: (company_id) => {
    //   dispatch(
    //     readEntities("COMPANIES.CONTRACTS.FILTERS.FACILITIES", {
    //       company_id,
    //     })
    //   );
    // },
    loadStatusTrip: (company_id, trip_id, opt) =>
      dispatch(readEntity("COMPANIES.TRIPS", { company_id, trip_id, view: "status_detail" }, opt)),
    loadFilterFacilities: (params, options) =>
      dispatch(
        readEntities(
          "COMPANIES.TRIPS",
          { ...params, action: "get_facilities" },
          options
        )
      ),
    resetTrip: (params, options) =>
      dispatch(
        partialEntity(
          "COMPANIES.TRIPS",
          { ...params, action: "reset_trip" },
          options
        )
      ),
    startTrip: (params, options) =>
      dispatch(
        partialEntity(
          "COMPANIES.TRIPS",
          { ...params, action: "start_trip" },
          options
        )
      ),
    finalizeTrip: (params, options) =>
      dispatch(
        partialEntity(
          "COMPANIES.TRIPS",
          { ...params, action: "finalize_trip" },
          options
        )
      ),
    finalizeOrder: (params: any, opts: ApiOptions) =>
      dispatch(
        partialEntity(
          "COMPANIES.ORDERS",
          { ...params, action: "finalize_order" },
          opts
        )
      ),
    cancelCFDI: (params, opt) => dispatch(createEntity("COMPANIES.SAT.TAXINFO.CANCELLATION.CANCEL", params, opt)),
    setNavbarAction: (name, config) => dispatch(setNavbarAction(name, config)),
    removeNavbarAction: (name) => dispatch(removeNavbarAction(name))
  };
};
export default connect<Props, OwnProps, StateToProps, DispatchToProps, _, _>(
  mapStateToProps,
  mapDispatchToProps
)(AssembleShipmentList);


const CaretContainer = styled.div`
  align-self: center;
  cursor: pointer;
`;

export const AssemblyOption = styled.div`
  &:hover {
    background-color: #98bcdf;
    cursor: pointer;
  }
`;