import Axios from "axios";
import { cloneDeep } from "lodash";
import React from "react";
import { connect } from 'react-redux';
import { cancelRequest, getEntityItems, readEntities, readEntity } from "../../redux/api";
import { listNetworkApprovedInvitations } from "../../redux/reducers/company-reducer";
import { Scroll } from "../../shared/animation";
import FilterBar from "../../shared/component/filter/filter-bar";
import { FILTER_LIST_TYPES } from "../../shared/component/filter/filter-bar-content";
import ClockAlert from "../../shared/icons/tat/clockAlert";
import Loader from "../../shared/icons/tat/loader";
import { View } from "../../shared/styled/view";
import moment from 'moment';
import DownloadImage from "../../shared/icons/routes/downloadIcon";
import { SERVER } from "../../redux/api/api-urls";
import HelperFilesModal from '../../shared/component/helper_files_modal';
import { setNavbarAction, removeNavbarAction } from "../../redux/reducers/navbar-reducer";
import ErrorDialog from "../../shared/dialog/error-dialog";
import styled from "styled-components";
import { isPremiumSubscriptionCo } from "../../shared/utils";

const today = new Date();
const yesterday = new Date(today.setDate(today.getDate() - 1));

const dateToString = (date) => {
  let dateStr = date.getFullYear();
  dateStr += "-" + (date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1);
  dateStr += "-" + (date.getDate() < 10 ? "0" : "") + (date.getDate());
  return dateStr;
}

const CATEGORIES = [
  {
    description: 'Órdenes',
    value: 'orders'
  },
  {
    description: 'Asignación',
    value: 'allocation'
  },
  {
    description: 'Adopción',
    value: 'adoption'
  },
  {
    description: 'Utilización',
    value: 'utilization'
  },
  {
    description: 'Calidad',
    value: 'quality'
  },
]

class UtilizationViewv2 extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      searchStr: "",
      filterDate: [yesterday],
      filterDateTemp: [yesterday],
      filterFacilities: [],
      filterOrigin: [],
      filterDestination: [],
      filterTransportClass: [],
      filterTags: [],
      filterTags2: [],
      filterBaseTags: [],
      filterCO: [],
      filterLT: [],
      filterDateType: ["3"],
      filterSupplement1: [],

      selectedMonth: (yesterday).getMonth(),

      graphStartDate: props.isEmail ? dateToString(new Date(yesterday.getFullYear(), yesterday.getMonth(), 1)) : "", 
      graphEndDate: props.isEmail ? dateToString(yesterday) : "",
      
      motivesGraphStartDate: props.isEmail ? dateToString(new Date(yesterday.getFullYear(), yesterday.getMonth(), 1)) : "", 
      motivesGraphEndDate: props.isEmail ? dateToString(yesterday) : "",

      graphGroupBy: '',
      
      showParetoOffenders: false,

      selectedGAPMode: 'table',
      selectedGAPMode1: 'motives',
      selectedParetoIndicator: "utilization",
      selectedParetoIndicator1: "utilization",
      selectedParetoGrouping: "economic_number",

      showGAPModule: false,
      showFoliosModule: false,
      showGAPModule1: false,
      showFoliosModule1: false,

      buttonFilterName: "Aplicar",

      firstLoaded: false,

      fleetProviderToRequest: 0,
      platesToRequest: '',
      operatorsToRequest: '',
      economicToRequest: '',

      selectedParetoZoomMode: 'zoom',
      selectedParetoPage: 2,
      zoomDataLength: 0,

      isLoading: false,
      isLoadingGlobal: false,
      isLoadingData: false,
      isLoadingOffenders: false,
      isLoadingFolios: false,
      isLoadingFolios1: false,
      isLoadingGraph: false,
      isLoadingGraph1: false,
      isLoadingGraph2: false,
      isLoadingDaily: false,
      isLoadingWeekly: false,
      showErrorMessage: false,
      msgError: '',
      isFreeSubscription: false
    }

    this.apiLoaded = false;
    this.paretoApiLoaded = false;
  }

  componentDidMount() {

    if (this.props.isCO && !this.props.isPremiumSubscriptionCo) {
      this.setState({isFreeSubscription: true});
      return;
    }

    if(this.props.companyType === 1){
      this.props.loadLTFilters({
        company_id: this.props.companyId
      })
    }
    this.props.loadCompanyProviders({
      company_id: this.props.companyId,
      view: "booking",
    });
    this.props.loadCompanyFacilities({
      company_id: this.props.companyId,
      view: "grouped",
    });
    this.props.loadCompanyTags(this.props.companyId);
    this.props.loadCompanyTransportClass(this.props.companyId);
    this.props.loadCompanySupplement1({company_id: this.props.companyId})
    this.loadApi().then(value => {
      this.loadParetoApi().then(value2 => {
        this.paretoApiLoaded = value2;
        // this.loadGlobal();
        // if(this.props.isEmail) this.loadDesglose();
        this.loadInitialData();
      });
      this.apiLoaded = value;
    });
    this.props.setNavbarAction("utilization", [
      {
        icon: <HelperFilesModal
          files={[]}
        />
      }
    ]);
  }

  componentWillUnmount() {
    this.props.removeNavbarAction("utilization");
  }

  showError = (e) => {
    if (e.response === undefined) {
      return
    }

    this.props.cancelRequest('COMPANIES.UTILIZATIONV2.DATE');
    this.props.cancelRequest('COMPANIES.UTILIZATIONV2.GLOBAL');
    this.props.cancelRequest('COMPANIES.UTILIZATIONV2.DAILY');
    this.props.cancelRequest('COMPANIES.UTILIZATIONV2.WEEKLY');
    this.props.cancelRequest('COMPANIES.UTILIZATIONV2.OFFENDERS');
    this.props.cancelRequest('COMPANIES.UTILIZATIONV2.FOLIOS');

    this.setState({
      isLoading: false,
      isLoadingGlobal: false,
      isLoadingData: false,
      isLoadingOffenders: false,
      isLoadingFolios: false,
      isLoadingFolios1: false,
      isLoadingGraph: false,
      isLoadingGraph1: false,
      isLoadingGraph2: false,
      isLoadingDaily: false,
      isLoadingWeekly: false,
      showErrorMessage: true
    });
  }

  loadParetoApi = () => {
    return new Promise((resolve) => {
      if (this.paretoApiLoaded) resolve(true);

      let mapApiScript = Array.from(
        document.getElementsByTagName("script")
      ).find((script) =>
        script.src.includes("https://code.highcharts.com/modules/pareto.js")
      );

      if (!mapApiScript) {
        mapApiScript = document.createElement("script");
        mapApiScript.src = `https://code.highcharts.com/modules/pareto.js`;
        window.document.body.appendChild(mapApiScript);
        mapApiScript.addEventListener("load", () => {
          resolve(true);
        });
      } else {
        resolve(true);
      }
    });
  };

  loadApi = () => {
    return new Promise((resolve) => {
      if (this.apiLoaded) resolve(true);

      let mapApiScript = Array.from(
        document.getElementsByTagName("script")
      ).find((script) =>
        script.src.includes("https://code.highcharts.com/highcharts.js")
      );

      if (!mapApiScript) {
        mapApiScript = document.createElement("script");
        mapApiScript.src = `https://code.highcharts.com/highcharts.js`;
        window.document.body.appendChild(mapApiScript);
        mapApiScript.addEventListener("load", () => {
          resolve(true);
        });
      } else {
        resolve(true);
      }
    });
  };

  loadInitialData = () => { 
    this.props.cancelRequest('COMPANIES.UTILIZATIONV2.DATE');
    this.props.cancelRequest('COMPANIES.UTILIZATIONV2.GLOBAL');
    this.setState({
      isLoadingGlobal: true,
      isLoadingData: true
    });

    this.props.loadUtilizationDate({
      company_id: this.props.companyId,
      start_date: dateToString(new Date(this.state.filterDate[0].getFullYear(), this.state.filterDate[0].getMonth(), 1)),
      end_date: dateToString(this.state.filterDate[0]),
      appointment_type: (this.state.filterDateType || []).join(",") || "",
      facilities: this.state.filterFacilities,
      origin: this.state.filterOrigin,
      destination: this.state.filterDestination,
      base_tags: this.state.filterBaseTags,
      trip_tags: this.state.filterTags,
      order_tags: this.state.filterTags2,
      transport_class: this.state.filterTransportClass,
      fleet_provider: this.state.filterLT,
      supplement: this.state.filterSupplement1
    }, {
      onSuccess: () => {
        this.setState({
          isLoadingData: false
        }, () => {
          this.renderCanvas();
        });
      },
      onError: (e) => this.showError(e)
    });
    this.props.loadUtilizationGlobal({
      company_id: this.props.companyId,
      start_date: dateToString(new Date(this.state.filterDate[0].getFullYear(), 0, 1)),
      end_date: dateToString(this.state.filterDate[0]),
      appointment_type: (this.state.filterDateType || []).join(",") || "",
      facilities: this.state.filterFacilities,
      origin: this.state.filterOrigin,
      destination: this.state.filterDestination,
      base_tags: this.state.filterBaseTags,
      trip_tags: this.state.filterTags,
      order_tags: this.state.filterTags2,
      transport_class: this.state.filterTransportClass,
      fleet_provider: this.state.filterLT,
      supplement: this.state.filterSupplement1
    }, {
      onSuccess: () => {
        this.setState({
          isLoadingGlobal: false
        }, () => {
          // this.graphDaily();
          // this.graphWeekly();
          this.graphMonthly();
        });
      },
      onError: (e) => this.showError(e)
    });

    this.loadWeekInfo();
    this.loadDailyInfo();
  }

  loadDailyInfo = () => {
    this.props.cancelRequest('COMPANIES.UTILIZATIONV2.DAILY');

    let date = new Date();
    date.setMonth(this.state.selectedMonth);
    date.setFullYear(this.state.filterDate[0].getFullYear());

    this.setState({isLoadingDaily: true})

    this.props.loadUtilizationDaily({
      company_id: this.props.companyId,
      start_date: dateToString(date),
      end_date: dateToString(date),
      appointment_type: (this.state.filterDateType || []).join(",") || "",
      facilities: this.state.filterFacilities,
      origin: this.state.filterOrigin,
      destination: this.state.filterDestination,
      base_tags: this.state.filterBaseTags,
      trip_tags: this.state.filterTags,
      order_tags: this.state.filterTags2,
      transport_class: this.state.filterTransportClass,
      fleet_provider: this.state.filterLT,
      supplement: this.state.filterSupplement1
    }, {
      onSuccess: () => {
        this.setState({isLoadingDaily: false}, () => {this.graphDaily(); this.graphWeekly();})
      },
      onError: (e) => this.showError(e)
    });
  }

  loadWeekInfo = () => {
    this.props.cancelRequest('COMPANIES.UTILIZATIONV2.WEEKLY');
    this.setState({isLoadingWeekly: true})

    this.props.loadUtilizationWeekly({
      company_id: this.props.companyId,
      start_date: dateToString(new Date(this.state.filterDate[0].getFullYear(), 0, 1)),
      end_date: dateToString(this.state.filterDate[0]),
      appointment_type: (this.state.filterDateType || []).join(",") || "",
      facilities: this.state.filterFacilities,
      origin: this.state.filterOrigin,
      destination: this.state.filterDestination,
      base_tags: this.state.filterBaseTags,
      trip_tags: this.state.filterTags,
      order_tags: this.state.filterTags2,
      transport_class: this.state.filterTransportClass,
      fleet_provider: this.state.filterLT,
      supplement: this.state.filterSupplement1
    }, {
      onSuccess: () => {
        this.setState({isLoadingWeekly: false}, () => {this.graphDaily(); this.graphWeekly();})
      },
      onError: (e) => this.showError(e)
    }) 
  }

  getUrl = (module) => {
    switch(module){
      case "offenders":
        return `${SERVER}/api/v2/companies/${this.props.companyId}/utilization_v2/?action=download&view=top_offenders&start_date=${this.state.graphStartDate.includes("Semana") ? dateToString(this.state.filterDate[0]) : this.state.graphStartDate}&end_date=${this.state.graphEndDate.includes("Semana") ? dateToString(this.state.filterDate[0]) : this.state.graphEndDate}&appointment_type=${(this.state.filterDateType || []).join(",") || ""}&week=${this.state.graphStartDate.includes("Semana") ? Number(this.state.graphStartDate.split(' ')[1]) : ''}&facilities=${this.state.filterFacilities}&from_facilities=${this.state.filterOrigin}&to_facilities=${this.state.filterDestination}&trip_tags=${this.state.filterTags}&order_tags=${this.state.filterTags2}&transport_class=${this.state.filterTransportClass}&fleet_provider=${this.state.filterLT}&supplement=${this.state.filterSupplement1}&base_tags=${this.state.filterBaseTags}`
      case "folios":
        return `${SERVER}/api/v2/companies/${this.props.companyId}/utilization_v2/?action=download&view=${this.getViewToRequest()}&start_date=${this.state.graphStartDate.includes("Semana") ? dateToString(this.state.filterDate[0]) : this.state.graphStartDate}&end_date=${this.state.graphEndDate.includes("Semana") ? dateToString(this.state.filterDate[0]) : this.state.graphEndDate}&appointment_type=${(this.state.filterDateType || []).join(",") || ""}&week=${this.state.graphStartDate.includes("Semana") ? Number(this.state.graphStartDate.split(' ')[1]) : ''}&facilities=${this.state.filterFacilities}&from_facilities=${this.state.filterOrigin}&to_facilities=${this.state.filterDestination}&trip_tags=${this.state.filterTags}&order_tags=${this.state.filterTags2}&transport_class=${this.state.filterTransportClass}&fleet_provider=${this.state.fleetProviderToRequest}&operators=${this.state.operatorsToRequest}&economic_number=${this.state.economicToRequest}&plates=${this.state.platesToRequest}&supplement=${this.state.filterSupplement1}&base_tags=${this.state.filterBaseTags}`
      case "folios1":
        return `${SERVER}/api/v2/companies/${this.props.companyId}/utilization_v2/?action=download&view=${this.getViewToRequest1()}&start_date=${this.state.graphStartDate.includes("Semana") ? dateToString(this.state.filterDate[0]) : this.state.graphStartDate}&end_date=${this.state.graphEndDate.includes("Semana") ? dateToString(this.state.filterDate[0]) : this.state.graphEndDate}&appointment_type=${(this.state.filterDateType || []).join(",") || ""}&week=${this.state.graphStartDate.includes("Semana") ? Number(this.state.graphStartDate.split(' ')[1]) : ''}&facilities=${this.state.filterFacilities}&from_facilities=${this.state.filterOrigin}&to_facilities=${this.state.filterDestination}&trip_tags=${this.state.filterTags}&order_tags=${this.state.filterTags2}&transport_class=${this.state.filterTransportClass}&fleet_provider=${this.state.filterLT}&operators=&economic_number=&plates=&supplement=${this.state.filterSupplement1}&base_tags=${this.state.filterBaseTags}`
      case "gap":
        return `${SERVER}/api/`
      default: 

    }
  }

  loadOffenders = () => {
    this.props.cancelRequest('COMPANIES.UTILIZATIONV2.OFFENDERS');
    this.setState({
      isLoadingOffenders: true
    });

    this.props.loadUtilizationOffenders({
      company_id: this.props.companyId,
      start_date: this.state.graphStartDate.includes("Semana") ? dateToString(this.state.filterDate[0]) : this.state.graphStartDate,
      end_date: this.state.graphEndDate.includes("Semana") ? dateToString(this.state.filterDate[0]) : this.state.graphEndDate,
      appointment_type: (this.state.filterDateType || []).join(",") || "",
      week: this.state.graphStartDate.includes("Semana") ? Number(this.state.graphStartDate.split(' ')[1]) : '',
      facilities: this.state.filterFacilities,
      origin: this.state.filterOrigin,
      destination: this.state.filterDestination,
      trip_tags: this.state.filterTags,
      order_tags: this.state.filterTags2,
      transport_class: this.state.filterTransportClass,
      fleet_provider: this.state.filterLT,
      supplement: this.state.filterSupplement1,
      base_tags: this.state.filterBaseTags,
      top_offenders_group: this.props.companyType === 2 ? '' : this.state.selectedParetoGrouping
    }, {
      onSuccess: () => {
        this.setState({
          isLoadingOffenders: false
        }, () => {
          this.graphPareto();
        })
      },
      onError: (e) => this.showError(e)
    })
    // Scroll.go(document.getElementById('paretoRutaPanel'), document.getElementById('pageContent'));
  }

  loadGap1 = () => {
    this.props.cancelRequest('COMPANIES.UTILIZATIONV2.FOLIOS');
    this.setState({
      isLoadingFolios1: true
    });

    this.props.loadUtilizationFolios({
      company_id: this.props.companyId,
      start_date: this.state.graphStartDate.includes("Semana") ? dateToString(this.state.filterDate[0]) : this.state.graphStartDate,
      end_date: this.state.graphEndDate.includes("Semana") ? dateToString(this.state.filterDate[0]) : this.state.graphEndDate,
      appointment_type: (this.state.filterDateType || []).join(",") || "",
      facilities: this.state.filterFacilities,
      origin: this.state.filterOrigin,
      destination: this.state.filterDestination,
      trip_tags: this.state.filterTags,
      order_tags: this.state.filterTags2,
      transport_class: this.state.filterTransportClass,
      fleet_provider: this.state.filterLT,
      plates: '',
      economic_number: '',
      operators: '',
      supplement: this.state.filterSupplement1,
      base_tags: this.state.filterBaseTags,
      week: this.state.graphStartDate.includes("Semana") ? Number(this.state.graphStartDate.split(' ')[1]) : '',
      view: this.getViewToRequest1()
    }, {
      onSuccess: () => { 
        this.setState({isLoadingFolios1: false})
      },
      onError: (e) => this.showError(e),
      alias: "foliosData1"
    });

    Scroll.go(document.getElementById('paretoGapFolios1'), document.getElementById('pageContent'));
  }

  loadGap2 = () => {
    this.props.cancelRequest('COMPANIES.UTILIZATIONV2.FOLIOS');
    this.props.cancelRequest('graphData2');

    this.setState({
      isLoadingGraph2: true
    });

    this.props.loadUtilizationFolios({
      company_id: this.props.companyId,
      start_date: this.state.graphStartDate.includes("Semana") ? dateToString(this.state.filterDate[0]) : this.state.graphStartDate,
      end_date: this.state.graphEndDate.includes("Semana") ? dateToString(this.state.filterDate[0]) : this.state.graphEndDate,
      appointment_type: (this.state.filterDateType || []).join(",") || "",
      facilities: this.state.filterFacilities,
      origin: this.state.filterOrigin,
      destination: this.state.filterDestination,
      trip_tags: this.state.filterTags,
      order_tags: this.state.filterTags2,
      transport_class: this.state.filterTransportClass,
      fleet_provider: this.state.filterLT,
      plates: '',
      economic_number: '',
      operators: '',
      supplement: this.state.filterSupplement1,
      base_tags: this.state.filterBaseTags,
      week: this.state.graphStartDate.includes("Semana") ? Number(this.state.graphStartDate.split(' ')[1]) : '',
      view: this.getViewToRequest2()
    }, {
      onSuccess: () => {
        this.setState({
          isLoadingGraph2: false
        });
      },
      onError: (e) => this.showError(e),
      alias: "foliosData2"
    });

    if(this.state.selectedGAPMode1 === "table" || this.state.selectedGAPMode1 === "process"){
      document.getElementById("GAPChart1").innerHTML = "";
    }

    let weeks = [];
    if(this.state.motivesGraphStartDate.includes("Semana")){
      for(let w = 1; w <= Number(this.state.motivesGraphEndDate.split(' ')[1]); w++){
        weeks.push(w);
      }
    }

    if (this.state.selectedParetoIndicator === 'adoption') {
      this.setState({isLoadingGraph1: true})
      this.props.loadUtilizationGraph({
        company_id: this.props.companyId,
        start_date: this.state.motivesGraphStartDate.includes("Semana") ? dateToString(this.state.filterDate[0]) : this.state.motivesGraphStartDate,
        end_date: this.state.motivesGraphEndDate.includes("Semana") ? dateToString(this.state.filterDate[0]) : this.state.motivesGraphEndDate,
        facilities: this.state.filterFacilities,
        origin: this.state.filterOrigin,
        destination: this.state.filterDestination,
        base_tags: this.state.filterBaseTags,
        trip_tags: this.state.filterTags,
        order_tags: this.state.filterTags2,
        transport_class: this.state.filterTransportClass,
        fleet_provider: this.state.filterLT,
        supplement: this.state.filterSupplement1,
        appointment_type: (this.state.filterDateType || []).join(",") || "",
        // week: this.state.motivesGraphStartDate.includes("Semana") ? Number(this.state.motivesGraphStartDate.split(' ')[1]) : '',
        week: weeks, 
        plates: '',
        economic_number: '',
        operators: '',
        group_by: this.state.graphGroupBy,
        view: 'adoption_motives'
      }, {
        onSuccess: () => {
          this.setState({isLoadingGraph1: false}, () => {
            this.graphGAP1();
          });
        },
        onError: (e) => this.showError(e),
        alias: 'graphData1'
      });
    } else if (this.state.selectedParetoIndicator === 'utilization') {
      this.setState({isLoadingGraph1: true})
      this.props.loadUtilizationGraph({
        company_id: this.props.companyId,
        start_date: this.state.motivesGraphStartDate.includes("Semana") ? dateToString(this.state.filterDate[0]) : this.state.motivesGraphStartDate,
        end_date: this.state.motivesGraphEndDate.includes("Semana") ? dateToString(this.state.filterDate[0]) : this.state.motivesGraphEndDate,
        facilities: this.state.filterFacilities,
        origin: this.state.filterOrigin,
        destination: this.state.filterDestination,
        base_tags: this.state.filterBaseTags,
        trip_tags: this.state.filterTags,
        order_tags: this.state.filterTags2,
        transport_class: this.state.filterTransportClass,
        fleet_provider: this.state.filterLT,
        supplement: this.state.filterSupplement1,
        appointment_type: (this.state.filterDateType || []).join(",") || "",
        // week: this.state.motivesGraphStartDate.includes("Semana") ? Number(this.state.motivesGraphStartDate.split(' ')[1]) : '',
        week: weeks,
        plates: '',
        economic_number: '',
        operators: '',
        group_by: this.state.graphGroupBy,
        view: 'utilization_motives'
      }, {
        onSuccess: () => {
          this.setState({isLoadingGraph1: false}, () => {
            this.graphGAP1()
          })
        },
        onError: (e) => this.showError(e),
        alias: 'graphData1'
      });
    }

    // Scroll.go(document.getElementById('paretoGapFolios1'), document.getElementById('pageContent'));
  }

  loadGap = () => {
    this.props.cancelRequest('COMPANIES.UTILIZATIONV2.FOLIOS');
    this.setState({
      isLoadingFolios: true
    });

    this.props.loadUtilizationFolios({
      company_id: this.props.companyId,
      start_date: this.state.graphStartDate.includes("Semana") ? dateToString(this.state.filterDate[0]) : this.state.graphStartDate,
      end_date: this.state.graphEndDate.includes("Semana") ? dateToString(this.state.filterDate[0]) : this.state.graphEndDate,
      appointment_type: (this.state.filterDateType || []).join(",") || "",
      facilities: this.state.filterFacilities,
      origin: this.state.filterOrigin,
      destination: this.state.filterDestination,
      trip_tags: this.state.filterTags,
      order_tags: this.state.filterTags2,
      transport_class: this.state.filterTransportClass,
      fleet_provider: this.state.fleetProviderToRequest,
      plates: this.state.platesToRequest,
      economic_number: this.state.economicToRequest,
      operators: this.state.operatorsToRequest,
      supplement: this.state.filterSupplement1,
      base_tags: this.state.filterBaseTags,
      week: this.state.graphStartDate.includes("Semana") ? Number(this.state.graphStartDate.split(' ')[1]) : '',
      view: this.getViewToRequest()
    }, {
      onSuccess: () => { 
        this.setState({
          isLoadingFolios: false
        });
      },
      onError: (e) => this.showError(e)
    });

    Scroll.go(document.getElementById('paretoGapFolios'), document.getElementById('pageContent'));
  }

  hideLastModules = () => {
    this.setState({
      showParetoOffenders: false,
      showGAPModule: false,
      showFoliosModule: false,
      showGAPModule1: false,
      showFoliosModule1: false
    })
  }

  //FILTROS
  
  handleTextSearch = (name, value) => {
    this.setState({ searchStr: value });
  }

  handleFilterChange = (e) => {
    if(e.target.name === "filterFacilities"){
      this.setState({ [e.target.name]: e.target.value, filterOrigin: [], filterDestination: [], buttonFilterName: "Aplicar" });
    } else if(e.target.name === "filterOrigin" || e.target.name === "filterDestination"){
      this.setState({ [e.target.name]: e.target.value, filterFacilities: [], buttonFilterName: "Aplicar" });
    } else {
      this.setState({ [e.target.name]: e.target.value, buttonFilterName: "Aplicar" });
    }
  }

  handleFilter = () => {
    if(this.state.buttonFilterName === "Aplicar"){
      this.setState({ buttonFilterName: "Restablecer", selectedMonth: this.state.filterDateTemp[0].getMonth(), filterDate: this.state.filterDateTemp}, () => {
        this.loadInitialData();
        this.hideLastModules();
      })
    } else {
      this.setState({  buttonFilterName: "Aplicar" }, () => this.handlerClean() );
    }
  };

  handlerClean = () => {
    this.setState({
      filterDate: [yesterday],
      filterDateTemp: [yesterday],
      filterFacilities: [],
      filterOrigin: [],
      filterDestination: [],
      filterTransportClass: [],
      filterTags: [],
      filterTags2: [],
      filterBaseTags: [],
      filterLT: [],
      filterCO: [],
      showFoliosModule: false,
      
      // showFoliosModule1: false
    }, () => {
      // this.loadInitialData();
      // this.hideLastModules();
    });
  }
  filterContents = () => {
    if(this.props.companyType === 1)
      return [
        {
          title: "Fecha",
          // items: this.props.companyFacilities,
          name: "filterDateTemp",
          listType: FILTER_LIST_TYPES.dateFixed,
        },
        {
          title: "Tipo fecha",
          items: [
            {
              id: "1",
              description: "Carga"
            },
            {
              id: "2",
              description: "Descarga"
            },
            {
              id: "3",
              description: "Programa"
            },
            {
              id: "5",
              description: "Programa y cita de carga"
            },
            {
              id: "4",
              description: "Fin de viaje"
            },
          ],
          name: "filterDateType",
          listType: FILTER_LIST_TYPES.checkExclusive,
        },
        {
          title: "Instalación",
          items: this.props.LTFacilities,
          name: "filterFacilities",
          listType: FILTER_LIST_TYPES.checkListTextSearchAll,
        },
        {
          title: "Origen",
          items: this.props.LTFacilities,
          name: "filterOrigin",
          listType: FILTER_LIST_TYPES.checkListTextSearchAll,
        },
        {
          title: "Destino",
          items: this.props.LTFacilities,
          name: "filterDestination",
          listType: FILTER_LIST_TYPES.checkListTextSearchAll,
        },
        {
          title: "Base",
          items: this.props.baseTags.map(oT => ({id: oT.title, description: oT.title})),
          name: "filterBaseTags", 
          listType: FILTER_LIST_TYPES.checkListTextSearchAll,
        },
        {
          title: "Clase de expedición",
          items: this.props.transportClassesByTag,
          name: "filterTransportClass",
          listType: FILTER_LIST_TYPES.checkListTextSearchAll,
        },
        {
          title: "Clientes",
          items: this.props.networkCompanies,
          name: "filterCO",
          listType: FILTER_LIST_TYPES.checkListTextSearchAll,
        },
        // {
        //   title: "Suplemento 1",
        //   items: this.props.supplement1.concat([{id: 'na', description: 'Sin suplemento'}]),
        //   name: "filterSupplement1",
        //   listType: FILTER_LIST_TYPES.checkListTextSearchAll,
        // },
      ];
    return [
      {
        title: "Fecha",
        // items: this.props.companyFacilities,
        name: "filterDateTemp",
        listType: FILTER_LIST_TYPES.dateFixed,
      },
      {
        title: "Tipo fecha",
        items: [
          {
            id: "1",
            description: "Carga"
          },
          {
            id: "2",
            description: "Descarga"
          },
          {
            id: "3",
            description: "Programa"
          },
          {
            id: "5",
            description: "Programa y cita de carga"
          },
          {
            id: "4",
            description: "Fin de viaje"
          },
        ],
        name: "filterDateType",
        listType: FILTER_LIST_TYPES.checkExclusive,
      },
      {
        title: "Instalación",
        items: this.props.companyFacilities,
        name: "filterFacilities",
        listType: FILTER_LIST_TYPES.checkListTextSearchAll,
      },
      {
        title: "Origen",
        items: this.props.companyFacilities,
        name: "filterOrigin",
        listType: FILTER_LIST_TYPES.checkListTextSearchAll,
      },
      {
        title: "Destino",
        items: this.props.companyFacilities,
        name: "filterDestination",
        listType: FILTER_LIST_TYPES.checkListTextSearchAll,
      },
      {
        title: "Etiqueta",
        items: this.props.tripTags.map(oT => ({id: oT.title, description: oT.title})),
        name: "filterTags",
        listType: FILTER_LIST_TYPES.checkListTextSearchAll,
      },
      {
        title: "Etiqueta 1",
        items: this.props.orderTags.map(oT => ({id: oT.title, description: oT.title})),
        name: "filterTags2",
        listType: FILTER_LIST_TYPES.checkExclusive,
      },
      {
        title: "Clase de expedición",
        items: this.props.transportClassesByTag,
        name: "filterTransportClass",
        listType: FILTER_LIST_TYPES.checkListTextSearchAll,
      },
      {
        title: "Linea Transportista",
        items: this.props.networkCompanies,
        name: "filterLT",
        listType: FILTER_LIST_TYPES.checkListTextSearchAll,
      },
      {
        title: "Suplemento 1",
        items: this.props.supplement1.concat([{id: 'na', description: 'Sin suplemento'}]),
        name: "filterSupplement1",
        listType: FILTER_LIST_TYPES.checkListTextSearchAll,
      },
    ]
  }
  
  getSelectedItems = () => {
    if(this.props.companyType === 1){
      return {
        filterDateTemp: [this.state.filterDateTemp[0]],
        filterDateType: this.state.filterDateType,
        filterFacilities: this.state.filterFacilities,
        filterOrigin: this.state.filterOrigin,
        filterDestination: this.state.filterDestination,
        filterBaseTags: this.state.filterBaseTags,
        filterTransportClass: this.state.filterTransportClass,
        filterSupplement1: this.state.filterSupplement1,
        filterCO: this.state.filterCO,
      }
    } else {
      return {
        filterDateTemp: [this.state.filterDateTemp[0]],
        filterDateType: this.state.filterDateType,
        filterFacilities: this.state.filterFacilities,
        filterOrigin: this.state.filterOrigin,
        filterDestination: this.state.filterDestination,
        filterTags2: this.state.filterTags2,
        filterTags: this.state.filterTags,
        filterTransportClass: this.state.filterTransportClass,
        filterSupplement1: this.state.filterSupplement1,
        filterLT: this.state.filterLT,
      }
    }
  }

  //StatsMonth
  getMonthStatsTitle = () => {
    return ("".concat(months_abbr[this.state.filterDate[0].getMonth()], ' ', this.state.filterDate[0].getFullYear()))
  }

  //StatsDay
  getDayStatsTitle = () => {
    return ("".concat(this.state.filterDate[0].getDate(), " ", months_abbr[this.state.filterDate[0].getMonth()], ' ', this.state.filterDate[0].getFullYear()))
  }

  renderCanvas = () => {
    const canvas = document.getElementById('canvas-month');
    const ctx = canvas.getContext('2d');
    
    // const mtm = (this.props.tatGlobal?.mtm || [])[this.state.filterDate[0].getMonth()] || {};
    // const dtd = (this.props.tatGlobal?.dtd || []).find(d => d.id === "".concat(
    //   this.state.filterDate[0].getFullYear(), 
    //   '-', 
    //   this.digit2(this.state.filterDate[0].getMonth() + 1), 
    //   '-', 
    //   this.digit2(this.state.filterDate[0].getDate())
    // )) || {};
    const mtm = (this.props.dateData["mtm"] || {});
    const dtd = (this.props.dateData["dtd"] || {});

    let startAngle = 0-90;
    let acc = 0;
    // if(filtrosValues['etiqueta1'][0] === 'PT'){
      // let total = (mtm.avg_tat_real || 0); /* state.stats.total; */
      let total = 100;
      const stats = ['quality', 'utilization', 'adoption', 'allocation'];
    // } else {
    //   let total = (state.stats.estadiaPlanta + state.stats.estadiaAgencia + state.stats.transitoIda);
    //   let stats = ['estadiaPlanta', 'transitoIda', 'estadiaAgencia'];
    // }
    // this.UI.total.innerHTML = '<b>' + total.toFixed(1) + '</b>hr';


    const colors = ['#41707c', '#4193cd', '#9bbdd9', '#bfbdb4', '#000'];
    const deg_rad = Math.PI / 180;

    ctx.clearRect(0, 0, 260, 260);
    for (let iStat = 0; iStat < stats.length; iStat++){
      acc += iStat === 0 ? (mtm[stats[iStat]] || 0) : (mtm[stats[iStat]] || 0) - (mtm[stats[iStat - 1]] || 0);
      ctx.beginPath();
      let endAngle = Math.round( 360 * acc / total) - 90;
      ctx.arc(130, 130, 130, startAngle * deg_rad, endAngle * deg_rad, false); // outer (filled)
      // the tip of the "pen is now at 0,115
      ctx.arc(130, 130, 90, endAngle * deg_rad, startAngle * deg_rad, true); // outer (unfills it)
      ctx.fillStyle = colors[iStat];
      ctx.fill();
      ctx.closePath();
      startAngle = endAngle;
    }
    if(acc < total){
      acc = 100;
      ctx.beginPath();
      let endAngle = Math.round( 360 * acc / total) - 90;
      ctx.arc(130, 130, 130, startAngle * deg_rad, endAngle * deg_rad, false); // outer (filled)
      // the tip of the "pen is now at 0,115
      ctx.arc(130, 130, 129, endAngle * deg_rad, startAngle * deg_rad, true); // outer (unfills it)
      ctx.arc(130, 130, 91, startAngle * deg_rad, endAngle * deg_rad, false); // outer (filled)
      // the tip of the "pen is now at 0,115
      ctx.arc(130, 130, 90, endAngle * deg_rad, startAngle * deg_rad, true); // outer (unfills it)
      ctx.fillStyle = '#ffffff80';
      ctx.fill();
      ctx.closePath();
      startAngle = endAngle;
    }

    const canvas2 = document.getElementById('canvas-day');
    const ctx2 = canvas2.getContext('2d');
    startAngle = 0-90;
    acc = 0;
    // total = (dtd.avg_tat_real || 0);
    ctx2.clearRect(0, 0, 260, 260);
    // debugger;
    for (let iStat = 0; iStat < stats.length; iStat++){
      acc += iStat === 0 ? (dtd[stats[iStat]] || 0) : (dtd[stats[iStat]] || 0) - (dtd[stats[iStat - 1]] || 0);
      ctx2.beginPath();
      let endAngle = Math.round( 360 * acc / total) - 90;
      ctx2.arc(130, 130, 130, startAngle * deg_rad, endAngle * deg_rad, false); // outer (filled)
      // the tip of the "pen is now at 0,115
      ctx2.arc(130, 130, 90, endAngle * deg_rad, startAngle * deg_rad, true); // outer (unfills it)
      ctx2.fillStyle = colors[iStat];
      ctx2.fill();
      ctx2.closePath();
      startAngle = endAngle;
    }
    if(acc < total){
      acc = 100;
      ctx2.beginPath();
      let endAngle = Math.round( 360 * acc / total) - 90;
      ctx2.arc(130, 130, 130, startAngle * deg_rad, endAngle * deg_rad, false); // outer (filled)
      // the tip of the "pen is now at 0,115
      ctx2.arc(130, 130, 129, endAngle * deg_rad, startAngle * deg_rad, true); // outer (unfills it)
      ctx2.arc(130, 130, 91, startAngle * deg_rad, endAngle * deg_rad, false); // outer (filled)
      // the tip of the "pen is now at 0,115
      ctx2.arc(130, 130, 90, endAngle * deg_rad, startAngle * deg_rad, true); // outer (unfills it)
      ctx2.fillStyle = '#ffffff80';
      ctx2.fill();
      ctx2.closePath();
      startAngle = endAngle;
    }
  }

  //Indicadores por dia
  graphDaily = () => {
    let self = this;
    let data = JSON.parse(JSON.stringify(MonthChart));
    data.tooltip.formatter = function () {
      return this.points.reduce(function (s, point) {
        return s + '<br/>' + ('<span style="color:'+point.color+';">\u25CF </span>') + (point.series.name) + ': ' + (point.point.pct ? point.point.pct : (point.y)) +  (point.series.name === 'Órdenes' ? '' : `% (${(point.point.orders)} órdenes)`);
      }, '<b>' + this.x + ':</b>');
    };
    data.series[0].point = {
      events: {
        click: function () {
          self.hideLastModules();
          let date = new Date();
          date.setDate(this.category)
          date.setMonth(self.state.selectedMonth);
          date.setFullYear(self.state.filterDate[0].getFullYear());
          self.setState({
            graphStartDate: dateToString(date),
            graphEndDate: dateToString(date),
            motivesGraphStartDate: dateToString(new Date(date.getFullYear(), date.getMonth(), 1)),
            motivesGraphEndDate: dateToString(date),
            showParetoOffenders: true,
            showFoliosModule1: true,
            showGAPModule1: true,
            graphGroupBy: 'D'
          }, () => {self.loadOffenders(); self.loadGap1(); self.loadGap2();})
        }
      }
    };
    data.series[0].dataLabels[0].rotation = 270;
    data.series[0].dataLabels[0].y = -25;

    data.series[1].dataLabels[0].formatter = function(){
      return (this.y !== 0 ? parseInt(this.y) : '');
    };
    data.series[2].dataLabels[0].formatter = data.series[1].dataLabels[0].formatter;
    data.series[3].dataLabels[0].formatter = data.series[1].dataLabels[0].formatter;
    data.series[4].dataLabels[0].formatter = data.series[1].dataLabels[0].formatter;
    data.series[5].dataLabels[0].formatter = data.series[1].dataLabels[0].formatter;
    data.series[4].dataLabels[0].y = 40;
    data.series[4].dataLabels[0].align = "center bottom";

    let daysDataTemp = (this.props.globalData["dtd"] || [])

    let daysData = Array.isArray(daysDataTemp) ? daysDataTemp.filter(d => {
      let month = parseInt(d.description.split('-')[1]); 
      return month === this.state.selectedMonth + 1;
    }) : []
    daysData.forEach(d => {
      data.xAxis.categories.push(d.description.split('-')[2].slice(0, 2));
      if(!d.orders){
        data.series[5].data.push({y: null, orders: d.in_progress ?? 0})
      } else {
        data.series[5].data.push({y: Number(((100 * (d.in_progress || 0))/d.orders).toFixed(2)), orders: d.in_progress ?? 0})
      }
      CATEGORIES.forEach((category, i) => {
        if(!d.orders){
          data.series[i].data.push({y: null, orders: d[category.value] ?? 0})
        } else {
          if(category.value === "orders"){
            data.series[i].data.push({y: d[category.value] || null, orders: d[category.value] ?? 0})
          } else {
            data.series[i].data.push({y: Number(((100 * (d[category.value] || 0))/d.orders).toFixed(2)), orders: d[category.value] ?? 0})
          }
        }
      });
    })
    window.Highcharts.chart('dailyChart', data);
  }

  graphWeekly = () => {
    let self = this;
    let data = JSON.parse(JSON.stringify(MonthChart));
    data.tooltip.formatter = function () {
      return this.points.reduce(function (s, point) {
        return s + '<br/>' + ('<span style="color:'+point.color+';">\u25CF </span>') + (point.series.name) + ': ' + (point.point.pct ? point.point.pct : (point.y)) +  (point.series.name === 'Órdenes' ? '' : `% (${(point.point.orders)} órdenes)`); 
      }, '<b>' + this.x + ':</b>');
    };
    data.series[0].point = {
      events: {
        click: function () {
          self.hideLastModules();
          let weekNumber = this.category;
          self.setState({
            graphStartDate: 'Semana '.concat(weekNumber),
            graphEndDate: 'Semana '.concat(weekNumber),
            motivesGraphStartDate: 'Semana '.concat(1),
            motivesGraphEndDate: 'Semana '.concat(weekNumber),
            showParetoOffenders: true,
            showFoliosModule1: true,
            showGAPModule1: true,
            graphGroupBy: 'week'
          }, () => {self.loadOffenders(); self.loadGap1(); self.loadGap2();})
        }
      }
    };

    data.series[0].dataLabels[0].rotation = 270;
    data.series[0].dataLabels[0].y = -25;

    
    data.series[1].dataLabels[0].formatter = function(){
      return (this.y !== 0 ? parseInt(this.y) : '');
    };
    data.series[2].dataLabels[0].formatter = data.series[1].dataLabels[0].formatter;
    data.series[3].dataLabels[0].formatter = data.series[1].dataLabels[0].formatter;
    data.series[4].dataLabels[0].formatter = data.series[1].dataLabels[0].formatter;
    data.series[5].dataLabels[0].formatter = data.series[1].dataLabels[0].formatter;
    data.series[4].dataLabels[0].y = 40;
    data.series[4].dataLabels[0].align = "center bottom";
    

    (Array.isArray(this.props.globalData["wtw"]) ? this.props.globalData["wtw"] : []).forEach(d => {
      data.xAxis.categories.push(d.description);
      if(!d.orders){
        data.series[5].data.push({y: null, orders: d.in_progress ?? 0})
      } else {
        data.series[5].data.push({y: Number(((100 * (d.in_progress || 0))/d.orders).toFixed(2)), orders: d.in_progress ?? 0})
      }
      CATEGORIES.forEach((category, i) => {
        if(!d.orders){
          data.series[i].data.push({y: null, orders: d[category.value]})
        } else {
          if(category.value === "orders"){
            data.series[i].data.push({y: d[category.value] || null, orders: d[category.value]})
          } else {
            data.series[i].data.push({y: Number(((100 * (d[category.value] || 0))/d.orders).toFixed(2)), orders: d[category.value]})
          }
        } 
      });
    })
    window.Highcharts.chart('weeklyChart', data);
  }

  graphMonthly = () => {
    let self = this;
    let data = JSON.parse(JSON.stringify(MonthChart));
    data.tooltip.formatter = function () {
      return this.points.reduce(function (s, point) {
        return s + '<br/>' + ('<span style="color:'+point.color+';">\u25CF </span>') + (point.series.name) + ': ' + (point.point.pct ? point.point.pct : (point.y)) +  (point.series.name === 'Órdenes' ? '' : `% (${(point.point.orders)} órdenes)`); 
      }, '<b>' + this.x + ':</b>');
    };
    data.series[0].point = {
      events: {
        click: function () {
          self.hideLastModules();
          let startDate = 0;
          let motivesStartDate = 0;
          let endDate = 0;
          let graphGroupBy = 'M';
          // debugger;
          if(Object.keys(months_index).includes(this.category)){
            startDate = new Date(self.state.filterDate[0].getFullYear(), months_index[this.category], 1);
            motivesStartDate = new Date(self.state.filterDate[0].getFullYear(), 0, 1);
            if(startDate.getMonth() === self.state.filterDate[0].getMonth()){
              endDate = self.state.filterDate[0];
            }else{
              endDate = new Date(self.state.filterDate[0].getFullYear(), months_index[this.category]+1, 0);
            }
          } else{
            graphGroupBy = 'Y';
            startDate = new Date(this.category, 0, 1);
            motivesStartDate = new Date(this.category, 0, 1);
            endDate = new Date(this.category, 11, 31);
          }
          self.setState({
            graphStartDate: dateToString(startDate),
            graphEndDate: dateToString(endDate),
            motivesGraphStartDate: dateToString(motivesStartDate),
            motivesGraphEndDate: dateToString(endDate),
            showParetoOffenders: true,
            showFoliosModule1: true,
            showGAPModule1: true,
            graphGroupBy,
          }, () => {
            self.loadOffenders(); 
            self.loadGap1(); 
            self.loadGap2();
          })
        }
      }
    };

    data.series[4].dataLabels[0].y = 40;
    data.series[4].dataLabels[0].align = "center bottom";

    let yearData = cloneDeep(data);
    yearData.legend = {
      enabled: false
    }

    console.log("DATA", data);
    (this.props.globalData["year"] || []).forEach((d) => {
      let date = new Date(d.id);
      yearData.xAxis.categories.push(date.getFullYear());
      if(!d.orders){
        yearData.series[5].data.push({y: null, orders: d.in_progress ?? 0})
      } else {
        yearData.series[5].data.push({y: Number(((100 * (d.in_progress || 0))/d.orders).toFixed(2)), orders: d.in_progress ?? 0})
      }
      CATEGORIES.forEach((category, i) => {
        if(!d.orders){
          yearData.series[i].data.push({y: null, orders: d[category.value]})
        } else {
          if(category.value === "orders"){
            yearData.series[i].data.push({y: d[category.value] || null, orders: d[category.value]})
          } else {
            yearData.series[i].data.push({y: Number(((100 * (d[category.value] || 0))/d.orders).toFixed(2)), orders: d[category.value]})
          }
        }
      });
    })
    window.Highcharts.chart('yearlyChart', yearData);
    
    (this.props.globalData["mtm"] || []).forEach((d, i) => {
      data.xAxis.categories.push(months_abbr[i]);
      if(!d.orders){
        data.series[5].data.push({y: null, orders: d.in_progress ?? 0})
      } else {
        data.series[5].data.push({y: Number(((100 * (d.in_progress || 0))/d.orders).toFixed(2)), orders: d.in_progress ?? 0})
      }
      CATEGORIES.forEach((category, ii) => {
        if(!d.orders){
          data.series[ii].data.push({y: null, orders: d[category.value]})
        } else {
          if(category.value === "orders"){
            data.series[ii].data.push({y: d[category.value] || null, orders: d[category.value]})
          } else {
            data.series[ii].data.push({y: Number(((100 * (d[category.value] || 0))/d.orders).toFixed(2)), orders: d[category.value]})
          }
        }
      });
    })
    
    window.Highcharts.chart('monthlyChart', data); 
  }

  //ParetoOffenders
  getParetoDate = () => {
    if(this.state.graphStartDate === "" || this.state.graphEndDate === "")
      return ""
    if(this.state.graphStartDate.includes('Semana') || this.state.graphEndDate.includes('Semana'))
      return this.state.graphStartDate;
    let date1 = this.state.graphStartDate.split('-')[2].concat(' de ', months_full[parseInt(this.state.graphStartDate.split('-')[1])-1]);
    let date2 = this.state.graphEndDate.split('-')[2].concat(' de ', months_full[parseInt(this.state.graphEndDate.split('-')[1])-1]);
    if(date1 === date2)
      return date1;
    return date1.concat(' - ', date2);
  }
  graphPareto = () => {
    let self = this;
    let data = JSON.parse(JSON.stringify(OffenderChart));

    data.tooltip.formatter = function () {
      return this.points.reduce(function (s, point) {
        // eslint-disable-next-line
        let value = point.point.pct ? point.point.pct : point.y;

        return s + '<br/>' + '<span style="color:'+point.color+';">\u25CF </span>' + point.series.name + ': ' + (point.series.name.includes('Acum') ? (point.y.toFixed(2) + '%') : value) + (point.series.name.includes('Órdenes') ? (' (' + ((value*100)/(point.point.pct1 || 1)).toFixed(1) + '% de ' + point.point.pct1 + ')') : '');
      }, '<b>' + this.x + '</b>');
    }
    data.series[1].dataLabels[0].formatter = function(){
      return this.point.pct;
    }

    data.series[0].point = {
      events: {
        click: function () {

          let fleetProviderToRequest = 0;
          // let platesToRequest = '';
          let operatorsToRequest = '';
          let economicToRequest = '';

          if(self.props.companyType === 1){
            if(self.state.selectedParetoGrouping === 'economic_number'){
              economicToRequest = this.category;
            } else {
              operatorsToRequest = this.category;
            }
            // platesToRequest = this.category;
          } else {
            for (let i = 0; i < (offendersData ?? []).length; i++) {
              if (offendersData[i].description === this.category) {
                fleetProviderToRequest = offendersData[i].id;
              }
            }
          }
          self.setState( {fleetProviderToRequest, /* platesToRequest, */ economicToRequest, operatorsToRequest, showGAPModule: false, showFoliosModule: true}, self.loadGap);
        }
      }
    }
    // debugger;
    let offendersData = this.props.offendersData || [];
    switch(self.state.selectedParetoIndicator){
      case "allocation":
        offendersData.sort((a, b) => ((b.orders - b.allocation) - (a.orders - a.allocation)))
        break;
      case "adoption":
        offendersData.sort((a, b) => ((b.allocation - b.adoption) - (a.allocation - a.adoption)))
        break;
      case "utilization":
        offendersData.sort((a, b) => ((b.adoption - b.utilization) - (a.adoption - a.utilization)))
        break;
      case "quality":
        offendersData.sort((a, b) => ((b.utilization - b.quality) - (a.utilization - a.quality)))
        break;
      default:
    }

    let zoomData = [];
    let zoomGroup = JSON.parse(JSON.stringify(data));
    zoomGroup.series[0].point = {
      events: {
        click: function () {

          let fleetProviderToRequest = 0;
          // let platesToRequest = '';
          let operatorsToRequest = '';
          let economicToRequest = '';

          if(self.props.companyType === 1){
            if(self.state.selectedParetoGrouping === 'economic_number'){
              economicToRequest = this.category;
            } else {
              operatorsToRequest = this.category;
            }
          } else {
            for (let i = 0; i < (offendersData ?? []).length; i++) {
              if (offendersData[i].description === this.category) {
                fleetProviderToRequest = offendersData[i].id;
              }
            }
          }

          self.setState( {fleetProviderToRequest, /* platesToRequest, */ economicToRequest, operatorsToRequest, showGAPModule: false, showFoliosModule: true}, self.loadGap);
        }
      }
    }
    zoomGroup.tooltip.formatter = function () {
      return this.points.reduce(function (s, point) {
        // eslint-disable-next-line
        let value = point.point.pct ? point.point.pct : point.y;

        return s + '<br/>' + '<span style="color:'+point.color+';">\u25CF </span>' + point.series.name + ': ' + (point.series.name.includes('Acum') ? (point.y.toFixed(2) + '%') : value) + (point.series.name.includes('Órdenes') ? (' (' + ((value*100)/(point.point.pct1 || 1)).toFixed(1) + '% de ' + point.point.pct1 + ')') : '');
      }, '<b>' + this.x + '</b>');
    }
    zoomGroup.series[1].dataLabels[0].formatter = function(){
      return this.point.pct;
    }

    zoomGroup.series[3].baseSeries = null;
    zoomGroup.series[3].data = [];

    
    let zoomIndex = 1;
    // let aux = 0;

    let total = 0;
    
    let pctacum = 0;

    offendersData.forEach(d => {
      total += self.getGapResult(d)
    });

    if(total === 0){
      total = 1;
    }

    offendersData.forEach(d => {
      let pct = (100*(self.getGapResult(d)))/total;
      pctacum += pct;

      data.xAxis.categories.push(d.description);
      data.series[0].data.push({y: self.getGapResult(d), name: d.id, pct1: d.orders});
      let trips_pct = (100 * (self.getGapResult(d)))/d.orders;
      if(trips_pct % 1){
        trips_pct = trips_pct.toFixed(1);
      }
      data.series[1].data.push({y: 100, pct: ''.concat(pct.toFixed(1), '%')});
      data.series[2].data.push(self.getGapResult(d));

      zoomGroup.xAxis.categories.push(d.description);
      zoomGroup.series[0].data.push({y: self.getGapResult(d), name: d.id, pct1: d.orders});
      zoomGroup.series[1].data.push({y: 100, pct: ''.concat(pct.toFixed(1), '%')});
      zoomGroup.series[3].data.push(pctacum.toFixed(2)/1);
      zoomIndex++;
      if(zoomIndex===11){
        zoomIndex = 1;
        zoomData.push(cloneDeep(zoomGroup));
      }
    });

    // total = 0;
    // offendersData.forEach(d => {
      
    // });
    zoomData.push(cloneDeep(zoomGroup));
    let selectedParetoPage = 1;

    selectedParetoPage = zoomData[self.state.selectedParetoPage - 1] ? 
      self.state.selectedParetoPage
      : zoomData[1] ? 2 : 1;
    
    self.setState({selectedParetoPage, zoomDataLength: zoomData.length})
    console.log("zoomDATA", zoomData);

    window.Highcharts.chart('paretoIndicatorChart', (self.state.selectedParetoZoomMode === 'all' ? data : zoomData[selectedParetoPage-1]));
  }
  getGapResult = (data) => {
    switch(this.state.selectedParetoIndicator){
      case "allocation":
        return (data.orders - data.allocation)
      case "adoption":
        return data.allocation - data.adoption
      case "utilization":
        return data.adoption - data.utilization
      case "quality":
        return data.utilization - data.quality
      default:
    }
  }

  //GAP
  getGAPTitle = () => {
    switch(this.state.selectedParetoIndicator){
      case "allocation":
        return "GAP Asignación"
      case "adoption":
        return "GAP Adopción"
      case "utilization":
        return "GAP Utilización"
      case "quality":
        return "GAP Calidad"
      default:
        return ""
    }
  }

  getTitle = () => {
    switch(this.state.selectedParetoIndicator){
      case "allocation":
        return "Asignación"
      case "adoption":
        return "Adopción"
      case "utilization":
        return "Utilización"
      case "quality":
        return "Calidad"
      default:
        return ""
    }
  }

  getGAPTitle1 = () => {
    switch(this.state.selectedParetoIndicator){
      case "allocation":
        if(this.state.selectedGAPMode1 === "motives") return "GAP Asignación";
        return "GAP Asignación".concat(' (', ((this.props.foliosData2 || [])[this.state.selectedGAPMode1 === "table" ? "gap" : "in_process"] || []).length, ' folios)');
      case "adoption":
        if(this.state.selectedGAPMode1 === "motives") return "GAP Adopción";
        return "GAP Adopción".concat(' (', ((this.props.foliosData2 || [])[this.state.selectedGAPMode1 === "table" ? "gap" : "in_process"] || []).length, ' folios)');
      case "utilization":
        if(this.state.selectedGAPMode1 === "motives") return "GAP Utilización";
        return "GAP Utilización".concat(' (', ((this.props.foliosData2 || [])[this.state.selectedGAPMode1 === "table" ? "gap" : "in_process"] || []).length, ' folios)');
      case "quality":
        if(this.state.selectedGAPMode1 === "motives") return "GAP Calidad";
        return "GAP Calidad".concat(' (', ((this.props.foliosData2 || [])[this.state.selectedGAPMode1 === "table" ? "gap" : "in_process"] || []).length, ' folios)');
      default:
        return ""
    }
  }

  getTitle1 = () => {
    switch(this.state.selectedParetoIndicator1){
      case "allocation":
        return "Asignación".concat(' (', (this.props.foliosData1?.positives || []).length, ' folios)')
      case "adoption":
        return "Adopción".concat(' (', (this.props.foliosData1?.positives || []).length, ' folios)')
      case "utilization":
        return "Utilización".concat(' (', (this.props.foliosData1?.positives || []).length, ' folios)')
      case "quality":
        return "Calidad".concat(' (', (this.props.foliosData1?.positives || []).length, ' folios)')
      case "in_progress":
        return "En proceso".concat(' (', (this.props.foliosData1?.in_process || []).length, ' folios)')
      default:
        return ""
    }
  }

  getViewToRequest1 = () => {
    switch(this.state.selectedParetoIndicator1){
      case "allocation":
        return "allocation_detail"
      case "adoption":
        return "adoption_detail"
      case "utilization":
        return "utilization_detail"
      case "quality":
        return "quality_detail"
      case "in_progress":
        return "in_progress_detail"
      default:
        return ""
    }
  }

  getViewToRequest2 = () => {
    switch(this.state.selectedParetoIndicator){
      case "allocation":
        return "allocation_detail"
      case "adoption":
        return "adoption_detail"
      case "utilization":
        return "utilization_detail"
      case "quality":
        return "quality_detail"
      default:
        return ""
    }
  }

  getViewToRequest = () => {
    switch(this.state.selectedParetoIndicator){
      case "allocation":
        return "allocation_detail"
      case "adoption":
        return "adoption_detail"
      case "utilization":
        return "utilization_detail"
      case "quality":
        return "quality_detail"
      default:
        return ""
    }
  }

  graphGAP = () => {
    if(this.state.selectedGAPMode === "table" || this.state.selectedGAPMode === "process"){
      document.getElementById("GAPChart").innerHTML = "";
    } else {
      let data = JSON.parse(JSON.stringify(MotivesChart));
      data.chart.type = 'column';
      data.plotOptions = {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true,
            format: '{point.percentage:.1f}%'
          }
        }
      }
      data.xAxis ={
        tickLength: 0,
        categories: [],
        labels: {
          style: {
            color: 'white',
            fontSize: '15px'
          }
        }
        // crosshair: true,
        // lineColor: '#303349',
      };
      data.yAxis = {
        title: {
            text: ''
        },
        labels: {
          enabled: false
        },
        lineColor: '#303349',
        gridLineColor: '#303349'
      }

      let graphData = Object.entries(this.props.graphData);
      let series = {};

      graphData.forEach((dateData, i) => {
        let category = dateData[0].split(' ')[0].split('-');
        switch(this.state.graphGroupBy){
          case 'D':
            category = category[2]
            break;
          case 'M':
            category = months_abbr[Number(category[1]) - 1];
            break;
          case 'Y':
            category = category[0]
            break;
          case 'week':
            category = dateData[0];
            break;
          default:
            category = '-'
        }
        // let dataKey = '';
        // switch(this.state.selectedParetoIndicator1){
        //   case "adoption":
        //     dataKey = "adoption"
        //     break;
        //   case "utilization":
        //     dataKey = "utilization"
        //     break;
        //   case "allocation":
        //     dataKey = "allocation"
        //     break;
        //   case "quality":
        //     dataKey = "quality"
        //     break;
        //   default:
        //     dataKey = "allocation";
        // }

        data.xAxis.categories.push(category);
        dateData[1].forEach(motive => {
          if(series[motive.id]){
            series[motive.id][i] = motive.orders;
          } else {
            series[motive.id] = [];
            series[motive.id][i] = motive.orders;
          }
        })
      })

      data.series = Object.entries(series).map(serie => {
        let serieData = [];
        for(let i = 0; i < serie[1].length; i++){
          serieData[i] = serie[1][i] || 0;
        }
        return ({
          name: serie[0],
          data: serieData
        })
      });
      window.Highcharts.chart('GAPChart', data);

      // let motives = (this.props.graphData ?? []).map(data => ({
      //   description: data.description ?? '',
      //   pct: data.allocation ?? 0,
      // })).sort((a, b) => a.pct - b.pct);

      // if (motives.length === 0) {

      //   document.getElementById("GAPChart").innerHTML = "";

      // } else {

      //   // data.series[0].data = motives.map(m => ({
      //   //   name: m.description,
      //   //   y: m.pct
      //   // }));
      //   data.series = motives.map(m => ({
      //       name: m.description,
      //       data: [m.pct]
      //     }));
          
      //   window.Highcharts.chart('GAPChart', data);

      // }

    }
  }
  graphGAP1 = () => {
    if(this.state.selectedGAPMode1 === "table" || this.state.selectedGAPMode1 === "process"){
      document.getElementById("GAPChart1").innerHTML = "";
    } else {
      let data = JSON.parse(JSON.stringify(MotivesChart));

      data.chart.type = 'column';
      data.plotOptions = {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true,
            format: '{point.percentage:.1f}%'
          }
        }
      }
      data.xAxis ={
        tickLength: 0,
        labels: {
          style: {
            color: 'white',
            fontSize: '15px'
          }
        },
        categories: []
        // crosshair: true,
        // lineColor: '#303349',
      };
      data.yAxis = {
        title: {
            text: ''
        },
        labels: {
          enabled: false
        },
        lineColor: '#303349',
        gridLineColor: '#303349'
      }

      let graphData = Object.entries(this.props.graphData1);
      let series = {};

      graphData.forEach((dateData, i) => {
        let category = dateData[0].split(' ')[0].split('-');
        switch(this.state.graphGroupBy){
          case 'D':
            category = category[2]
            break;
          case 'M':
            category = months_abbr[Number(category[1]) - 1];
            break;
          case 'Y':
            category = category[0]
            break;
          case 'week':
            category = dateData[0];
            break;
          default:
            category = '-'
        }
        // debugger;
        let total = (dateData[1]).reduce((acum, current) => {return acum + (current.orders)}, 0);
        if(total === 0) total = 1;
        data.xAxis.categories.push(category);
        dateData[1].forEach(motive => {
          // debugger;
          if(series[motive.id]){
            series[motive.id][i] = (motive.orders * 10)/total;
          } else {
            series[motive.id] = [];
            series[motive.id][i] = (motive.orders * 10)/total;
          }
        })
      })

      data.series = Object.entries(series).map(serie => {
        let serieData = [];
        for(let i = 0; i < serie[1].length; i++){
          serieData[i] = serie[1][i] || 0;
        }
        return ({
          name: serie[0],
          data: serieData
        })
      });
      // debugger;
      //console.log("DATA MOTIVOS", data);
      window.Highcharts.chart('GAPChart1', data);

    }
  }
  graphTable = (dataToPresent) => {

    let table = (
      <div className="pareto-table">
        <div className="table-header" style={{marginRight: (null || []).length >= 9 ? "16px" : ""}}>
          <div className="large-pct">Folio</div>
          <div className="large-pct">Origen</div>
          <div className="large-pct">Fecha carga origen</div>
          <div className="large-pct">Destino</div>
          <div className="large-pct">Fecha desc. destino</div>
          <div className="large-pct">Operador</div>
          <div className="large-pct">Vehículo</div>
          <div className="large-pct">Remolques</div>
        </div>
        <div className="list">
          {
            dataToPresent.map(v => (
              <div className="trip">
                <div class="large-pct">{v.folio || '-'}</div>
                <div class="large-pct">{v.origen || '-'}</div>
                <div class="large-pct">{v.fecha_carga ? `${moment(v.fecha_carga).format('YYYY-MM-DD HH:mm')}` : '-'}</div>
                {/* <div class="large-pct">{v.fecha_carga || '-'}</div> */}
                <div class="large-pct">{v.destino || '-'}</div>
                <div class="large-pct">{v.fecha_descarga ? `${moment(v.fecha_descarga).format('YYYY-MM-DD HH:mm')}` : '-'}</div>
                {/* <div class="large-pct">{v.fecha_descarga || '-'}</div>  */}
                <div class="large-pct">{v.operadores || '-'}</div>
                <div class="large-pct">{v.vehiculo || '-'}</div>
                <div class="large-pct">{v.remolques || '-'}</div>
              </div>
            ))
          }
        </div>
      </div>
    )

    return (dataToPresent || []).length != 0 ? table : 
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: 50, width: '100%', fontWeight: 'bold', fontSize: 20, borderRadius: 7, zIndex: 1, color: 'white'}}>
      { 'No hay datos que mostrar' }
    </div>;
  }

  formatPCT = (value, decimals = 2) => {
    if(!value || !(value % 1)){
      return value
    } else {
      return Number(value.toFixed(decimals))
    }
  }

  presentLoader = () => {
    return(
      <div style={{height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#2F3B52', borderRadius: 7, marginTop: 20, color: 'white'}}>
        {'Cargando'}
        <div style={{width: 8}}/>
        <Loader />
      </div>
    );
  }

  presentBlurView = () => {
    return(
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%', backgroundColor: 'white', opacity: 0.7, position: 'absolute', top: 0, left: 0, fontWeight: 'bold', fontSize: 20, backdropFilter: 'blur(3px)', borderRadius: 7, zIndex: 1}}></div>
    );
  }

  getToken = () => {
    return {
      Authorization:
        "Bearer " +
        (JSON.parse(sessionStorage.getItem("orbinetwork_session")) || {})
          .access,
    };
  };

  openDocument = (module) => {
    let downloadUrl = this.getUrl(module);
    this.setState({isLoading: true});
    Axios({
      url: downloadUrl,
      method: "GET",
      responseType: "blob",
      headers: this.getToken(),
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "utilizacion.xlsx");
      document.body.appendChild(link);
      link.click();
      this.setState({isLoading: false});
    }).catch((response) => {
      let error = (response.errors && response.errors.length > 0) ? response.errors.join() : response.error || 'Ocurrió un error, intente de nuevo';

      this.setState({
        isLoading: false,
        msgError: error
      });
    });
  }

  getFoliosSection1 = () => {

    if (!this.state.showFoliosModule1) { return(<div/>); }

    return(
      <div>
        { this.state.isLoadingFolios1 && this.presentLoader() }

        <div id="paretoGapFolios1" className="dashboard-panel dashboard-TAT-Month " style={{display: "" }}>
          <div className="panel-title etapas">
            <b >{ this.getTitle1() }</b>
            <span >
              {this.getParetoDate()}
            </span>
          </div>
          <ul className="filtros">
            <div className="desviacion desv">
              <li className={"desviacion".concat(this.state.selectedParetoIndicator1 === "allocation" ? " selected" : "", " estadiaPlanta")} onClick={() => this.setState({selectedParetoIndicator1: "allocation"}, this.loadGap1)}>
                Asignación<div></div>
              </li>
              <li className={"desviacion".concat(this.state.selectedParetoIndicator1 === "adoption" ? " selected" : "", " estadiaPlanta")} onClick={() => this.setState({selectedParetoIndicator1: "adoption"}, this.loadGap1)} style={{borderRadius: "0px"}}>
                Adopción<div></div>
              </li>
              <li className={"desviacion".concat(this.state.selectedParetoIndicator1 === "in_progress" ? " selected" : "", " estadiaPlanta")} onClick={() => this.setState({selectedParetoIndicator1: "in_progress"}, this.loadGap1)} style={{borderRadius: "0px"}}>
                Proceso<div></div>
              </li>
              <li className={"desviacion".concat(this.state.selectedParetoIndicator1 === "utilization" ? " selected" : "", " estadiaPlanta")} onClick={() => this.setState({selectedParetoIndicator1: "utilization"}, this.loadGap1)} style={{borderRadius: "0px"}}>
                Utilización<div></div>
              </li>
              <li className={"desviacion".concat(this.state.selectedParetoIndicator1 === "quality" ? " selected" : "", " estadiaPlanta")} onClick={() => this.setState({selectedParetoIndicator1: "quality"}, this.loadGap1)} style={{borderRadius: "0px 15px 15px 0px"}}>
                Calidad<div></div>
              </li>
            </div>
            {/*<div className="desviacion desv">
              <div
                style={{backgroundColor: '#d4d2d2', color: 'black', borderRadius: 15, paddingLeft: 10, paddingRight: 10, cursor: "pointer"}}
                onClick={() => {
                  this.setState({showGAPModule1: true, selectedGAPMode1: (this.state.selectedParetoIndicator1 === "utilization" || this.state.selectedParetoIndicator1 === "adoption" ? "motives" : "table")});

                  

                  setTimeout(() => {
                    Scroll.go(document.getElementById('paretoGapPanel1'), document.getElementById('pageContent'));
                  }, 200);
                }}
              >
                {this.getGAPTitle1()}
              </div>
            </div>*/}
          </ul>
          <div var="content" className="panel-content rutas">
            <div className="charts">
              <div className="paretoRuta" >
                { this.graphTable( this.state.isLoadingFolios1 ? [] : (this.props.foliosData1[this.state.selectedParetoIndicator1 === "in_progress" ? "in_process" : "positives"] || [] )) }
              </div>
            </div>
          </div>
          <div style={{height: "35px"}}>
            
          {
            this.state.isLoading
            ? <Loader className="download-icon"/>
            : <DownloadImage
                fill={"#FFFFFF"}
                width={"50px"}
                height={"50px"}
                className="download-icon"
                onClick={(e) => {e.stopPropagation(); this.openDocument("folios1")}}
              /> 
          }
            
          </div>
          { this.state.isLoadingFolios1 && this.presentBlurView() }
        </div>
      </div>
    );

  }
  getGapSection1 = () => {

    if (!this.state.showGAPModule1) { return(<div/>); }

    return(
      <div>
        { (this.state.isLoadingGraph1 || this.state.isLoadingGraph2 || this.state.isLoadingOffenders) && this.presentLoader() }

        <div id="paretoGapPanel1" className="dashboard-panel dashboard-TAT-Month " style={{display: ""}}>
          <div className="panel-title etapas">
            <b >{this.getGAPTitle1()}</b>
            <span >
              {this.getParetoDate()}
            </span>
          </div>
          <ul className="filtros">
            <div className="desviacion desv">
              <li className={"desviacion".concat(this.state.selectedParetoIndicator === "allocation" ? " selected" : "", " estadiaPlanta")} onClick={() => this.setState({selectedParetoIndicator: "allocation", selectedGAPMode1: 'table', showGAPModule: false, showFoliosModule: false}, () =>  {this.graphPareto(); this.loadGap2()})}>
                Asignación<div></div>
              </li>
              <li className={"desviacion".concat(this.state.selectedParetoIndicator === "adoption" ? " selected" : "", " estadiaPlanta")} onClick={() => this.setState({selectedParetoIndicator: "adoption", selectedGAPMode1: 'table', showGAPModule: false, showFoliosModule: false}, () =>  {this.graphPareto(); this.loadGap2()})} style={{borderRadius: "0px"}}>
                Adopción<div></div>
              </li>
              <li className={"desviacion".concat(this.state.selectedParetoIndicator === "utilization" ? " selected" : "", " estadiaPlanta")} onClick={() => this.setState({selectedParetoIndicator: "utilization", selectedGAPMode1: 'table', showGAPModule: false, showFoliosModule: false}, () =>  {this.graphPareto(); this.loadGap2()})} style={{borderRadius: "0px"}}>
                Utilización<div></div>
              </li>
              <li className={"desviacion".concat(this.state.selectedParetoIndicator === "quality" ? " selected" : "", " estadiaPlanta")} onClick={() => this.setState({selectedParetoIndicator: "quality", selectedGAPMode1: 'table', showGAPModule: false, showFoliosModule: false}, () =>  {this.graphPareto(); this.loadGap2()})} style={{borderRadius: "0px 15px 15px 0px"}}>
                Calidad<div></div>
              </li>
            </div>
            { this.getGapFilters1() }
          </ul>
          <div var="content" className="panel-content rutas">
            <div className="charts" style={{minHeight: "auto"}}>
              <div className="paretoRuta" >
                <div id="GAPChart1"></div>
                {
                  (this.state.selectedGAPMode1 !== 'motives' && !this.state.isLoadingGraph2) && this.graphTable(this.state.selectedGAPMode1 === 'table' ? (this.props.foliosData2.gap || []): (this.props.foliosData2.in_process || []))
                }
                {
                  (this.state.selectedGAPMode1 === 'motives' && !this.state.isLoadingGraph2 && (this.props.graphData1 ?? []).length === 0) && this.graphTable([])
                }
              </div>
            </div>
          </div>
          <div style={{height: "35px", position: "relative"}}>
            
            {
              this.state.isLoading
              ? <Loader className="download-icon"/>
              : <DownloadImage
                  fill={"#FFFFFF"}
                  width={"50px"}
                  height={"50px"}
                  className="download-icon"
                  style={{bottom: 0}}
                  onClick={(e) => {e.stopPropagation(); this.openDocument("folios1")}}
                /> 
            }
            
          </div>
          <div className="panel-title etapas">
            <b >Top Offenders</b>
            <span >
              {this.getParetoDate()}
            </span>
          </div>
          <ul className="filtros">
            <div className="desviacion zoom">
              <li className={"desviacion".concat(this.state.selectedParetoZoomMode === "all" ? " selected" : "", " estadiaPlanta")} onClick={() => this.setState({selectedParetoZoomMode: "all", showGAPModule: false, showFoliosModule: false}, this.graphPareto)}>
                Todos<div></div>
              </li>
              <li className={"desviacion".concat(this.state.selectedParetoZoomMode === "zoom" ? " selected" : "", " estadiaPlanta")} onClick={() => this.setState({selectedParetoZoomMode: "zoom", showGAPModule: false, showFoliosModule: false}, this.graphPareto)} style={{borderRadius: "0px 15px 15px 0px"}}>
                Acotado<div></div>
              </li>
              <div className={"navigate estadiaPlanta"} var="nav" style={{display: this.state.selectedParetoZoomMode === "zoom" ? "" : "none"}}>
                <span var="prev" className="prev" onClick={() => {if(this.state.selectedParetoPage > 1)this.setState({selectedParetoPage: this.state.selectedParetoPage -1}, this.graphPareto)}}>
                  &#8211;
                </span>
                <span var="selectedParetoPage" className="pag">{this.state.selectedParetoPage*10}</span> 
                <span var="next" className="prev" onClick={() => {if(this.state.selectedParetoPage < this.state.zoomDataLength)this.setState({selectedParetoPage: this.state.selectedParetoPage +1}, this.graphPareto)}}>
                  +
                </span>
              </div>
            </div>
            {
              this.props.companyType === 1 &&
              <div className="desviacion desv">
                <li className={"desviacion".concat(this.state.selectedParetoGrouping === "economic_number" ? " selected" : "", " estadiaPlanta")} onClick={() => this.setState({selectedParetoGrouping: "economic_number", showGAPModule: false, showFoliosModule: false}, this.loadOffenders)}>
                  Vehiculos<div></div>
                </li>
                <li className={"desviacion".concat(this.state.selectedParetoGrouping === "operators" ? " selected" : "", " estadiaPlanta")} onClick={() => this.setState({selectedParetoGrouping: "operators", showGAPModule: false, showFoliosModule: false}, this.loadOffenders)} style={{borderRadius: "0px 15px 15px 0px"}}>
                  Operadores<div></div>
                </li>
              </div>
            }
          </ul>
          <div var="content" className="panel-content rutas">
            <div className="charts">
              <div className="paretoRuta" >
                {/* <b className="loader" style={{display: "none"}}><Loader /></b> */}
                <div id="paretoIndicatorChart"></div>
              </div>
            </div>
          </div>
          {
            this.state.isLoading
            ? <Loader className="download-icon"/>
            : <DownloadImage
                fill={"#FFFFFF"}
                width={"50px"}
                height={"50px"}
                className="download-icon"
                onClick={(e) => {e.stopPropagation(); this.openDocument("offenders")}}
              /> 
          }
          { (this.state.isLoadingGraph1 || this.state.isLoadingGraph2 || this.state.isLoadingOffenders) && this.presentBlurView() }
        </div>
      </div>
    );

  }

  getTopOffenders = () => {

    // if (!this.state.showParetoOffenders) { return(<div/>); }

    // return(
    //   <div>
    //     { this.state.isLoadingOffenders && this.presentLoader() }

    //     <div id="paretoRutaPanel" className="dashboard-panel dashboard-TAT-Month " style={{display: ""}}>
          
          
          

    //       { this.state.isLoadingOffenders && this.presentBlurView() }
    //     </div>
    //   </div>
    // );

  }

  getFoliosSection = () => {

    if (!this.state.showFoliosModule) { return(<div/>); }

    return(
      <div>
        { this.state.isLoadingFolios && this.presentLoader() }

        <div id="paretoGapFolios" className="dashboard-panel dashboard-TAT-Month " style={{display: "" }}>
          <div className="panel-title etapas">
            <b >{ this.getTitle() }</b>
            <span >
              {this.getParetoDate()}
            </span>
          </div>
          <ul className="filtros">
            <div className="desviacion desv">
              <div
                style={{backgroundColor: '#d4d2d2', color: 'black', borderRadius: 15, paddingLeft: 10, paddingRight: 10, cursor: "pointer"}}
                onClick={() => {
                  this.setState({showGAPModule: true, selectedGAPMode: "table"});
                  let weeks = [];
                  if(this.state.motivesGraphStartDate.includes("Semana")){
                    for(let w = 1; w <= Number(this.state.motivesGraphEndDate.split(' ')[1]); w++){
                      weeks.push(w);
                    }
                  }

                  if (this.state.selectedParetoIndicator === 'adoption') {
                    this.setState({isLoadingGraph: true})
                    this.props.loadUtilizationGraph({
                      company_id: this.props.companyId,
                      start_date: this.state.motivesGraphStartDate.includes("Semana") ? dateToString(this.state.filterDate[0]) : this.state.motivesGraphStartDate,
                      end_date: this.state.motivesGraphEndDate.includes("Semana") ? dateToString(this.state.filterDate[0]) : this.state.motivesGraphEndDate,
                      facilities: this.state.filterFacilities,
                      origin: this.state.filterOrigin,
                      destination: this.state.filterDestination,
                      base_tags: this.state.filterBaseTags,
                      trip_tags: this.state.filterTags,
                      order_tags: this.state.filterTags2,
                      transport_class: this.state.filterTransportClass,
                      fleet_provider: this.state.fleetProviderToRequest,
                      supplement: this.state.filterSupplement1,
                      appointment_type: (this.state.filterDateType || []).join(",") || "",
                      // week: this.state.motivesGraphStartDate.includes("Semana") ? Number(this.state.motivesGraphStartDate.split(' ')[1]) : '',
                      week: weeks,
                      plates: this.state.platesToRequest,
                      economic_number: this.state.economicToRequest,
                      operators: this.state.operatorsToRequest,
                      group_by: this.state.graphGroupBy,
                      view: 'adoption_motives'
                    }, {
                      onSuccess: () => {
                        this.setState({isLoadingGraph: false})
                      },
                      onError: (e) => this.showError(e)
                    });
                  } else if (this.state.selectedParetoIndicator === 'utilization') {
                    this.setState({isLoadingGraph: true})
                    this.props.loadUtilizationGraph({
                      company_id: this.props.companyId,
                      start_date: this.state.motivesGraphStartDate.includes("Semana") ? dateToString(this.state.filterDate[0]) : this.state.motivesGraphStartDate,
                      end_date: this.state.motivesGraphEndDate.includes("Semana") ? dateToString(this.state.filterDate[0]) : this.state.motivesGraphEndDate,
                      facilities: this.state.filterFacilities,
                      origin: this.state.filterOrigin,
                      destination: this.state.filterDestination,
                      base_tags: this.state.filterBaseTags,
                      trip_tags: this.state.filterTags,
                      order_tags: this.state.filterTags2,
                      transport_class: this.state.filterTransportClass,
                      fleet_provider: this.state.fleetProviderToRequest,
                      supplement: this.state.filterSupplement1,
                      appointment_type: (this.state.filterDateType || []).join(",") || "",
                      // week: this.state.motivesGraphStartDate.includes("Semana") ? Number(this.state.motivesGraphStartDate.split(' ')[1]) : '',
                      week: weeks,
                      plates: this.state.platesToRequest,
                      economic_number: this.state.economicToRequest,
                      operators: this.state.operatorsToRequest,
                      group_by: this.state.graphGroupBy,
                      view: 'utilization_motives'
                    }, {
                      onSuccess: () => {
                        this.setState({isLoadingGraph: false})
                      },
                      onError: (e) => this.showError(e)
                    });
                  }

                  setTimeout(() => {
                    Scroll.go(document.getElementById('paretoGapPanel'), document.getElementById('pageContent'));
                  }, 200);
                }}
              >
                {this.getGAPTitle()}
              </div>
            </div>
          </ul>
          <div var="content" className="panel-content rutas">
            <div className="charts">
              <div className="paretoRuta" >
                { this.graphTable( this.state.isLoadingFolios ? [] : (this.props.foliosData.positives || [] )) }
              </div>
            </div>
          </div>
          <div style={{height: "35px"}}>
            
          {
            this.state.isLoading
            ? <Loader className="download-icon"/>
            : <DownloadImage
                fill={"#FFFFFF"}
                width={"50px"}
                height={"50px"}
                className="download-icon"
                onClick={(e) => {e.stopPropagation(); this.openDocument("folios")}}
              /> 
          }
            
          </div>
          { this.state.isLoadingFolios && this.presentBlurView() }
        </div>
      </div>
    );

  }

  getGapFilters = () => {
    switch(this.state.selectedParetoIndicator){
      case "allocation":
        return (
          <div className="desviacion desv">
            <li className={"desviacion".concat(this.state.selectedGAPMode === "table" ? " selected" : "", " estadiaPlanta")} onClick={() => this.setState({selectedGAPMode: "table"}, this.graphGAP)} >
              Folios<div></div>
            </li>
            <li className={"desviacion".concat(this.state.selectedGAPMode === "process" ? " selected" : "", " estadiaPlanta")} onClick={() => this.setState({selectedGAPMode: "process"}, this.graphGAP)} style={{borderRadius: "0px 15px 15px 0px"}}>
              En proceso<div></div>
            </li>
          </div>
        );
      case "adoption":
        return (
          <div className="desviacion desv">
            <li className={"desviacion".concat(this.state.selectedGAPMode === "table" ? " selected" : "", " estadiaPlanta")} onClick={() => this.setState({selectedGAPMode: "table"}, this.graphGAP)}>
              Folios<div></div>
            </li>
            <li className={"desviacion".concat(this.state.selectedGAPMode === "process" ? " selected" : "", " estadiaPlanta")} onClick={() => this.setState({selectedGAPMode: "process"}, this.graphGAP)} style={{borderRadius: "0px"}}>
              En proceso<div></div>
            </li>
            <li className={"desviacion".concat(this.state.selectedGAPMode === "motives" ? " selected" : "", " estadiaPlanta")} onClick={() => this.setState({selectedGAPMode: "motives"}, this.graphGAP)} style={{borderRadius: "0px 15px 15px 0px"}}>
              Motivos<div></div>
            </li>
          </div>
        );
      case "utilization":
        return (
          <div className="desviacion desv">
            <li className={"desviacion".concat(this.state.selectedGAPMode === "table" ? " selected" : "", " estadiaPlanta")} onClick={() => this.setState({selectedGAPMode: "table"}, this.graphGAP)}>
              Folios<div></div>
            </li>
            <li className={"desviacion".concat(this.state.selectedGAPMode === "process" ? " selected" : "", " estadiaPlanta")} onClick={() => this.setState({selectedGAPMode: "process"}, this.graphGAP)} style={{borderRadius: "0px"}}>
              En proceso<div></div>
            </li>
            <li className={"desviacion".concat(this.state.selectedGAPMode === "motives" ? " selected" : "", " estadiaPlanta")} onClick={() => this.setState({selectedGAPMode: "motives"}, this.graphGAP)} style={{borderRadius: "0px 15px 15px 0px"}}>
              Motivos<div></div>
            </li>
          </div>
        );
      case "quality":
        return (
          <div className="desviacion desv">
            <li className={"desviacion".concat(this.state.selectedGAPMode === "table" ? " selected" : "", " estadiaPlanta")} onClick={() => this.setState({selectedGAPMode: "table"}, this.graphGAP)}>
              Folios<div></div>
            </li>
            <li className={"desviacion".concat(this.state.selectedGAPMode === "process" ? " selected" : "", " estadiaPlanta")} onClick={() => this.setState({selectedGAPMode: "process"}, this.graphGAP)} style={{borderRadius: "0px 15px 15px 0px"}}>
              En proceso<div></div>
            </li>
          </div>
        );
      default:
        return ""
    
    }
  }

  getGapFilters1 = () => {
    switch(this.state.selectedParetoIndicator){
      case "allocation":
        return (
          <div className="desviacion desv">
            <li className={"desviacion".concat(this.state.selectedGAPMode1 === "table" ? " selected" : "", " estadiaPlanta")} onClick={() => this.setState({selectedGAPMode1: "table"}, this.graphGAP1)} >
              Folios<div></div>
            </li>
            <li className={"desviacion".concat(this.state.selectedGAPMode1 === "process" ? " selected" : "", " estadiaPlanta")} onClick={() => this.setState({selectedGAPMode1: "process"}, this.graphGAP1)} style={{borderRadius: "0px 15px 15px 0px"}}>
              En proceso<div></div>
            </li>
          </div>
        );
      case "adoption":
        return (
          <div className="desviacion desv">
            <li className={"desviacion".concat(this.state.selectedGAPMode1 === "table" ? " selected" : "", " estadiaPlanta")} onClick={() => this.setState({selectedGAPMode1: "table"}, this.graphGAP1)}>
              Folios<div></div>
            </li>
            <li className={"desviacion".concat(this.state.selectedGAPMode1 === "process" ? " selected" : "", " estadiaPlanta")} onClick={() => this.setState({selectedGAPMode1: "process"}, this.graphGAP1)} style={{borderRadius: "0px"}}>
              En proceso<div></div>
            </li>
            <li className={"desviacion".concat(this.state.selectedGAPMode1 === "motives" ? " selected" : "", " estadiaPlanta")} onClick={() => this.setState({selectedGAPMode1: "motives"}, this.graphGAP1)} style={{borderRadius: "0px 15px 15px 0px"}}>
              Motivos<div></div>
            </li>
          </div>
        );
      case "utilization":
        return (
          <div className="desviacion desv">
            <li className={"desviacion".concat(this.state.selectedGAPMode1 === "table" ? " selected" : "", " estadiaPlanta")} onClick={() => this.setState({selectedGAPMode1: "table"}, this.graphGAP1)}>
              Folios<div></div>
            </li>
            <li className={"desviacion".concat(this.state.selectedGAPMode1 === "process" ? " selected" : "", " estadiaPlanta")} onClick={() => this.setState({selectedGAPMode1: "process"}, this.graphGAP1)} style={{borderRadius: "0px"}}>
              En proceso<div></div>
            </li>
            <li className={"desviacion".concat(this.state.selectedGAPMode1 === "motives" ? " selected" : "", " estadiaPlanta")} onClick={() => this.setState({selectedGAPMode1: "motives"}, this.graphGAP1)} style={{borderRadius: "0px 15px 15px 0px"}}>
              Motivos<div></div>
            </li>
          </div>
        );
      case "quality":
        return (
          <div className="desviacion desv">
            <li className={"desviacion".concat(this.state.selectedGAPMode1 === "table" ? " selected" : "", " estadiaPlanta")} onClick={() => this.setState({selectedGAPMode1: "table"}, this.graphGAP1)}>
              Folios<div></div>
            </li>
            <li className={"desviacion".concat(this.state.selectedGAPMode1 === "process" ? " selected" : "", " estadiaPlanta")} onClick={() => this.setState({selectedGAPMode1: "process"}, this.graphGAP1)} style={{borderRadius: "0px 15px 15px 0px"}}>
              En proceso<div></div>
            </li>
          </div>
        );
      default:
        return ""
    
    }
  }

  getGapSection = () => {

    if (!this.state.showGAPModule) { return(<div/>); }

    return(
      <div>
        { this.state.isLoadingGraph && this.presentLoader() }

        <div id="paretoGapPanel" className="dashboard-panel dashboard-TAT-Month " style={{display: ""}}>
          <div className="panel-title etapas">
            <b >{this.getGAPTitle()}</b>
            <span >
              {this.getParetoDate()}
            </span>
          </div>
          <ul className="filtros">
            { this.getGapFilters() }
          </ul>
          <div var="content" className="panel-content rutas">
            <div className="charts">
              <div className="paretoRuta" >
                <div id="GAPChart"></div>
                {
                  (this.state.selectedGAPMode !== 'motives' && !this.state.isLoadingGraph) && this.graphTable(this.state.selectedGAPMode === 'table' ? (this.props.foliosData.gap || []): (this.props.foliosData.in_process || []))
                }
                {
                  (this.state.selectedGAPMode === 'motives' && !this.state.isLoadingGraph && (this.props.graphData ?? []).length === 0) && this.graphTable([])
                }
              </div>
            </div>
          </div>
          <div style={{height: "35px"}}>
            
            {
              this.state.isLoading
              ? <Loader className="download-icon"/>
              : <DownloadImage
                  fill={"#FFFFFF"}
                  width={"50px"}
                  height={"50px"}
                  className="download-icon"
                  onClick={(e) => {e.stopPropagation(); this.openDocument("folios")}}
                /> 
            }
            
          </div>
          { this.state.isLoadingGraph && this.presentBlurView() }
        </div>
      </div>
    );

  }

  getFreeSubscription = () => {
    return(
      <NotAllowedContent>
        {'La cuenta no tiene acceso a este modulo'}
      </NotAllowedContent>
    );
  }

  getMainContent() {
    // let mtm = (this.props.tatGlobal?.mtm || [])[this.state.filterDate[0].getMonth()] || {};
    // let mtm = MTM;
    // let dtd = (this.props.tatGlobal?.dtd || []).find(d => d.id === "".concat(
    //   this.state.filterDate[0].getFullYear(), 
    //   '-', 
    //   this.digit2(this.state.filterDate[0].getMonth() + 1), 
    //   '-', 
    //   this.digit2(this.state.filterDate[0].getDate())
    // )) || {};
    // let dtd = DTD;
    return (
      <View className="tat-view">
        <div className="tat-header">
          <FilterBar
            searchAction={this.handleTextSearch}
            filterAction={this.handleFilter}
            searchStr={this.state.searchStr}
            onChange={this.handleFilterChange}
            searchButtonText={this.state.buttonFilterName}
            content={this.filterContents()}
            textSearch= {false}
            selectedItems={this.getSelectedItems()}
          />
          <div className="refresh-button">
            <ClockAlert />
            <span>{this.state.refreshLabel}</span>
          </div>
        </div>
        <div className="tat-content">
          {
            (this.state.isLoadingData || this.state.isLoadingGlobal) && this.presentLoader()
          }
          <ErrorDialog
            open={this.state.showErrorMessage}
            message={"Opss falló la consulta, favor de intentar de nuevo, si el problema persiste, favor de contactar a soporte"}
            acceptText="OK"
            acceptAction={() => {
              this.setState({showErrorMessage: false});
            }}
            closeAction={() => {
              this.setState({showErrorMessage: false});
            }}
          />
          <ErrorDialog
            open={this.state.msgError !== ''}
            message={this.state.msgError}
            acceptText="OK"
            acceptAction={() => this.setState({msgError: ''})}
            closeAction={() => this.setState({msgError: ''})}
          />
          <div className="dashboard-panel dashboard-stats">
            <div className="panel-title">
              {this.getMonthStatsTitle()}
            </div>
            <div className="panel-content">
              <canvas width="260" height="260" id="canvas-month"></canvas>
              <div className="total"><b style={{fontSize: "55px"}}>{(this.props.dateData["mtm"] || {}).orders}</b>Folios</div>
              <div className="stats">
                <div className="titles">
                  
                  <b className="allocation" >ASIGNACIÓN </b>
                  <b className="adoption">ADOPCIÓN</b>
                  <b className="utilization" >UTILIZACIÓN</b>
                  <b className="quality">CALIDAD</b>
                </div>
                <div className="data">
                  <b className="real" >{this.formatPCT((this.props.dateData["mtm"] || {}).allocation || 0, 1)}%</b>
                  <b className="real" >{this.formatPCT((this.props.dateData["mtm"] || {}).adoption || 0, 1)}%</b>
                  <b className="real">{this.formatPCT((this.props.dateData["mtm"] || {}).utilization || 0, 1)}%</b>
                  <b className="real">{this.formatPCT((this.props.dateData["mtm"] || {}).quality || 0, 1)}%</b>
                </div>
              </div>
            </div>
            { this.state.isLoadingData && this.presentBlurView() }
          </div>
          
          <div className="dashboard-panel dashboard-stats">
            <div className="panel-title">
              {this.getDayStatsTitle()}
            </div>
            <div className="panel-content">
              <canvas width="260" height="260" id="canvas-day"></canvas>
              <div className="total"><b style={{fontSize: "55px"}}>{(this.props.dateData["dtd"] || {}).orders}</b>Folios</div>
              <div className="stats">
                <div className="titles">
                  <b className="allocation" >ASIGNACIÓN </b>
                  <b className="adoption">ADOPCIÓN</b>
                  <b className="utilization" >UTILIZACIÓN</b>
                  <b className="quality">CALIDAD</b>
                </div>
                <div className="data">
                  <b className="real" >{this.formatPCT((this.props.dateData["dtd"] || {}).allocation || 0, 1)}%</b>
                  <b className="real" >{this.formatPCT((this.props.dateData["dtd"] || {}).adoption || 0, 1)}%</b>
                  <b className="real">{this.formatPCT((this.props.dateData["dtd"] || {}).utilization || 0, 1)}%</b>
                  <b className="real">{this.formatPCT((this.props.dateData["dtd"] || {}).quality || 0, 1)}%</b>
                </div>
              </div>
              {/* <b className="loader" style={{display: "none"}}><Loader /></b> */}
            </div>
            { this.state.isLoadingData && this.presentBlurView() }
          </div>

          <div className="dashboard-panel dashboard-TAT-Month">
            <div className="panel-title tat">Calidad diaria</div>
            <div className="panel-content" style={{paddingTop: "92px"}}>
              <ul style={{marginTop: "20px"}}>
                {
                  months_abbr.map((m, i) => {
                    let current = new Date();
                    return <li
                      key={i}
                      className={(this.state.selectedMonth === i ? 'selected' : '').concat((i <= current.getMonth() || this.state.filterDate[0].getFullYear() < current.getFullYear()) ? '' : ' disabled')}
                      onClick={() => {
                        this.setState({selectedMonth: i}, this.loadDailyInfo);
                      }}
                    >{m}</li>
                  })
                }
              </ul>
              <div className="charts">
                <div className="month" >
                  {/* <b className="loader" style={{display: "none"}}><Loader /></b> */}
                  <div id="dailyChart"></div>
                </div>
              </div>
            </div>
            { (this.state.isLoadingGlobal || this.state.isLoadingDaily) && this.presentBlurView() }
          </div>

          <div className="dashboard-panel dashboard-TAT-Month">
            <div className="panel-title tat">Calidad semanal</div>
            <div className="panel-content" style={{paddingTop: "92px"}}>
              <div className="charts">
                <div className="month" >
                  {/* <b className="loader" style={{display: "none"}}><Loader /></b> */}
                  <div id="weeklyChart"></div>
                </div>
              </div>
            </div>
            { (this.state.isLoadingGlobal || this.state.isLoadingWeekly) && this.presentBlurView() }
          </div>

          <div className="dashboard-panel dashboard-TAT-Month">
            <div className="panel-title tat">Calidad mensual</div>
            <div className="panel-content" style={{paddingTop: "92px"}}>
              <div className="charts">
                <div className="month" style={{display: "flex", width: "100%"}}>
                  {/* <b className="loader" style={{display: "none"}}><Loader /></b> */}
                  <div id="yearlyChart" style={{width: "20%"}}></div>
                  <div id="monthlyChart" style={{width: "80%"}}></div>
                </div>
              </div>
            </div>
            { this.state.isLoadingGlobal && this.presentBlurView() }
          </div>

          { this.getFoliosSection1() }

          { this.getGapSection1() }

          { this.getTopOffenders() }

          { this.getFoliosSection() }

          { this.getGapSection() }

          
        </div>
      </View>
    )
  }

  render() {
    return( this.state.isFreeSubscription ? this.getFreeSubscription() : this.getMainContent() );
  }
}


const mapStateToProps = (state) => {
  const companyId: number = state.globalCatalog.session.company.id;
  const companyType = state.globalCatalog.session.company?.type?.id || 0;
  const companyTags = getEntityItems(state, "COMPANIES.TAGS");
  const orderTags = companyTags.filter(tag => tag.type === 7);
  const tripTags = companyTags.filter(tag => tag.type === 9);
  const baseTags = companyTags.filter(tag => tag.type === 3);
  const LTFacilities = (getEntityItems(state, "COMPANIES.SHIPMENTS.LT.FILTERS") || [])
    .map(f => f.facilities || [])
    .flat()
    .map(f => ({
      id: f.id,
      description: `${f.name} - ${f.code} - ${f.alias}`
    }))
    .sort((a, b) => {
      if (a.description.toLowerCase() > b.description.toLowerCase()) {
        return 1;
      }
      if (a.description.toLowerCase() < b.description.toLowerCase()) {
        return -1;
      }
      return 0;
    })

  const transportTags = companyTags.filter(tag => tag.type === 8);
  const companyTransportClasses = getEntityItems(state, "COMPANIES.TRANSPORTCLASS");
  let transportClassesByTag = [];

  if(companyType === 1){
    let transportClass = getEntityItems(
      state,
      "COMPANIES.SHIPMENTS.LT.FILTERS"
    )
    
    let tempTransportClass = [];

    (transportClass || []).forEach(e => {
      (e.transport_classes_tags || []).forEach(t => {
        if(tempTransportClass.find(toFind => toFind.description === `${e.orbi_id} - ${t.tags.title}`) === undefined){
          const classes = (e.transport_classes_tags || []).filter(tag => tag.tags.title === t.tags.title)
          
          let selectedClasses = []
          classes.forEach(s => {
            (e.transport_classes || []).forEach(classe => {
              if(classe.id === s.transport_class){
                selectedClasses.push(classe)
              }
            })
          })

          tempTransportClass.push(
            { 
              description: `${e.orbi_id} - ${t.tags.title}`,
              id: e.id,
              items: selectedClasses.map((tc) => ({
                id: tc.code,
                description: tc.code,
              })),
            }
          )
        }
      })
    });

    transportClassesByTag = tempTransportClass
  } else {
    transportClassesByTag = transportTags.map(tag => {
      return ({
        id: tag.id,
        description: tag.title,
        items: (companyTransportClasses || []).filter(tC => tC.tags.map(t => t.id).includes(tag.id)).map(tC => ({id: tC.code, description: tC.code}))
      })
    }).filter(tC => tC.items.length);
  }

  let ownCompanyType = (state.globalCatalog.session.company.type || { id: 0 })
    .id;

  const companyProviders = getEntityItems(state, "COMPANIES.NETWORK");
  let oppositeCompanyType = ownCompanyType === 1 ? 2 : 1;
  const companyFacilities = getEntityItems(
    state,
    "COMPANIES.FACILITIES.FILTERS"
  ).map((f) => ({
    description: f.title,
    id: f.id,
    items: f.facilities.map((ff) => ({
      id: ff.id,
      description: `${ff.name} - ${ff.code} - ${ff.alias}`,
    })),
  }));
  let networkCompanies =
    Array.isArray(state.companyReducer.networkApprovedInvitations.data) &&
    state.companyReducer.networkApprovedInvitations.data.reduce(
      (result, network) => {
        let id = companyProviders.find(
          (provider) => provider.id === network.id
        );

        if (network.from_companies.id === companyId) {
          if (network.to_companies.type === oppositeCompanyType) {
            result.push({
              id: String(id?.fleet_provider?.id),

              description: network.to_companies_alias.concat(
                " - ",
                network.to_companies_code,
                " - ",
                network.to_companies.orbi_id
              ),
            });
          }
        } else {
          if (network.from_companies.type === oppositeCompanyType)
            result.push({
              // id: network.id,
              id: String(id?.fleet_provider?.id),
              // description: network.from_companies.corporation_name,
              description: network.from_companies.orbi_id.concat(
                " - ",
                network.from_companies_alias,
                " - ",
                network.from_companies_code
              ),
            });
        }
        return result;
      },
      []
    );

  let globalData = getEntityItems(state, "COMPANIES.UTILIZATIONV2.GLOBAL");
  const dateData = getEntityItems(state, "COMPANIES.UTILIZATIONV2.DATE");
  const offendersData = getEntityItems(state, "COMPANIES.UTILIZATIONV2.OFFENDERS")["data"] || [];
  const foliosData = getEntityItems(state, "COMPANIES.UTILIZATIONV2.FOLIOS");
  const foliosData1 = getEntityItems(state, "foliosData1");
  const foliosData2 = getEntityItems(state, "foliosData2");
  const graphData = getEntityItems(state, "COMPANIES.UTILIZATIONV2.GRAPH");
  const graphData1 = getEntityItems(state, "graphData1");
  const dayData = getEntityItems(state, "COMPANIES.UTILIZATIONV2.DAILY");
  const weekData = getEntityItems(state, "COMPANIES.UTILIZATIONV2.WEEKLY");

  if (!Array.isArray(globalData) && !Array.isArray(dayData)) {
    globalData['dtd'] = dayData['dtd']
  }

  if (!Array.isArray(globalData) && !Array.isArray(weekData)) {
    globalData['wtw'] = weekData['wtw']
  }

  // const isLoadingDaily = state?.api['COMPANIES.UTILIZATIONV2.DAILY']?.status?.isFetching || false;
  // const isLoadingWeekly = state?.api['COMPANIES.UTILIZATIONV2.WEEKLY']?.status?.isFetching || false;
  // const isLoadingGlobal = state?.api['COMPANIES.UTILIZATIONV2.GLOBAL']?.status?.isFetching || false;
  // const isLoadingData = state?.api['COMPANIES.UTILIZATIONV2.DATE']?.status?.isFetching || false;  
  // const isLoadingOffenders = state?.api['COMPANIES.UTILIZATIONV2.OFFENDERS']?.status?.isFetching || false;
  // const isLoadingFolios = state?.api['COMPANIES.UTILIZATIONV2.FOLIOS']?.status?.isFetching || false;
  // const isLoadingFolios1 = state?.api['foliosData1']?.status?.isFetching || false;
  // const isLoadingGraph = state?.api['COMPANIES.UTILIZATIONV2.GRAPH']?.status?.isFetching || false;
  // const isLoadingGraph1 = state?.api['graphData1']?.status?.isFetching || false;
  // const isLoadingGraph2 = state?.api['foliosData2']?.status?.isFetching || false;
  const supplement1 = getEntityItems(state, "COMPANIES.SYNC.SUPPLEMENT1").map(s => ({id: s.name, description: s.name}));
  // console.log("STATE",state);

  return {
    // isLoadingDaily,
    // isLoadingWeekly,
    companyId,
    companyType,
    LTFacilities,
    LTClasses: [],
    // companyTags,
    orderTags,
    tripTags,
    baseTags,
    transportClassesByTag,
    companyFacilities,
    networkCompanies,
    globalData,
    dateData,
    offendersData,
    // isLoadingGlobal,
    // isLoadingData,
    // isLoadingOffenders,
    foliosData,
    // isLoadingFolios,
    graphData: Array.isArray(graphData) ? {} : graphData,
    // isLoadingGraph,
    foliosData1,
    foliosData2,
    // isLoadingFolios1,
    graphData1: Array.isArray(graphData1) ? {} : graphData1,
    // isLoadingGraph1,
    // isLoadingGraph2,
    supplement1,
    isCO: (state.globalCatalog.session.company.type.id || 0) === 2,
    isPremiumSubscriptionCo: isPremiumSubscriptionCo(state.globalCatalog.session)
  };
};

const mapDispatchToProps = (dispatch) => {
  dispatch(listNetworkApprovedInvitations("", ""));
  return {
    cancelRequest: (entityPath) => dispatch(cancelRequest(entityPath)),
    loadLTFilters: (params, opts) =>
      dispatch(readEntities("COMPANIES.SHIPMENTS.LT.FILTERS", params, opts)),
    loadCompanyProviders: (params, opts) =>
      dispatch(readEntities("COMPANIES.NETWORK", params, opts)),
    loadCompanyFacilities: (params, opts) =>
      dispatch(readEntities("COMPANIES.FACILITIES.FILTERS", params, opts)),
    loadCompanySupplement1: (params, opts) =>
      dispatch(readEntities("COMPANIES.SYNC.SUPPLEMENT1", params, opts)),
    loadCompanyTags: (company_id) =>
      dispatch(readEntities("COMPANIES.TAGS", { company_id })),
    loadCompanyTransportClass: (company_id) => 
      dispatch(readEntities("COMPANIES.TRANSPORTCLASS", { company_id })),
    loadUtilizationGlobal: (params, opt) => 
      dispatch(readEntity("COMPANIES.UTILIZATIONV2.GLOBAL", params, opt)),
    loadUtilizationDate: (params, opt) => 
      dispatch(readEntity("COMPANIES.UTILIZATIONV2.DATE", params, opt)),
    loadUtilizationDaily: (params, opt) => 
      dispatch(readEntity("COMPANIES.UTILIZATIONV2.DAILY", params, opt)),
    loadUtilizationWeekly: (params, opt) => 
      dispatch(readEntity("COMPANIES.UTILIZATIONV2.WEEKLY", params, opt)),
    loadUtilizationOffenders: (params, opt) => 
      dispatch(readEntity("COMPANIES.UTILIZATIONV2.OFFENDERS", params, opt)),
    loadUtilizationFolios: (params, opt) => 
      dispatch(readEntity("COMPANIES.UTILIZATIONV2.FOLIOS", params, opt)),
    // loadUtilizationFolios1: (params, opt) => 
    //   dispatch(readEntity("COMPANIES.UTILIZATIONV2.FOLIOS", params, {...opt, alias: "foliosData1"})),
    loadUtilizationGraph: (params, opt) => 
      dispatch(readEntity("COMPANIES.UTILIZATIONV2.GRAPH", params, opt)),
    setNavbarAction: (name, config) => dispatch(setNavbarAction(name, config)),
    removeNavbarAction: (name) => dispatch(removeNavbarAction(name)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UtilizationViewv2);

const months_index = {
  "Ene": 0,
  "Feb": 1,
  "Mar": 2,
  "Abr": 3,
  "May": 4,
  "Jun": 5,
  "Jul": 6,
  "Ago": 7,
  "Sep": 8,
  "Oct": 9,
  "Nov": 10,
  "Dic": 11,
}
const months_abbr = [
  "Ene",
  "Feb",
  "Mar",
  "Abr",
  "May",
  "Jun",
  "Jul",
  "Ago",
  "Sep",
  "Oct",
  "Nov",
  "Dic"
];
const months_full = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre"
];

const MonthChart = {
  chart: {
    backgroundColor: 'transparent'
  },
  title: {
      text: ''
  },
  legend: {
    itemStyle: {
      color: '#fff',
      fontSize: '15px'
    }
  },
  tooltip: {
    shared: true,
    // valueDecimals: 2,
    
  },
  xAxis: {
    categories: [],
    crosshair: true,
    lineColor: '#303349',
    tickLength: 0,
    labels: {
      style: {
        color: 'white',
        fontSize: '15px'
      }
    }
  },
  yAxis: [
    { 
      gridLineWidth: 0, 
      title: { text: '' },
      // stackLabels: {
      //   enabled: true,
      //   formatter: 1,
      //   style: {
      //     color: '#FFFFFF',
      //     textOutline: '0px',
      //     fontSize: '14px',
      //   }
      // },
      maxPadding: 0.1,
      lineColor: '#303349',
      gridLineColor: '#303349'
    },
    {
      title: {
          text: ''
      },
      minPadding: 0,
      maxPadding: 0,
      min: 0,
      max: 100,
      opposite: true,

      lineColor: '#303349',
      gridLineColor: '#303349'
    }
  ],
  // tooltip: {
  //   enabled: false
  // },
  plotOptions: {
    spline: {
      dataLabels: {
          enabled: true,
          style: {
            fontSize: '17px'
          }
      },
    }
  },
  series: [{
      name: 'Órdenes',
      data: [],
      stack: 0,
      color: '#c5daf3',
      borderWidth: 0,
      type: 'column',
      dataLabels: [{
        enabled: true,
        align: 'center',
        inside: true,
        verticalAlign: 'bottom',
        style: {
          fontWeight: 'normal',
          // textOutline: false,
          fontSize: '15px',
        },
        color: "#fff",
        // formatter: function(){
        //   return (this.y !== 0 ? this.y.toFixed(legendDecimals) : '');
        // },
        // x: 0,
        y: 0
      }]
    }, {
      name: 'Asignación',
      data: [],
      stack: 0,
      color: '#bfbdb4',
      yAxis: 1,
      type: 'spline',
      borderWidth: 0,
      marker: {
        symbol: 'circle'
      },
      dataLabels: [{
        align: 'center top',
        x: 0,
        y: 10,
        color: '#fff',
        shadow: false,
        style: {
          fontWeight: 'normal',
          fontSize: '15px',
        },
      }]
    }, {
      name: 'Adopción',
      data: [],
      stack: 0,
      color: '#9bbdd9',
      yAxis: 1,
      type: 'spline',
      borderWidth: 0,
      marker: {
        symbol: 'circle'
      },
      dataLabels: [{
        align: 'center top',
        x: 0,
        y: 10,
        color: '#fff',
        shadow: false,
        style: {
          fontWeight: 'normal',
          fontSize: '15px',
        },
      }]
    }, {
      name: 'Utilización',
      data: [],
      stack: 0,
      color: '#4193cd',
      yAxis: 1,
      type: 'spline',
      borderWidth: 0,
      marker: {
        symbol: 'circle'
      },
      dataLabels: [{
        align: 'center top',
        x: 0,
        y: 10,
        color: '#fff',
        shadow: false,
        style: {
          fontWeight: 'normal',
          fontSize: '15px',
        },
      }]
    }, {
      name: 'Calidad',
      data: [],
      stack: 0,
      color: '#41707c',
      yAxis: 1,
      type: 'spline',
      borderWidth: 0,
      marker: {
        symbol: 'circle'
      },
      dataLabels: [{
        align: 'center top',
        x: 0,
        y: 10,
        color: '#fff',
        shadow: false,
        style: {
          fontWeight: 'normal',
          fontSize: '15px',
        },
      }]
    }, {
      name: 'Folios en proceso',
      data: [],
      type: 'spline',
      // legendIndex:5,
      yAxis: 1,
      // zIndex: 11,
      lineWidth: 0,
      color: '#f7a35c',
      // marker: {
      //   enabled: true,
      //   symbol: 'diamond',
      //   radius: 7
      // },
      states: {
        hover: {
          lineWidthPlus: 0
        }
      },
      dataLabels: [{
        align: 'center top',
        x: 0,
        y: 10,
        color: '#fff',
        shadow: false,
        style: {
          fontWeight: 'normal',
          fontSize: '15px',
        },
      }],
      // dataLabels: {
        // enabled: true,
        // style: {
        //   color: '#FFFFFF',
        // },
        // formatter: function(){
        //   return this.point.pct;
        // }
      // },
    }
  ],
  credits: {
    enabled: false
  },
};

const OffenderChart = {
  chart: {
    backgroundColor: 'transparent'
  },
  title: {
      text: ''
  },
  legend: {
    itemStyle: {
      color: '#fff',
      fontSize: '15px'
    }
  },
  tooltip: {
    shared: true,
    // valueDecimals: 2,
    
  },
  xAxis: {
    categories: [],
    crosshair: true,
    lineColor: '#535661',
    tickLength: 0,
    labels: {
      style: {
        color: 'white',
        // fontSize: '15px'
      }
    }
  },
  yAxis: [
    { 
      gridLineWidth: 0, 
      title: { text: '' },
      // stackLabels: {
      //   enabled: true,
      //   formatter: 1,
      //   style: {
      //     color: '#FFFFFF',
      //     textOutline: '0px',
      //     fontSize: '14px',
      //   }
      // },
      maxPadding: 0.4,
      lineColor: '#303349',
      gridLineColor: '#666666',
      opposite: true,
      startOnTick: false,
      endOnTick: false,
      labels: {
          enabled: false
      }
    },
    {
      title: {
          text: ''
      },
      minPadding: 0,
      maxPadding: 0,
      min: 0,
      max: 100,
      opposite: true,

      lineColor: '#303349',
      gridLineColor: '#666666',
      gridLineWidth: 1, 
      // startOnTick: false,
      // endOnTick: false,
      opposite: false,
      plotLines: [{
          color: '#fff',
          width: 2,
          value: 0,
          zIndex: 3
      }],
      // labels: {
      //     formatter: function(){
      //       return Math.floor(this.value/1) + '%';
      //     },
      //     enabled: (self.state.selectedParetoDisplayMode  === 'sum' ? true : false)
      // }
    }
  ],
  // tooltip: {
  //   enabled: false
  // },
  // plotOptions: {
  //   spline: {
  //     dataLabels: {
  //         enabled: true,
  //         style: {
  //           fontSize: '17px'
  //         }
  //     },
  //   }
  // },
  series: [{
      name: 'Órdenes Gap',
      data: [],
      stack: 0,
      color: '#c5daf3',
      borderWidth: 0,
      type: 'column',
      dataLabels: [{
        enabled: true,
        align: 'center',
        inside: true,
        verticalAlign: 'bottom',
        style: {
          fontWeight: 'normal',
          // textOutline: false,
          fontSize: '15px',
        },
        color: "#fff",
        // formatter: function(){
        //   return (this.y !== 0 ? this.y.toFixed(legendDecimals) : '');
        // },
        // x: 0,
        y: 0
      }]
    }, {
      name: '% viajes',
      data: [],
      legendIndex:5,
      zIndex: 11,
      lineWidth: 0,
      color: '#f7a35c',
      yAxis: 1,
      type: 'spline',
      borderWidth: 0,
      marker: {
        enabled: true,
        symbol: 'diamond',
        radius: 7
      },
      states: {
        hover: {
          lineWidthPlus: 0
        }
      },
      dataLabels: [{
        enabled: true,
        style: {
          color: '#FFFFFF',
        },
        // formatter: function(){
        //   return this.point.pct;
        // }
      }]
    },  {
      name: 'Total',
      showInLegend: false,
      data: [],
      stack: 0,
      color: '#00ffff',
      borderWidth: 0,
      visible: false
    }, {
      type: 'pareto',
      zIndex: 10,
      name: '% Gap Acum.',
      color: '#008CFF',
      yAxis: 1,
      // type: 'spline',
      baseSeries: 2,
      borderWidth: 0,
      dataLabels:{
        enabled: false
      }
    }
  ],
  credits: {
    enabled: false
  },
};

const MOTIVES = [
  {
    description: 'Uno',
    pct: 61.41,
  }, 
  {
    description: 'Dos',
    pct: 11.84
  }, 
  {
    description: 'Tres',
    pct: 10.85
  }, 
  {
    description: 'Cuatro',
    pct: 4.67
  }, 
  {
    description: 'Cinco',
    pct: 4.18
  }, 
  {
    description: 'Seis',
    pct: 7.05
  }
]

const MotivesChart = {
  chart: {
    // plotBackgroundColor: null,
    backgroundColor: 'transparent',
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie'
  },
  legend: {
    itemStyle: {
      color: '#fff',
      fontSize: '15px'
    }
  },
  credits: {
    enabled: false
  },
  title: {
      text: ''
  },
  tooltip: {
      // pointFormat: '<span style="color:{point.color}">\u25CF </span> {series.name}: <b>{point.percentage:.1f}%</b><br/>',
      pointFormat: '<span style="color:{point.color}">\u25CF </span> <b>{point.percentage:.1f}%</b><br/>',
      shared: true,
  },
  accessibility: {
      point: {
          valueSuffix: '%'
      }
  },
  plotOptions: {
      pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
              enabled: false
          },
          showInLegend: true
      }
  },
  series: [{
      name: 'Motivos',
      colorByPoint: true,
      data: []
      // data: [{
      //     name: 'Chrome',
      //     y: 61.41,
      // }, {
      //     name: 'Internet Explorer',
      //     y: 11.84
      // }, {
      //     name: 'Firefox',
      //     y: 10.85
      // }, {
      //     name: 'Edge',
      //     y: 4.67
      // }, {
      //     name: 'Safari',
      //     y: 4.18
      // }, {
      //     name: 'Other',
      //     y: 7.05
      // }]
  }]
}

const NotAllowedContent = styled.div`
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90%;
  font-size: 35;
`;