import React, { createRef, useImperativeHandle }  from "react";
import { connect } from "react-redux";
import { readEntities, readCatalog, cancelRequest, partialEntity, createEntity } from "../../../redux/api/actions.jsx";
import { getEntityItems, getEntityCount } from "../../../redux/api/helpers.jsx";
import { validateObjPerms } from "../../../redux/reducers/global-catalog.reducer.jsx";
import ShipmentBoardRow from "../../../shared/component/shipment-board-row.jsx";
import TableD from "../../../shared/component/table-d.jsx";
import PaginatorControlled from "../../../shared/controls/paginator-controlled.jsx";
import ChangeDateModal from "../../../shared/dialog/change-date-modal.jsx";
import UnassignedOrderRow from "./unassigned-order-row.jsx";
import { mapOrderShipmentRowType } from './utils';
import CancelAppointmentModal from '../detail/cancel-appointment-modal';
import FinalizeOrderModal from '../detail/finalize-order-modal';
import { buildTableConfiguration, hasDifferentOrder, calculateTableWidth, buildSampleTable, buildFilters } from "../detail/trips-control-configuration.jsx";
import { setOrderSeleted } from '../../../redux/reducers/global-catalog.reducer'; 
import { cloneDeep } from "lodash";
import Selector from '../../../shared/component/selector';
import CatalogSelectionFooter from '../../../shared/component/catalog-selection-footer';
import ConfirmDialog from '../../../shared/dialog/confirm-dialog';
import UserChipContainer from '../../../shared/component/user-chip-container';
import BaseDialog from '../../../shared/dialog/base-dialog';
import { getTimeZone, sendMessageToMultipleUsersInSocket } from "../../../shared/utils.jsx";
import * as moment from "moment";

let typingTimer;
const session = JSON.parse(sessionStorage.getItem("orbinetwork_session"));
const companyTypeId = session?.company?.type?.id || 0;
const horizontalScrollRef = createRef();

let timer;

class UnassignedOrdersBoard extends React.Component<Props> {
 
  constructor(props){
    super(props);
    this.state = {
      limit: 100,
      offset: 1,
      tempOffset: 1,
      getOrdersCalled: false,
      searchType: "1",
      time: 0,
      tempMaxElementsToShow: 20,
      maxElementsToShow: 20,
  
      shownewAppointmentModal: false,
      showChangeAppointmentModal: false,
      reasonSelect: 0,
      tableConfig: buildTableConfiguration(this.props.columnsData, this.props.columnsHidden),
      sampleConfig: buildSampleTable(),
      scrollToUpdate: null,

      //filter
      order: [], // 0 sin orden - 1 mayor a menor - 2 menor a mayor, words, idConfig, date sin orden, mayor, menor, sin valor
      columnsUpdate: Date.now(),

      selectedTripsId: [],
      selectionModeOn: false,
      ordersToFinalize: 0,
      showFinalizeOrdersModal: false,

      horizontalScrollRefLeft: 0,
      horizontalScrollRefWidth: 0,
    }
  }

  ordersCounter = 0;
  timerId = null;
  scrollTop = 0;

  componentDidMount() {
    this.setState({ ...this.props.configuration, getOrdersCalled: true }, () => {
      this.loadTrips();
    });
    this.props.getContainerStatus();
    document.addEventListener("keydown", this.cancelSearch)
  }

  componentWillUnmount() {
    this.stopTimer();
    clearTimeout(timer);
    document.removeEventListener("keydown", this.cancelSearch)
  }

  setTimer = () => {

    if (this.props.refreshSelected) {

      let timeToSchedule = 0;

      switch (this.props.refreshSelected) {
        case '1' : timeToSchedule = 60000; break;
        case '2' : timeToSchedule = 180000; break;
        case '3' : timeToSchedule = 300000; break;
        case '4' : timeToSchedule = 600000; break;
      }

      clearTimeout(timer);

      timer = setTimeout(() => {

        this.loadTrips();

      }, timeToSchedule);

      this.setState({
        scrollToUpdate: this.scrollTop,
        maxElementsToShow: this.state.tempMaxElementsToShow
      });

    } else {
      clearTimeout(timer);
    }

  }

  cancelSearch = (e) => {
    if(e.key === "Escape"){
      if(this.state.isLoadingTrips){
        if (this.props.updatePaginator) this.props.updatePaginator({target: {name: 'offset', value: this.state.tempOffset}});

        this.props.cancelRequest("COMPANIES.FACILITIES.COMMANDCONTROLCO");
        this.setState({isLoadingTrips: false});
        this.props.toggleLoadingState(false);
      }
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.filterStatus !== this.props.filterStatus ||
      prevProps.maneuver !== this.props.maneuver ||
      prevProps.filter !== this.props.filter ||
      prevProps.search !== this.props.search
    ){
      if(this.props.resetPaginator && this.state.offset > 1)
        this.setState({limit: 100, offset: 1});
      clearTimeout(typingTimer);
      typingTimer = setTimeout(this.loadTrips, 500);
    }

    if (hasDifferentOrder(prevProps.columnsData, this.props.columnsData) || prevProps.columnsHidden.length !== this.props.columnsHidden.length || this.props.columnsData.length !== prevProps.columnsData.length) {
      this.setState({
        tableConfig: buildTableConfiguration(this.props.columnsData, this.props.columnsHidden)
      });
    }

    if (this.props.offset && this.props.offset !== this.state.offset) {
      this.setState({
        offset: this.props.offset
      });
    }

    if (prevProps.refreshSelected !== this.props.refreshSelected) {
      this.setTimer();
    }

    if((prevProps.refreshData !== this.props.refreshData) && this.props.refreshData){
      this.loadTrips();
      this.props.updateRefreshData(false);
    }
  }

  getContainerTimeStatusValue = (order_id) => {

    if (this.state.containerTimeStatus && this.state.containerTimeStatus[`${order_id}`]) {
      return this.state.containerTimeStatus[`${order_id}`]
    }

    return {}
  }

  getContainerTimeStatus = () => {

    let orders = this.getOrdersToShow();
    let timeToShow = {};

    if (orders.length > 0) {

      for (let i = 0; i < orders.length; i++) {
        let order = orders[i];
        let containersTimeToShow = this.getContainerTimeStatusValue(order.order_id);

        Object.keys(order.containers_status).map( (e, i) => {

          let times = order.containers_status[`${e}`] || [];

          if (times.length > 0) {
            
            let container_status = `${times[times.length - 1].container_status_id ?? ''}`;
            let order_status = `${order?.status.id}`;
            let not_calculate_time = (container_status === '15' || container_status === '19' || container_status === '20' || container_status === '21' || order_status !== '7');

            if (!not_calculate_time) {
              let date = times[times.length - 1].event_date ?? '';
              let diff = moment().diff(moment(date));

              let time = parseInt(moment.duration(diff).asSeconds());

              containersTimeToShow[`${e}`] = time;

            }

          }

        });

        timeToShow[`${order.id}`] = containersTimeToShow

      }

    }

    return timeToShow;
  }

  startTimer = () => {
    if (this.timerId !== null) {
      clearInterval(this.timerId);
      this.setState({ time: 0, maxElementsToShow: 20 });
    }

    this.timerId = setInterval(() => {
      this.state.time = this.state.time + 1;
      this.state.containerTimeStatus = this.getContainerTimeStatus();

      this.setState({});
    }, 1000);
  }

  stopTimer = () => {
    clearInterval(this.timerId);
    this.timerId = null;
  }

  loadTrips = () => {
    if (this.props.companyType !== 1) {

      const { companyId, facilityId, maneuver, filter, search, category } = this.props;
      const { limit, offset } = this.state;

      this.setState({ getTripsCalled: true, isLoadingTrips: true });
      this.props.toggleLoadingState(true);

      this.props.cancelRequest("COMPANIES.FACILITIES.COMMANDCONTROLCO");
      if(search && search.length > 0){
        this.props.loadTrips({
          category: category || '',
          company_id: companyId,
          facility_id: facilityId,
          limit: limit,
          offset: offset,
          view: maneuver,
          // status: this.props.filterStatus || "",
          appointment_status: '',
          status: this.props.searchType  === "1" ? '' : (filter.selectedstatusorder || []).join(","),
          fleet_providers: '', //(filter.selectedProviders || []).join(","),
          transport_classes: '', //(filter.transportClass || []).join(","),
          trip_tags: '', //(filter.selectedTrip || []).join(","),
          order_tags: '', //(filter.selectedOrder || []).join(","),
          facilities: '',//this.getFacilities(maneuver, filter.selectedFacilities),
          priority: '', //(filter.selectedLevel || []).join(","),
          search: search || "",
          search_type: this.props.searchType  === "1" ? 'folio' : this.props.searchType  === "2" ? 'vehiculo' : 'placas', 
          multifacilities: "",
          from_facilities: "",
          to_facilities: "",
          appointment: '', /*(filter.appointment && filter.appointment[0]) 
            ? "".concat'', //(filter.appointment[0].toISOString(), ",", filter.appointment[1] ? filter.appointment[1].toISOString() : filter.appointment[0].toISOString()) 
            : "",*/
          appointment_type: '', //(filter.appointmentType  || []).join(",") || "1,2,3"
          start_date: (filter.appointment && filter.appointment[0]) ? filter.appointment[0].toISOString() : '',
          end_date: (filter.appointment && filter.appointment[1]) ? filter.appointment[1].toISOString() : '',
          timezone: getTimeZone(),
          container_status: '',
          from_facilities: '',
          to_facilities: '',
          from_to_facilities: '',
          eta_load_sort: '',
          eta_unload_sort: ''
        }, {
          onSuccess: (response) => {

            if (response.count) {
              this.state.ordersCount = response.count;
            }

            this.props.toggleLoadingState(false);
            this.setState({isLoadingTrips: false});
            this.startTimer();
            this.setTimer();
          }, 
          onError: () => {
            this.props.toggleLoadingState(false);
            this.setState({isLoadingTrips: false});
            this.stopTimer();
            this.setTimer();
          }
        });
      } else {

        // loading extra filters

        let fleet_providers = (filter.selectedProviders || []).join(",");
        let priority = (filter.selectedLevel || []).join(",");
        let transport_classes = (filter.transportClass || []).join(",");
        let order_tags = (filter.selectedOrder || []).join(",");
        let status = (filter.selectedstatusorder || []).join(",");
        let container_status = (filter.containerStatus || []).join(",");
        let etaLoading = '';
        let etaUnloading = '';

        if (this.state.order) {
          for (var i = 0; i < this.state.order.length; i++) {
            let filter = this.state.order[i];

            if (filter.idConfig === 4 && filter.ids.length && filter.ids.length > 0) {
              fleet_providers = (filter.selectedProviders || []).concat(filter.ids).join(",");
            }

            if (filter.idConfig === 14 && filter.ids.length && filter.ids.length > 0) {
              priority = (filter.selectedLevel || []).concat(filter.ids).join(",");
            }

            if (filter.idConfig === 12 && filter.ids.length && filter.ids.length > 0) {
              transport_classes = (filter.transportClass || []).concat(filter.ids).join(",");
            }

            if (filter.idConfig === 7 && filter.ids.length && filter.ids.length > 0) {
              order_tags = (filter.selectedOrder || []).concat(filter.ids).join(",");
            }

            if (filter.idConfig === 13 && filter.ids.length && filter.ids.length > 0) {
              status = (filter.selectedstatusorder || []).concat(filter.ids).join(",");
            }

            if (filter.idConfig === 10 && filter.ids.length && filter.ids.length > 0) {
              container_status = (filter.containerStatus || []).concat(filter.ids).join(",");
            }

            if (filter.idConfig === 17 && filter.ids.length && filter.ids.length > 0) {
              let order = filter.ids[0];
              if (order === 1) {
                etaLoading = 'desc'
              } else if (order === 2) {
                etaLoading = 'asc'
              } else if (order === 3) {
                etaLoading = 'withoutDate'
              }
            }

            if (filter.idConfig === 21 && filter.ids.length && filter.ids.length > 0) {
              let order = filter.ids[0];
              if (order === 1) {
                etaUnloading = 'desc'
              } else if (order === 2) {
                etaUnloading = 'asc'
              } else if (order === 3) {
                etaUnloading = 'withoutDate'
              }
            }

          }
        }

        this.props.loadTrips({
          category: category || '',
          company_id: companyId,
          facility_id: facilityId,
          limit: limit,
          offset: offset,
          view: maneuver,
          // status: this.props.filterStatus || "",
          appointment_status: (filter.selectedAppointmentStatus || []).join(","),
          status,
          fleet_providers,
          transport_classes, 
          trip_tags: (filter.selectedTrip || []).join(","),
          order_tags,
          facilities: this.getFacilities(maneuver, filter.selectedFacilities),
          priority,
          search: search || "",
          search_type: '',
          multifacilities: this.props.urlFacilities.filter(item => item !== '1') || "",
          from_facilities: filter.from_facilities || '',
          to_facilities: filter.to_facilities || '',
          appointment: (filter.appointment && filter.appointment[0]) 
            ? "".concat(filter.appointment[0].toISOString(), ",", filter.appointment[1] ? filter.appointment[1].toISOString() : filter.appointment[0].toISOString()) 
            : "",
          appointment_type: maneuver === 'all' ? (filter.appointmentType  || []).join(",") || "1,2,3" : '',
          start_date: (filter.appointment && filter.appointment[0]) ? filter.appointment[0].toISOString() : '',
          end_date: (filter.appointment && filter.appointment[1]) ? filter.appointment[1].toISOString() : '',
          timezone: getTimeZone(),
          container_status,
          from_facilities: filter.from_facilities || [],
          to_facilities: filter.to_facilities || [],
          from_to_facilities: filter.from_to_facilities || [],
          eta_load_sort: etaLoading,
          eta_unload_sort: etaUnloading
        }, {
          onSuccess: (response) => {

            if (response.count) {
              this.state.ordersCount = response.count;
            }

            this.props.toggleLoadingState(false);
            this.setState({isLoadingTrips: false});
            this.startTimer();
            this.setTimer();
          }, 
          onError: () => {
            this.props.toggleLoadingState(false);
            this.setState({isLoadingTrips: false});
            this.stopTimer();
            this.setTimer();
          }
        });
      }
    } else {
      this.startTimer();
    }
  };

  getFacilities = (type, facilities) => {
    switch (type) {
      case "all":
        return "";
      default:
        return (facilities || []).join(",");
    }
  };

  getTableColumns = () => {
    return this.state.tableConfig.columns
    // if(this.props.companyType !== 1){
    //   return this.state.tableConfig.columns
    // } else {
    //   let columns = [...this.state.tableConfig.columns];
    //   columns[2].text = "Cliente";
    //   return columns;
    // }
  }

  handleScroll = (e) => {
    this.scrollTop = e.target.scrollTop;

    const scrollTop = e.target.scrollHeight - e.target.scrollTop;
    const validation = ((e.target.clientHeight + 2) > scrollTop) && ((e.target.clientHeight - 2) < scrollTop);

    let orders = [];

    if ((this.props.ordersLT || []).length > 0) {
      orders = this.props.ordersLT;
    } else {
      orders = this.props.orders;
    }

    if (validation && (orders || []).length > this.state.maxElementsToShow) {
      this.setState({
        tempMaxElementsToShow: this.state.maxElementsToShow + 20,
        maxElementsToShow: this.state.maxElementsToShow + 20
      });
    }

    if (this.state.scrollToUpdate) {
      this.setState({
        scrollToUpdate: null
      });
    }

    if (this.props.updatePositions) this.props.updatePositions({horizontal: horizontalScrollRef.current.scrollLeft, vertical: this.scrollTop});
  }

  getEquipmentStatus = (status) => {

    let statusToShow = [];

    if (status) {
      Object.entries(status).map( ([key, value]) => {

        if (value && value.length) {
          let statusId = value[value.length - 1].container_status_id;

          if (!statusToShow.includes(statusId)) {
            statusToShow.push(statusId);
          }
        }

      });

    }

    return statusToShow;

  }

  getContainerStatusDescription = (statusId) => {
    let status = this.props.containerStatusCatalog.find(s => s.id === statusId);
    if(!status) return '-';
    return status.event_description;
  }

  getOrdersToShow = () => {
    let orders = [];
    const { maxElementsToShow } = this.state;

    if ((this.props.ordersLT || []).length > 0) {
      orders = this.props.ordersLT;
    } else {
      orders = this.props.orders;
    }

    let newOrders = orders.slice(0, maxElementsToShow);
    return newOrders;
  }

  getCurrentOrders = () => {

    let orders = [];
    const { maxElementsToShow } = this.state;

    if ((this.props.ordersLT || []).length > 0) {
      orders = this.props.ordersLT;
    } else {
      orders = this.props.orders;
    }

    return orders.slice(0, maxElementsToShow);

  }

  getOrdersCount = () => {
    let ordersCount = 0;

    if ((this.props.ordersLT || []).length > 0) {
      ordersCount = this.props.ordersCountLT;
    } else {
      ordersCount = this.state.ordersCount;
    }

    return ordersCount;
  }

  isOwnerUser = () => {
    if (`${companyTypeId}` === '2') { return true; } 
    return false;
  }

  validShow = (perm) => {
    let response = this.props.validateObjPerms([perm], {
      display: true,
    });
    if (Object.keys(response).length) {
      return true;
    }
    return false;
  };

  manageCancelDialogs = (e: any) => {
    const { name, value } = e.target;

    console.log('adsfdasdfd');
    console.log(value);

    switch (name) {
      case "newAppointment":
        this.setState({
          shownewAppointmentModal: true,
          reasonSelect: 0,
          currentTrip: value,
          selectedNewDate: new Date()
        });
        break;

      case "openAppointment":
        //debugger;
        this.setState({
          reasonSelect: 0,
          showCancelAppointmentModal: true,
          currentTrip: value,
        });
        break;
      case "changeAppointment":
        this.setState({
          reasonSelect: 0,
          showChangeAppointmentModal: true,
          currentTrip: value,
          selectedNewDate: new Date()
        });
        break; 
      case "openTrip":
        this.setState({
          showCancelTripModal: true,
          reasonSelect: 0,
          currentTrip: value,
        });
        break;
      case "openBooking":
        this.setState({
          showCancelBookingModal: true,
          reasonSelect: 0,
          currentTrip: value,
        });
        break;
      case "openFinalizeTrip":
          this.setState({
            showFinalizeTripModal: true,
            reasonSelect: 0,
            currentTrip: value,
          });
          break;
      case "openFinalizeOrder":
        this.setState({
          showFinalizeOrderModal: true,
          reasonSelect: 0,
          currentTrip: value,
        });
        break;
      case "cancelTrip":
        this.props.cancelTrip(
          {
            company_id: this.props.companyId,
            trip_id: this.state.currentTrip.id,
          },
          {
            onSuccess: () => {
              this.setState({
                showCancelTripModal: false,
                currentTrip: undefined,
              });
              this.loadTrips();
            },
            onError: (e) => {
              this.setState({
                showCancelTripModal: false,
                currentTrip: undefined,
                showResultModal: true,
                reloadTrips: false,
                resultModalText: e.response?.data?.errors || ["Ocurrió un error al cancelar el viaje."],
                resultModalTitle: "¡Error!",
              });
            }
          }
        );
        break;
      case "cancelBooking":
        this.props.cancelBooking(
          {
            company_id: this.props.companyId,
            trip_id: this.state.currentTrip.id,
          },
          {
            onSuccess: () => {
              this.setState({
                showCancelBookingModal: false,
                currentTrip: undefined,
              });
              this.loadTrips();
            },
            onError: (e) => {
              this.setState({
                showCancelBookingModal: false,
                currentTrip: undefined,
                showResultModal: true,
                reloadTrips: false,
                resultModalText: e.response?.data?.errors || ["Ocurrió un error al cancelar la reservación."],
                resultModalTitle: "¡Error!",
              });
            }
          }
        );
        break;
      case "finalizeTrip":
          this.props.finalizeTrip(
            {
              company_id: this.props.companyId,
              trip_id: this.state.currentTrip.id,
            },
            {
              onSuccess: () => {
                this.setState({
                  showFinalizeTripModal: false,
                  currentTrip: undefined,
                });
                this.loadTrips();
              },
            }
          );
          break;
      case "finalizeOrder":
        // console.log(name, value);
        this.props.finalizeOrder(
          {
            company_id: this.props.companyId,
            id: this.state.currentTrip.maneuver.orderId,
            view: value,
          },
          {
            onSuccess: () => {
              this.setState({
                showFinalizeOrderModal: false,
                currentTrip: undefined,
                card: undefined,
              });
              this.loadTrips();
            },
          }
        );
        break;
      case "cancelAppointment":
        const params = {
          company_id: this.props.companyId,
          id: this.state.currentTrip.maneuver.orderId,
          view:
            this.state.currentTrip.maneuver.maneuver === "Carga"
              ? "load"
              : "unload",
          submotive: this.state.reasonSelect,
        };
        this.props.cancelAppointment(params, {
          onSuccess: () => {
            this.setState({
              showCancelAppointmentModal: false,
              currentTrip: undefined,
            });
            this.loadTrips();
          },
          onError: (e) => {
            this.setState({
              showCancelAppointmentModal: false,
              currentTrip: undefined,
              showResultModal: true,
              reloadTrips: false,
              resultModalText: e.response?.data?.errors || ["Ocurrió un error al cancelar la cita."],
              resultModalTitle: "¡Error!",
            });
          }
        });
        break;
      case "close":
      case "cancel":
        this.setState({
          showCancelTripModal: false,
          showCancelBookingModal: false,
          showCancelAppointmentModal: false,
          showChangeAppointmentModal: false,
          showFinalizeOrderModal: false,
          showFinalizeTripModal: false,
          spaceSelect: 0,
          reasonSelect: 0,
        });
        break;
      default:
        this.setState({ [name]: value });
    }
  };

  actionResolver = (order) => {

    let result = []
    let r = mapOrderShipmentRowType(order);

    if (this.validShow({id: "61", perm: "u",}) === true && r.trip_status !== "Cancelado" && r.trip_status !== "Viaje completado") {
      result.push({
        type: "detail cancel",
        description: "Cancelar cita",
        action: (additional) =>
          this.manageCancelDialogs({
            target: {
              name: "openAppointment",
              value: {
                ...r,
                maneuver: additional.maneuver,
                ticket: additional.ticket,
              },
            },
          }),
      }, {
        type: "detail assign",
        description: "Asignar cita",
        action: (additional) => 
          this.manageCancelDialogs({
            target: {
              name: "newAppointment",
              value: {
                ...r,
                maneuver: additional.maneuver,
                ticket: additional.ticket,
              },
            },
          }),
      }, {
        type: "detail change",
        description: "Cambiar cita",
        action: (additional) =>
          this.manageCancelDialogs({
            target: {
              name: "changeAppointment",
              value: {
                ...r,
                maneuver: additional.maneuver,
                ticket: additional.ticket,
              },
            },
          }),
      });
    }
    return result;
  };

  onColumnWidthUpdate = (e) => {
    let columns = cloneDeep(this.state.tableConfig.columns);
    columns[e.target.name].width = e.target.value;
    this.setState({tableConfig: {...this.state.TableConfig, columns}, columnsUpdate: Date.now()});
  }

  selectNewFilters = (filters) => {
    this.setState({
      order: filters
    }, () => {
      if (this.props.loadExtraFilters) {
        this.props.loadExtraFilters(filters);
      } else {
        this.loadTrips();
      }
    });
  }

  buildTagsTitle = (orders) => {
    let filter = orders.length > 0 ? orders[0].filters : {};
    let tags = [];

    if (filter.order_tags && filter.order_tags.length) {
      filter.order_tags.map(item => {
        if (item.ordertag_order__tag) {
          tags.push({
            id: item.ordertag_order__tag,
            title: item.ordertag_order__tag__title
          });
        }
      });
    }

    return tags;

  }

  buildFilterDescription = (orders) => {
    let filter = orders.length > 0 ? orders[0].filters : {};
    let filters = buildFilters(filter);

    if (filters['13'] && filters['13'].length) {
      let newArrayToAssing = [];

      filters['13'].map(item => {
        let orderStatus = this.props.orderStatus.filter(itemStatus => itemStatus.id === item.status);
  
        newArrayToAssing.push(
          {
            id: item.status,
            description: (orderStatus && orderStatus.length > 0) ? orderStatus[0].description : item.status
          }
        );
      });

      filters['13'] = newArrayToAssing;
    }

    if (filters['7'] && filters['7'].length) {
      let newArrayToAssing = [];

      filters['7'].map(item => {

        if (item.ordertag_order__tag) {
          newArrayToAssing.push(
            {
              id: item.ordertag_order__tag,
              description: item.ordertag_order__tag__title
            }
          );
        }

      });

      filters['7'] = newArrayToAssing;
    }

    if (filters['10'] && filters['10'].length) {
      let newArrayToAssing = [];

      filters['10'].map(item => {
        newArrayToAssing.push(
          {
            id: item.container_status,
            description: item.container_status__description
          }
        );
      });

      filters['10'] = newArrayToAssing;
    }

    if (filters['12'] && filters['12'].length) {
      let newArrayToAssing = [];

      filters['12'].map(item => {
        if (item.trip__transport_class) {
          newArrayToAssing.push(
            {
              id: item.trip__transport_class,
              description: item.trip__transport_class__code
            }
          );
        }
      });

      filters['12'] = newArrayToAssing;
    }

    return filters;
  }

  handleSelectTrip = (e, orders) => {
    let selectedTripsId = [...this.state.selectedTripsId];
    let tempOrder = (orders || []).filter( item => item.id === e.target.name);

    if (tempOrder.length > 0 && tempOrder[0].status.id === 11) {
      this.setState({ selectedTripsId, showFinalizeOrdersModal: true });
      return;
    }

    if (selectedTripsId.includes(e.target.name)) {
      selectedTripsId.splice(
        selectedTripsId.findIndex((f) => f === e.target.name),
        1
      );
    } else {
      selectedTripsId.push(e.target.name);
    }

    this.setState({ selectedTripsId });
  };

  finalizeOrdersSuccess = () => {
    this.ordersCounter = 0;
    this.setState({
      ordersToFinalize: 0,
      openFinalizeOrderModal: false,
      showSuccessFinalizeOrdersModal: true,
      selectionModeOn: false
    }, () => {
      if (this.props.companyType !== 1) {
        this.loadTrips();
      } else {
        if (this.props.loadOrders) {
          this.props.loadOrders();
        }
      }
    });
  }

  toggleSelectMode = () => {
    this.setState({
      selectionModeOn: !this.state.selectionModeOn,
      selectedTripsId: []
    });
  };

  handleProductChipClick = (id) => {
    let selectedTripsId = [...this.state.selectedTripsId];
    selectedTripsId.splice(
      selectedTripsId.findIndex((f) => f === id),
      1
    );
    this.setState({ selectedTripsId });
  };

  selectAllOrders = (orders) => {

    let tempOrders = orders.filter(item => item.status.id !== 11);

    if (tempOrders.length === (this.state.selectedTripsId.length)) {
      this.setState({ selectedTripsId: []});
    } else {
      let selectedTripsId = [];

      tempOrders
        .map((item) => {
          selectedTripsId.push(item.id);
          return null;
        });
      
      this.setState({ selectedTripsId});
    }
  };

  render() {

    let orders = this.getOrdersToShow();
    let filters = this.buildFilterDescription(orders);
    let tags = this.buildTagsTitle(orders);

    return (
      <>
        <div
          ref={horizontalScrollRef}
          style={{
            overflowX: 'scroll'
          }}
          onScroll={(e) => {
            if (this.props.updatePositions) if (this.props.updatePositions) this.props.updatePositions({horizontal: horizontalScrollRef.current.scrollLeft, vertical: this.scrollTop});
            this.state.horizontalScrollRefLeft = horizontalScrollRef.current.scrollLeft;
            this.state.horizontalScrollRefWidth = horizontalScrollRef.current.offsetWidth;
            
            this.setState({});
          }}
        >
          <div
            style={{
              width: `${calculateTableWidth(this.state.tableConfig.columns)}px`,
              // width: orders.length > 0 ? `${calculateTableWidth(this.state.tableConfig.columns)}px` : '100%',
              minWidth: '100%'
            }}
          >
            <TableD  
              tableConfig={this.state.tableConfig} 
              // tableConfig={orders.length > 0 ? this.state.tableConfig : this.state.sampleConfig} 
              onScroll={this.handleScroll} 
              toScroll={this.state.scrollToUpdate}
              onColumnWidthUpdate={this.onColumnWidthUpdate}
              horizontalScrollRef={horizontalScrollRef}
              orders={this.getCurrentOrders()}
              companyType={this.props.companyType}
              setOrder={(newOrder) => this.selectNewFilters(newOrder)}
              orderFilter={this.state.order}
              containerStatusCatalog={this.props.containerStatusCatalog}
              companyTags={tags}
              filters={filters}
            >
              {(this.props.loading) ?
                <div
                  style={{
                    backgroundColor: "#212A3C",
                    height: "300px",
                    textAlign: "center",
                    opacity: "0.57",
                    color: "#FFFFFF",
                    fontSize: "24px",
                    fontWeight: "bold",
                    letterSpacing: "0",
                    lineHeight: "37px",
                    paddingTop: "30px",
                  }}
                >
                  <span>
                    Cargando...
                  </span>
                  <br />
                  <img alt="" src="/images/empty-travels.png"></img>
                </div> 
                :
                orders.length > 0 ? 
                (
                  <Selector
                    selectedItemClass="selected"
                    selectionModeClass="selection"
                    selectedItems={this.state.selectedTripsId}
                    onSelect={(e) => this.handleSelectTrip(e, orders)}
                    selectionModeOn={this.state.selectionModeOn} 
                    toggleSelectionMode={this.toggleSelectMode}
                    selectorStyle={{ 
                      marginLeft: this.state.horizontalScrollRefLeft === 0 ? (horizontalScrollRef.current?.scrollLeft ?? 0) - 10 : this.state.horizontalScrollRefLeft - 10,
                      width: this.state.horizontalScrollRefWidth === 0 ? (horizontalScrollRef.current?.offsetWidth ?? 0) - 10 : this.state.horizontalScrollRefWidth - 10
                    }}
                  >
                {
                  orders.map((order, index) => (
                    <ShipmentBoardRow
                      key={order.id}
                      id={order.id}
                      // selected={this.getSelectedFunction(r)}
                    >
                      <UnassignedOrderRow  
                        {...order} 
                        columnsUpdate={this.state.columnsUpdate}
                        detail={mapOrderShipmentRowType(order)}
                        actions={this.actionResolver(order)}
                        facilityId={this.props.facilityId}
                        tags={(order.order_tags || []).map((o) =>
                          tags.find((t) => t.id === o.tag)
                        )}
                        key={index}
                        history={this.props.history}
                        containerStatusCatalog={this.props.containerStatusCatalog}
                        time={this.state.time}
                        containerTimeStatus={this.state.containerTimeStatus}
                        setOrderSelected={ () => {
                          this.props.setOrderDetail(order.code)
                        }}
                        tableConfig={
                          {
                            ...this.state.tableConfig,
                            // columns: this.getTableColumns()
                          }
                        }
                        companyType={this.props.companyType}
                        />
                      </ShipmentBoardRow>
                    ))
                  }
                  </Selector>
                )
                : (
                    <div
                      style={{
                        backgroundColor: "#212A3C",
                        height: "300px",
                        textAlign: "center",
                        opacity: "0.57",
                        color: "#FFFFFF",
                        fontSize: "24px",
                        fontWeight: "bold",
                        letterSpacing: "0",
                        lineHeight: "37px",
                        paddingTop: "30px",
                      }}
                    >
                      {/* <span>
                        No hay órdenes en este momento
                      </span>
                      <br />
                      <img alt="" src="/images/empty-travels.png"></img> */}
                    </div>
                  )
              }
            </TableD>
          </div>
        </div>
        <PaginatorControlled
          itemCount={this.getOrdersCount()}
          onChange={(e) => {
            if (this.state.getOrdersCalled) {
              // debugger;
              if (this.props.updatePaginator) {
                this.state.tempOffset = this.state.offset;
                this.props.updatePaginator(e);
              } else {
                if(e.target.name === "offset") this.props.updateOffset(e.target.value);
                if(e.target.name === "limit") this.props.updateLimit(e.target.value);
              }

              this.setState({ [e.target.name]: e.target.value }, () => {
                this.loadTrips();
              });
 
            }
          }}
          limit={this.state.limit}
          offset={this.state.offset}
        />

        <CatalogSelectionFooter
          show={this.state.selectionModeOn}
          selectedElements={this.state.selectedTripsId.length}
          selectAll={() => this.selectAllOrders(orders)}
          actions={[
            {
              description: "Cerrar",
              f: () => this.setState({selectionModeOn: false, selectedTripsId: []}),
            },
            {
              description: "Finalizar sin datos",
              f: () => this.setState({openFinalizeOrderModal: true}),
              disabled: !this.state.selectedTripsId.length
            }
          ]}
        />

        <ConfirmDialog
          open={this.state.openFinalizeOrderModal}
          isAcceptLoading={this.state.ordersToFinalize !== 0}
          title="Finalizar ordenes"
          message={"Esta interacción finalizará sin datos solo las órdenes seleccionadas, ¿Estás seguro que deseas continuar?"}
          contentObject={
            <UserChipContainer
              deleteTag={(id) => this.handleProductChipClick(id)}
              items={
                !Array.isArray(orders)
                  ? []
                  : orders
                      .filter((u) =>
                        this.state.selectedTripsId.includes(u.id)
                      )
                      .map((u) => {
                        return {
                          id: u.id,
                          name: u.signature,
                          avatarType: "contained",
                        };
                      })
              }
            />
          }
          acceptText="Aceptar"
          class="delete-facility"
          showCancel={true}
          cancelText="Cancelar"
          cancelButtonType="secondary"
          closeAction={() => this.setState({openFinalizeOrderModal: false})}
          acceptAction={() => {
            if (this.state.ordersToFinalize !== 0) { 
              this.setState({
                openFinalizeOrderModal: false,
                selectionModeOn: false
              });
              return;
            }

            let ordersTemp = orders.filter((u) =>
              this.state.selectedTripsId.includes(u.id)
            );

            if (ordersTemp.length === 0) {
              this.setState({
                openFinalizeOrderModal: false,
                selectionModeOn: false
              });
              return;
            }

            this.setState({
              ordersToFinalize: ordersTemp.length
            });

            for (var i = 0; i < ordersTemp.length; i++) {
              this.props.finalizeOrder( 
                {
                  company_id: this.props.companyId,
                  id: ordersTemp[i].id,
                  view: ''
                },
                {
                  onSuccess: () => {
                    this.ordersCounter = this.ordersCounter + 1
                    
                    if (this.ordersCounter === this.state.ordersToFinalize) {
                      this.finalizeOrdersSuccess()
                    }
                  },
                  onError: () => {
                    this.ordersCounter = this.ordersCounter + 1;

                    if (this.ordersCounter === this.state.ordersToFinalize) {
                      this.finalizeOrdersSuccess()
                    }
                  }
                }
              );
            }
          }}
        />

        <BaseDialog
          closeAction={() => {
            this.setState({
              showSuccessFinalizeOrdersModal: false, 
              selectionModeOn: false
            })
          }}
          acceptAction={() => {
            this.setState({
              showSuccessFinalizeOrdersModal: false, 
              selectionModeOn: false
            })
          }}
          open={this.state.showSuccessFinalizeOrdersModal}
          title={"Éxito"}
          message={"Se finalizaron las ordenes correctamente"}
          showCancel={false}
          cancelText="Cancelar"
          cancelButtonType="primary outline dark"
          acceptText="Aceptar"
          acceptButtonType="primary"
          style={{ content: { width: "600px", marginLeft: "-300px" } }}
        />

        <BaseDialog
          closeAction={() => {
            this.setState({
              showFinalizeOrdersModal: false
            })
          }}
          acceptAction={() => {
            this.setState({
              showFinalizeOrdersModal: false, 
            })
          }}
          open={this.state.showFinalizeOrdersModal}
          title={"Error"}
          message={"El estatus de esta orden ya esta en finalizado sin datos"}
          showCancel={false}
          cancelText="Cancelar"
          cancelButtonType="primary outline dark"
          acceptText="Aceptar"
          acceptButtonType="primary"
          style={{ content: { width: "600px", marginLeft: "-300px" } }}
        />

        {
          this.state.shownewAppointmentModal && (
            <ChangeDateModal
              editDate={false}
              open={this.state.shownewAppointmentModal}
              onClose={() => {
                this.setState({
                  shownewAppointmentModal: false
                });
              }}
              facilityId= {this.state.currentTrip.maneuver.facilityId}
              orderId={this.state.currentTrip.maneuver.orderId}
              tripDetails = {[this.state.currentTrip].map((detail) => {
                return {
                  ...detail, tags: detail.tags.map((o) => this.props.companyTags.find((t) => t.id === o.tag) )
                };
              })}
              data={{
                code: this.state.currentTrip.ticket, 
                logo: this.state.currentTrip.companyLogo,
                maneuver: this.state.currentTrip.maneuver.maneuver,
              }}
              reasons={this.props.orderMotives.filter((m) => m.type === 2)}
              date={new Date(this.state.currentTrip.maneuver.date)}
              loadTrips={ () => {
                this.setAcceptedResponse(this.state.currentTrip?.maneuver?.orderId || 0);
                this.loadTrips();
              }}
            />

          )
        }

        {
          this.state.showChangeAppointmentModal && (
            <ChangeDateModal
              isLoadingReasons={this.props.isLoadingReasons}
              editDate={true}
              open={this.state.showChangeAppointmentModal}
              onClose={() => {
                this.setState({
                  showChangeAppointmentModal: false
                });
              }}
              tripDetails = {[this.state.currentTrip].map((detail) => {
                return {
                  ...detail, tags: detail.tags.map((o) => this.props.companyTags.find((t) => t.id === o.tag) )
                };
              })}
              facilityId= {this.state.currentTrip.maneuver.facilityId}
              orderId={this.state.currentTrip.maneuver.orderId}
              data={{
                code: this.state.currentTrip.ticket, 
                logo: this.state.currentTrip.companyLogo,
                maneuver: this.state.currentTrip.maneuver.maneuver,
              }}
              reasons={this.props.orderMotives.filter((m) => m.type === 2)}
              date={new Date(this.state.currentTrip.maneuver.date)}
              loadTrips={ () => {
                this.setAcceptedResponse(this.state.currentTrip?.maneuver?.orderId || 0);
                this.loadTrips();
              }}
            />
          )
        }
        {
          this.state.showCancelAppointmentModal && (
          <CancelAppointmentModal
            show={this.state.showCancelAppointmentModal}
            reasons={[
              { label: "Selecciona una opción", value: 0, type: 2 },
            ].concat(this.props.orderMotives.filter((m) => m.type === 2))}
            reasonSelect={this.state.reasonSelect}
            data={{
              logo: this.state.currentTrip.companyLogo,
              trip: this.state.currentTrip.tripNumber,
              maneuver: this.state.currentTrip.maneuver.maneuver,
            }}
            onChange={this.manageCancelDialogs}
            date={new Date(this.state.currentTrip.maneuver.date)}
            place={``}
          />
          )
        }
        {
          this.state.showFinalizeOrderModal && (
            <FinalizeOrderModal
              show={this.state.showFinalizeOrderModal}
              data={{
                logo: this.state.currentTrip.companyLogo,
                name: this.state.currentTrip.companyName,
                trip: this.state.currentTrip.tripNumber,
                card: this.state.currentTrip.ticket
              }}
              onChange={this.manageCancelDialogs}
            />
          )
        }
      </>
    )
  }

  setAcceptedResponse = (order_id) => {
    if (order_id === 0 ) return;
    this.props.setActionAppointmentRequest(
      {
        company_id: this.props.companyId,
        order_id: order_id,
        action: 'accept_change_appointment_request'
      }, 
      {
        onSuccess: (result) => {
          if (result && result['accepted'] && result['accepted']['users'] && result['accepted']['users'].length > 0) {
            sendMessageToMultipleUsersInSocket(
              this.props.userId,
              result['accepted']['users'],
              result['accepted']['data']
            );
          }
        }
      }
    )
  }
}

const mapStateToProps = (state) => {

  const companyType = (state.globalCatalog.session.company.type || { id: 0 }).id;
  const containerStatusCatalog = getEntityItems(state, "COMPANIES.CATALOGS.CONTAINERSTATUS");
  let orders = getEntityItems(state, "COMPANIES.FACILITIES.COMMANDCONTROLCO");
  let ordersCount = getEntityCount(state, "COMPANIES.FACILITIES.COMMANDCONTROLCO");

  const orderStatus = getEntityItems(
    state,
    "COMPANIES.CATALOGS.ORDERSTATUS"
  ).map((f) => ({
    description: f.event_description,
    id: f.id || 0,
  }));

  const orderMotives = getEntityItems(state, "COMPANIES.CATALOGS.ORDERSMOTIVES")
    .map((o) =>
      o.submotives.map((s) => ({
        label: `${o.description} - ${s.description}`,
        value: s.id,
        type: s.type,
      }))
    )
    .flat();

  const isLoadingReasons = state?.api['COMPANIES.CATALOGS.ORDERSMOTIVES']?.status?.isFetching || false;

  return {
    orderStatus,
    companyType,
    orders,
    ordersCount,
    containerStatusCatalog,
    orderMotives,
    isLoadingReasons,
    userId: state.globalCatalog.session.user.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  dispatch(readEntities("COMPANIES.CATALOGS.ORDERSMOTIVES"));
  return {
    setActionAppointmentRequest: (params, opt) => dispatch(createEntity("COMPANIES.NOTIFICATIONSV2.CANCELREQUEST", params, opt)),
    getContainerStatus: (params, opt) => dispatch(readEntities("COMPANIES.CATALOGS.CONTAINERSTATUS", params, opt)),
    loadTrips: (params, options) => dispatch( readCatalog("COMPANIES.FACILITIES.COMMANDCONTROLCO", params, options)),
    cancelRequest: (entityPath: string) => dispatch(cancelRequest(entityPath)),
    validateObjPerms: (perms, obj) => validateObjPerms(perms, obj),
    cancelAppointment: (params: any, opts: ApiOptions) =>
      dispatch(
        partialEntity(
          "COMPANIES.ORDERS",
          { ...params, action: "cancel_appointment" },
          opts
        )
      ),
    setOrderDetail: (payload) => dispatch(setOrderSeleted(payload)),
    finalizeOrder: (params, opts) =>
      dispatch(
        partialEntity(
          "COMPANIES.ORDERS",
          { ...params, action: "finalize_order" },
          opts
        )
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnassignedOrdersBoard);

const cellStyle = {
  height: "50px",
  color: "#afc8ff",
  fontSize: "16px",
  lineHeight: "25px",
  textAlign: "left",
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'center'
};
const headerItemStyle = {
  color: "#afc8ff",
  fontSize: "16px",
  lineHeight: "25px",
  textAlign: "center",
};

const headerStyle = {
  color: "#afc8ff",
  fontSize: "16px",
  lineHeight: "25px",
  backgroundColor: "#455471",
  borderBottom: "solid #A4B4CE 2px"
};
const rowStyle = {
  backgroundColor: "#455471",
  height: "71px",
  borderBottom: "solid 1px #A4B4CE",
  minHeight: "71px"
};

const contentStyle = {
  height: 'calc(100% - 250px)',
  overflowY: "auto",
}

export const TableConfig = {
  rowStyle,
  headerStyle,
  contentStyle,
  columns: [
    {
      text: "Orden",
      width: "7.5%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Viaje",
      width: "7.5%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Programa",
      width: "7.5%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Transportista",
      width: "8.5%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Origen/Cita",
      width: "10%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Destino/Cita",
      width: "10%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Etiquetas",
      width: "7.5%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Vehículo",
      width: "7.5%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Equipos",
      width: "6%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Estatus equipo",
      width: "8%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Tiempo de estatus",
      width: "7.5%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Clase",
      width: "150px",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Estatus orden",
      width: "9%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Nivel",
      width: "4%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Tipo",
      width: "4%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "",
      width: "50px",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "",
      width: "18px",
      headerItemStyle,
      cellStyle,
    },
  ],
};
