//@flow
import * as React from "react";
import * as Types from "../types";
import { FILES_SERVER } from "../../../redux/api";
import Unitary from "../../../shared/icons/assemble/unitary";
import Tag from "../../../shared/controls/tag";
import ExceedPlus from "../../../shared/controls/exceed-plus";
import TruckDoubleEquipment from "../../../shared/icons/assemble/truck-double-equipment";
import TruckEquipment from "../../../shared/icons/assemble/truck-equipment";
import { ArrowDown, ArrowUp } from "orbi_v2_frontend_components/lib/Icons";
import { DoubleEquipment, Equipment, Truck } from "../../../shared/icons/assemble";

export const mapOrderShipmentRowType = (o) => ({
  id: o.id,
    // sortField: Date.parse(o.appointment_loading_date) || 0,
    ticket: o.code,
    trip_status: o.trip_status?.description || '',
    origin: {
      facilityId: o.from_facility.id,
      editable: true,//(o.main_from_facility ?? '1') === (o.from_facility?.id ?? ''),
      logo: FILES_SERVER.concat(o.from_company.logo),
      name: o.from_facility.name,
      date: o.appointment_loading_date
        ? new Date(o.appointment_loading_date)
        : "",
      location: "",
    },
    destination: {
      facilityId: o.to_facility.id,
      editable: true,//(o.main_to_facility ?? '1') === (o.to_facility?.id ?? ''),
      logo: FILES_SERVER.concat(o.to_company.logo),
      name: o.to_facility.name,
      date: o.appointment_unload_date
        ? new Date(o.appointment_unload_date)
        : null,
      location: "",
    },
    type: '',//t.transport_type?.description,
    level: o.priority,
    // weight: o.net_weight,
    weight: o.gross_weight,
    vol: o.volume,
    // quantity: o.gross_weight,
    is_unhooked: (o.order_containers || []).some(c => c.status === 9),
    status: o.status.description,
    quantity: "0",
    tags: o.order_tags || [],
    assemble: o.assembly,
    eta_loading: o.eta_loading_date || '',
    eta_unload: o.eta_unload_date || '',
    ata_loading: o.ata_loading_date || '',
    ata_unload: o.ata_unload_date || '',
    companyLogo: (o.secondary_company && o.secondary_company.secondary_logo) && FILES_SERVER.concat(o.secondary_company.secondary_logo),
});

export const mapTripToUnassignedShipmentRowType = (
  t: Types.UnassignedShipmentRowType
) => ({
  id: t.id,
  hasProvider: !!t.fleet_provider.logo,
  companyLogo:
    t.fleet_provider.logo && FILES_SERVER.concat(t.fleet_provider.logo),
  companyName: t.fleet_provider?.alias || t.fleet_provider?.orbi_id || "Pendiente",
  tripNumber: t.code || t.signature || "-",
  tickets: (t.orders || t.orders_without_appointment).length,
  status: t.status.description,
  class: t.sap_id,
  assemble: t.assembly,
  tc: t.transport_class?.rutas_tipo_vehiculo.id ?? '',
  mainFacility: [...new Set(t.main_from_facility.concat(t.main_to_facility))],
  // tags: t.distinct_order_tags || [],
  tags: t.trip_tags || [],
  // tags: (t.orders || t.orders_without_appointment)
  //   .map((o) => o.order_tags)
  //   .flat(),
  actions: [],
  is_unhooked: t.orders.map(o => (o.order_containers || [])).flat().some(c => c.status === 9),
  details: (t.orders || t.orders_without_appointment).map((o) => ({
    id: o.id,
    // sortField: Date.parse(o.appointment_loading_date) || 0,
    ticket: o.code,
    origin: {
      facilityId: o.from_facility.id,
      editable: true,//t.main_from_facility.includes(o.from_facility.id),
      logo: FILES_SERVER.concat(o.from_company.logo),
      name: o.from_facility.name,
      date: o.appointment_loading_date
        ? new Date(o.appointment_loading_date)
        : "",
      location: "",
    },
    destination: {
      facilityId: o.to_facility.id,
      editable: true,//t.main_to_facility.includes(o.to_facility.id),
      logo: FILES_SERVER.concat(o.to_company.logo),
      name: o.to_facility.name,
      date: o.appointment_unload_date
        ? new Date(o.appointment_unload_date)
        : null,
      location: "",
    },
    type: t.transport_type?.description,
    level: o.priority,
    // weight: o.net_weight,
    weight: o.gross_weight,
    vol: o.volume,
    // quantity: o.gross_weight,
    is_unhooked: (o.order_containers || []).some(c => c.status === 9),
    status: o.status.description,
    quantity: "0",
    tags: o.order_tags || [],
    assemble: o.assembly,
    eta_loading: o.eta_loading_date || '',
    eta_unload: o.eta_unload_date || '',
    ata_loading: o.ata_loading_date || '',
    ata_unload: o.ata_unload_date || '',
    order_containers: o.order_containers
  })),
  // .sort((a, b) => a.sortField - b.sortField),
});

export const mapBookingAssemblyAvailability = (
  t
): Types.BookingAssemblyRow[] => {
  const contract = t.contracts[0] || {};

  const availability = t.availability[0];

  return {
    alerts: t.assembly.equipments
      .filter((e) => e.fleet_equipment.status.id !== 2)
      .concat(
        t.assembly.vehicles.filter((e) => e.fleet_vehicle.status.id !== 2)
      )
      .concat(t.assembly.operators.filter((e) => e.fleet_operator.status !== 2))
      .length,
    selectedAssembly: 0,
    onChange: () => {},
    id: t.assembly.assembly_id,
    truck: {
      icon: getAssembleIconByTransportClassId(
        t.contracts[0] && t.contracts[0].contract_clases[0] && t.contracts[0].contract_clases[0].transport_class.rutas_tipo_vehiculo
      ),
      name: t.assembly.vehicles[0]?.fleet_vehicle.economic_number,
      tooltipContent: "",
    },
    orderTag: ((
      <ExceedPlus name={`${availability.order}-tags`}>
        {availability.order?.trip_tags?.map((tag, index) => (
          <Tag key={index} {...tag.tag} />
        ))}
      </ExceedPlus>
    ): null),
    availabilityDate: availability.availability_date,
    fleetProvider: {
      logo: FILES_SERVER.concat(contract.fleet_provider?.logo),
      name: contract.fleet_provider?.alias || contract.fleet_provider?.corporation_name,
      id: contract.fleet_provider?.fleet_provider_id,
    },
    contract: contract,
    expClass: (contract?.contract_clases
      || []).map((c) => c.transport_class.code)
      .join(","),
    ticketNumber: availability.order?.code || "-",
    maneuverIcon:
      availability.order?.operation === "load" ? (
        <ArrowUp width="10px" height="15px" />
      ) : availability.order?.operation === "unload" ? (
        <ArrowDown width="10px" height="15px" />
      ) : (
        <React.Fragment>
          <ArrowUp width="10px" height="15px" />
          <ArrowDown width="10px" height="15px" />
        </React.Fragment>
      ),
    operationTags: contract.tag ? <Tag {...contract.tag} /> : "-",
  };
};

export const mapBookingFleetProviderAvailability = (
  t
): Types.BookingFleetProviderRow => ({
  fleetProvider: {
    logo: FILES_SERVER.concat(t.contract?.fleet_provider?.logo),
    name: t.contract?.fleet_provider?.corporation_name,
    id: t.contract?.fleet_provider?.fleet_provider_id,
  },
  id: t.contract.id,
  onChange: () => {},
  selectedContract: 0,
  expClass: t.transport_class.code,
  total: t.quantity,
  available: t.available,
  used: t.quantity - t.unavailable - t.available,
  unavailable: t.unavailable,
});

const cellStyle = {
  height: "50px",
  color: "#afc8ff",
  fontSize: "16px",
  lineHeight: "25px",
  textAlign: "center",
};
const headerItemStyle = {
  color: "#afc8ff",
  fontSize: "16px",
  lineHeight: "25px",
  textAlign: "center",
};

const headerStyle = {
  color: "#afc8ff",
  fontSize: "16px",
  lineHeight: "25px",
  backgroundColor: "#455471",
  borderBottom: "solid #A4B4CE 2px",
};
const rowStyle = {
  backgroundColor: "#455471",
  height: "81px",
  borderBottom: "solid 1px #A4B4CE",
  minHeight: "81px"
};

const contentStyle = {
  maxHeight: "500px",
  overflowY: "auto",
}

export const TableConfig = {
  rowStyle,
  headerStyle,
  contentStyle,
  columns: [
    {
      text: "",
      width: "20px",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Transportista",
      width: "20%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Instalacion",
      width: "5%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "No. de viaje",
      width: "9%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Folios",
      width: "4%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Clase",
      width: "4%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Etiquetas",
      width: "10%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Estatus",
      width: "9%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Ensamble",
      width: "5%",
      headerItemStyle,
      cellStyle,
    },
    {
      text: "Acciones",
      width: "14%",
      headerItemStyle,
      cellStyle: { ...cellStyle, justifyContent: "flex-end" },
    },
  ],
};

export const getAssembleIconByAssemble = (assemble, has_alert = false) => {
  if(!assemble)
    return <></>
  if(Object.keys(assemble.vehicles).length){
    if(Object.keys(assemble.equipments).length){
      if(Object.keys(assemble.equipments).length > 1)
        return <TruckDoubleEquipment fill={has_alert ? "#ffba66" : "#AFC8FF"} stroke={has_alert ? "#ffba66" : "#AFC8FF"}/>;
      return <TruckEquipment  fill={has_alert ? "#ffba66" : "#AFC8FF"}  stroke={has_alert ? "#ffba66" : "#AFC8FF"}/>;
    }
    return <Truck  fill={has_alert ? "#ffba66" : "#AFC8FF"}  stroke={has_alert ? "#ffba66" : "#AFC8FF"}/>;
  } else {
    if(Object.keys(assemble.equipments).length){
      if(Object.keys(assemble.equipments).length > 1)
        return <DoubleEquipment  fill={has_alert ? "#ffba66" : "#AFC8FF"}  stroke={has_alert ? "#ffba66" : "#AFC8FF"}/>;
      return <Equipment  fill={has_alert ? "#ffba66" : "#AFC8FF"}  stroke={has_alert ? "#ffba66" : "#AFC8FF"}/>;
    }
  }
  return <></>;
}

export const getAssembleIconByTransportClassId = (tcId) => {
  switch (tcId) {
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
    case 10:
    case 11:
      return <Unitary />;
    case 12:
    case 13:
    case 14:
    case 15:
    case 16:
    case 17:
      return <TruckEquipment />;
    case 18:
    case 19:
    case 20:
    case 21:
    case 22:
    case 23:
    case 24:
    case 25:
    case 26:
    case 27:
    case 28:
      return <TruckDoubleEquipment />;
    default:
      return "";
  }
};
