import { io } from 'socket.io-client';
import { SOCKET } from "../redux/reducers/global-catalog.reducer";
import store from "../redux/store";
import { getUrlsEnv } from './backend-api';

class SocketIO {

  static connect = (user_id) => {
    try {
      const socket = io(getUrlsEnv().socket, { reconnect: true });

      socket.on('connect', (response) => {
        console.log('User connected!');
        
        const user_channel = `user-${user_id}`;
        socket.emit('create', user_channel);

        socket.on('user-update', (msg) => {
          console.log(`user update: ${msg}`);

          const event = new CustomEvent('user-update', { detail: msg });
          document.dispatchEvent(event);
        });

        store.dispatch(SOCKET.SET.ACTION(socket));
      });
    } catch(error) {
      console.log(`socket: ${error}`);
    }
  }

  static connectToOrder = (order_id) => {
    try {
      let socket = store.getState().globalCatalog.socket.client;
      
      const order_channel = `order-${order_id}`;
      socket.emit('create', order_channel);

      socket.on('order-comment', (msg) => {
        console.log(`message from: ${msg}`);

        const event = new CustomEvent('order-comment', { detail: msg });
        document.dispatchEvent(event);
      });
    } catch(error) {
      console.log(`socket: ${error}`);
    }
  }

  static sendMessage = (event, room, msg) => {
    try {
      let socket = store.getState().globalCatalog.socket.client;
      
      socket.emit(event, { room: room, payload: msg });
    } catch(error) {
      console.log(`socket: ${error}`);
    }
  }

  static leaveRoom = (event, room) => {
    try {
      let socket = store.getState().globalCatalog.socket.client;

      socket.emit('leave_room', room);
      socket.off(event);
    } catch(error) {
      console.log(`socket: ${error}`);
    }
  }

  static disconnect = () => {
    try {
      
      let socket = store.getState().globalCatalog.socket.client;
      socket.disconnect();
    } catch(error) {
      console.log(`socket: ${error}`);
    }
  }

}

export default SocketIO;