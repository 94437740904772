import Axios from "axios";
import React from "react";
import { connect } from 'react-redux';
import { SERVER } from "../redux/api/api-urls";
import { View, ViewTitle } from "../shared/styled/view";
import TableSortable from '../shared/component/table-sortable/table-sortable';
import { cancelRequest, getEntityItems, readEntities } from "../redux/api";
import moment from "moment";
import PDFIcon from "../shared/icons/pdf-icon";
import XMLIcon from "../shared/icons/xml-icon";
import { FILTER_LIST_TYPES } from "../shared/component/filter/filter-bar-content";
import FilterBarCompact from "../shared/component/filter/filter-bar-compact";
import PaginatorControlled from "../shared/controls/paginator-controlled";
import CatalogSelectionFooter from "../shared/component/catalog-selection-footer";
// import CopyIcon from "../shared/icons/copy-icon";
import HelperFilesModal from '../shared/component/helper_files_modal';
import { setNavbarAction, removeNavbarAction } from "../redux/reducers/navbar-reducer";
import ErrorDialog from "../shared/dialog/error-dialog";

const columnsData = [
  {
    key: "created",
    title: "Fecha de emisión",
    hideArrow: true
  },
  {
    key: "code",
    title: "Folio de viaje"
  },
  {
    key: "receiver_company",
    title: "RFC Receptor",
  },
  {
    key: "receiver_company_fiscal_number",
    title: "Razón Social",
  },
  {
    key: "amount",
    title: "Importe",
  },
  {
    key: "uuid",
    title: "Folio fiscal (UUID)",
    minWidth: 300,
    allowCopy: true,
    hideSort: true
  },
  {
    key: "type",
    title: "Tipo",
    // minWidth: 300,
    // allowCopy: true
  },
  {
    key: "status",
    title: "Estatus",
    minWidth: 100
  },
  {
    key: "pdf_file",
    title: "PDF",
    minWidth: 60,
    hideArrow: true
  },
  {
    key: "xml_file",
    title: "XML",
    minWidth: 60,
    hideArrow: true
  }
];

const TODAY = new Date();

class BillingReportView extends React.Component {
  state = {
    isLoading: false,
    buttonFilterName: 'Aplicar',
    limit: 50,
    offset: 1,
    filterDate: [
      new Date(TODAY.getFullYear(), TODAY.getMonth(), 1),
      new Date(TODAY.getFullYear(), TODAY.getMonth() + 1, 0)
    ],
    filterRFC: [],
    textSearch: '',
    selectionModeOn: false,
    selectedItems: [],
    loadingXLSX: false,
    loadingPDF: false,
    loadingXML: false,
    shownItems: [],
    msgError: ''
  }

  componentDidMount() {
    this.loadReport();
    this.props.listFilterRfc({
      company_id: this.props.companyId
    });
    this.props.setNavbarAction("billing-report", [
      {
        icon: <HelperFilesModal
          files={[]}
        />
      }
    ]);
  }

  componentWillUnmount() {
    this.props.removeNavbarAction("billing-report");
  }

  loadReport = () => {
    this.props.cancelRequest("COMPANIES.INVOICES.REPORT");
    this.setState({isLoading: true});
    let start_date = moment(this.state.filterDate[0]).format('YYYY-MM-DD');
    let end_date = moment(this.state.filterDate[1] || this.state.filterDate[0]).format('YYYY-MM-DD');
    this.props.loadReport({
      company_id: this.props.companyId,
      limit: this.state.limit,
      offset: this.state.offset,
      start_date,
      end_date,
      fiscal_number: this.state.filterRFC,
    },{
      onSuccess: () => {
        this.setState({isLoading: false});
      }
    });
  }

  openDocument = (url) => {
    // window.open(url, "_blank");
    // const link = document.createElement("a");
    // link.href = url;
    let array = url.split("/");
    let lastSegment = array.length > 0 ? array[array.length - 1] : ".";
    // link.setAttribute("download", lastSegment);
    // document.body.appendChild(link);
    // link.click();

    fetch(url)
      .then(response => response.blob())
      .then(blob => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = lastSegment;
          link.click();
      })
      .catch(response => {
        let error = (response.errors && response.errors.length > 0) ? response.errors.join() : response.error || 'Ocurrió un error, intente de nuevo';
        this.setState({msgError: error});
      });
  };

  getColor = (row) => {
    if(row.status !== "Vigente"){
      return "#dc1111"
    } else {
      if(row.type === "Prefactura"){
        return "#dcdcdc"
      } else {
        return "#3d77f7"
      }
    }
  }

  formatData = (row) => {
    let date = this.parseDate(row.created);
    let pdf = row.pdf_file 
      ? <div 
          style={{ cursor: "pointer"}} 
          onClick={() => {
            this.openDocument(row.pdf_file);
          }}
        >
          <PDFIcon 
            height="23" 
            width="23"
            fill={this.getColor(row)}
          />
        </div>
      : '';
    let xml = row.xml_file 
      ? <div 
          style={{ cursor: "pointer"}} 
          onClick={() => {
            this.openDocument(row.xml_file);
          }}
        >
          <XMLIcon  
            height="23" 
            width="23"
            fill={this.getColor(row)}
          />
        </div>
      : '';
    let uuid = row.uuid;
    // if(uuid){
    //   uuid = <div>
    //     {row.uuid}
    //   </div>
    // }
    return {
      ...row,
      created: date,
      pdf_file: pdf,
      xml_file: xml,
      uuid
    }
  }

  parseDate = (dateStr) => {
    return moment(dateStr).format('YYYY-MM-DD HH:mm')
  }

  setSearchStr = e => {
    this.setState({
      textSearch: e.target.value || '',
      offset: 1
    });
  }

  getFilterContent = () => {
    return [
      {
        title: "Fecha",
        name: "filterDate",
        listType: FILTER_LIST_TYPES.dateRangeFixed,
      },
      {
        title: "RFC emisores",
        name: "filterRFC",
        items: this.props.filterRFC,
        listType: FILTER_LIST_TYPES.checkExclusive
      }
    ]
  }

  manageFilter = e => {
    this.setState({[e.target.name]: e.target.value, buttonFilterName: "Aplicar"});
  }

  selectAllItems = () => {
    if(this.state.shownItems.length === this.state.selectedItems.length){
      this.setState({selectedItems: []})
    } else {
      this.setState({selectedItems: [...this.state.shownItems]});
    }
  }

  getToken = () => {
    return {
      Authorization:
        "Bearer " +
        (JSON.parse(sessionStorage.getItem("orbinetwork_session")) || {})
          .access,
    };
  };

  getUrl = (type) => {
    let start_date = moment(this.state.filterDate[0]).format('YYYY-MM-DD');
    let end_date = moment(this.state.filterDate[1] || this.state.filterDate[0]).format('YYYY-MM-DD');
    
    switch(type){
      case 'xlsx':
        return `${SERVER}/api/v2/companies/${this.props.companyId}/invoices/?limit=${this.state.limit}&offset=${this.state.offset}&start_date=${start_date}&end_date=${end_date}&fiscal_number=${this.state.filterRFC}&download=1`;
      case 'pdf':
        return `${SERVER}/api/v2/companies/${this.props.companyId}/invoices/?limit=${this.state.limit}&offset=${this.state.offset}&start_date=${start_date}&end_date=${end_date}&fiscal_number=${this.state.filterRFC}&download=2&id=${this.state.selectedItems}&download_type=${2}`;
      case 'xml':
        return `${SERVER}/api/v2/companies/${this.props.companyId}/invoices/?limit=${this.state.limit}&offset=${this.state.offset}&start_date=${start_date}&end_date=${end_date}&fiscal_number=${this.state.filterRFC}&download=2&id=${this.state.selectedItems}&download_type=${1}`;
      default: 
        return ''
    }
  };

  getFileName = (type) => {
    switch(type){
      case 'xlsx':
        return 'Facturas.xlsx';
      case 'pdf':
        return 'PDFs.zip';
      case 'xml':
        return 'XMLs.zip';
      default:
        return 'Facturas.zip';
    }
  }

  downloadFile = (type) => {
    let url = this.getUrl(type);
    // const source = Axios.CancelToken.source();
    // this.setState({
    //   // loading: true,
    //   downloadingFile: true, 
    // }, () => {
    switch(type){
      case 'xlsx':
        this.setState({loadingXLSX: true});
        break;
      case 'pdf':
        this.setState({loadingPDF: true});
        break;
      case 'xml':
        this.setState({loadingXML: true});
        break;
      default: 
    }
    Axios({
      url: url,
      method: "GET",
      responseType: "blob",
      // cancelToken: source,
      headers: this.getToken(),
    }).then((response) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", this.getFileName(type));
      document.body.appendChild(link);
      link.click();
      switch(type){
        case 'xlsx':
          this.setState({loadingXLSX: false});
          break;
        case 'pdf':
          this.setState({loadingPDF: false});
          break;
        case 'xml':
          this.setState({loadingXML: false});
          break;
        default: 
      }
    }).catch((response) => {
      let error = (response.errors && response.errors.length > 0) ? response.errors.join() : response.error || 'Ocurrió un error, intente de nuevo';

      switch(type){
        case 'xlsx':
          this.setState({loadingXLSX: false, msgError: error});
          break;
        case 'pdf':
          this.setState({loadingPDF: false, msgError: error});
          break;
        case 'xml':
          this.setState({loadingXML: false, msgError: error});
          break;
        default: 
      }
    });
    // });
  }

  updateShownItems = items => {
    this.setState({
      shownItems: items.map(i => i.id)
    })
  }

  render() {
    return (
      <View className="billing-report-view">
        <ViewTitle>
          <div style={{flex: "1"}}>
            Reporte de facturación
          </div>
          <FilterBarCompact
              filtered={false}
              searchAction={this.setSearchStr}
              filterAction={() => {
                if(this.state.buttonFilterName === "Aplicar"){
                  this.setState({ buttonFilterName: "Restablecer", offset: 1 }, () => {this.loadReport()});
                } else {
                  this.setState({
                    limit: 50,
                    offset: 1,
                    filterDate: [
                      new Date(TODAY.getFullYear(), TODAY.getMonth(), 1),
                      new Date(TODAY.getFullYear(), TODAY.getMonth() + 1, 0)
                    ],
                    filterRFC: [],
                    buttonFilterName: "Aplicar"
                  }, () => {this.loadReport()});
                }
              }}
              searchStr={this.state.textSearch}
              content={this.getFilterContent()}
              selectedItems={{
                filterDate: this.state.filterDate,
                filterRFC: this.state.filterRFC
              }}
              buttonFilterName={this.state.buttonFilterName}
              onChange={this.manageFilter}
              showTextSearch={false}
          />
        </ViewTitle>
        <div className="select-option">
          <span onClick={() => this.setState({selectionModeOn: !this.state.selectionModeOn, selectedItems: []})}>
            {
              !this.state.selectionModeOn
              ? 'Modo seleccionar varios'
              : 'Salir de modo de selección'
            }
          </span>
        </div>
        <TableSortable
          isLoading={this.state.isLoading}
          itemsToHide={[]}
          didSelectRow={(data) => {
            if(this.state.selectedItems.includes(data.id)){
              this.setState({selectedItems: this.state.selectedItems.filter(sI => sI !== data.id)})
            } else {
              this.setState({selectedItems: this.state.selectedItems.concat(data.id)});
            }
          }}
          columns={columnsData}
          items={this.props.items.map(this.formatData)}
          selectionModeOn={this.state.selectionModeOn}
          selectedItems={this.state.selectedItems}
          updateShownItems={this.updateShownItems}
        />
        <PaginatorControlled
          itemCount={this.props.itemCount}
          onChange={(e) =>
            this.setState({ [e.target.name]: e.target.value }, () => {
              this.loadReport()
            })
          }
          limit={this.state.limit}
          offset={this.state.offset}
          limitOptions={[
            {
              id: 50,
              description: "50",
            },
            {
              id: 100,
              description: "100",
            },
          ]}
        />
        <CatalogSelectionFooter
            show={this.state.selectionModeOn}
            selectedElements={this.state.selectedItems.length}
            selectAll={this.selectAllItems}
            actions={[
              {
                description: 'Descargar PDFs',
                f: () => {this.downloadFile('pdf')},
                loading: this.state.loadingPDF,
                disabled: this.state.loadingPDF || !this.state.selectedItems.length,
                error: !this.state.selectedItems.length ? 'Seleccione las facturas' : ''
              },
              {
                description: 'Descargar XMLs',
                f: () => {this.downloadFile('xml')},
                loading: this.state.loadingXML,
                disabled: this.state.loadingXML || !this.state.selectedItems.length,
                error: !this.state.selectedItems.length ? 'Seleccione las facturas' : ''
              }, 
              {
                description: "Descargar lista de facturas",
                f: () => {this.downloadFile('xlsx')},
                loading: this.state.loadingXLSX,
                disabled: this.state.loadingXLSX
              },
            ]}
          />
        
        <ErrorDialog
          open={this.state.msgError !== ''}
          message={this.state.msgError}
          acceptText="OK"
          acceptAction={() => this.setState({msgError: ''})}
          closeAction={() => this.setState({msgError: ''})}
        />
      </View>
    )
  }
}

const mapStateToProps = (state) => {
  let companyId = state.globalCatalog.session.company.id;
  let items = getEntityItems(state, "COMPANIES.INVOICES.REPORT")['results'] || [];
  let itemCount = getEntityItems(state, "COMPANIES.INVOICES.REPORT")['count'] || 0;
  let filterRFC = getEntityItems(state, "COMPANIES.INVOICES.REPORT.FILTERS.RFC").map(r => ({id: r.data?.fiscal_number || '-', description: r.data?.fiscal_number || '-'}));

  return {
    companyId,
    items,
    filterRFC,
    itemCount
  };
}

const mapDispatchToProps = (dispatch) => {
  
  return {
    cancelRequest: (entityPath) => dispatch(cancelRequest(entityPath)),
    loadReport: (params, opt) => dispatch(readEntities("COMPANIES.INVOICES.REPORT", params, opt)),
    listFilterRfc: (params, opt) => dispatch(readEntities("COMPANIES.INVOICES.REPORT.FILTERS.RFC", params, opt)),
    setNavbarAction: (name, config) => dispatch(setNavbarAction(name, config)),
    removeNavbarAction: (name) => dispatch(removeNavbarAction(name)),
  }

};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BillingReportView);