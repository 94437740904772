import React from 'react';
import { getUrlsEnv } from '../../../redux/api/api-urls';
import Button from '../../../shared/controls/button';
import Chip from '../../../shared/controls/chip';
import FileSelector, { acceptTypes } from '../../../shared/controls/file-selector';
import RadioInput from '../../../shared/controls/radio-input';
import AttachIcon from '../../../shared/icons/orders/AttachIcon';
import FavMarker from '../../../shared/icons/orders/FavMarker';
import SendIcon from '../../../shared/icons/orders/SendIcon';
import SVGIcon, { icons } from '../../../shared/svg-icon';
import styled from 'styled-components'
import moment from "moment";
import { MentionsInput, Mention } from 'react-mentions'
import mentionStyle from './mention-style'
import { connect } from "react-redux";
import { getEntityItems, readEntities } from '../../../redux/api';
import { cancelMultipleRequests } from '../../../redux/api/actions';

class CommentInput extends React.Component {
  constructor(props){
    super(props);

    // menu type
    // 0.- main menu
    // 1.- cambio cita carga
    // 2.- cambio cita descarga

    this.state = {
      showInput: !props.newConversation,
      menuType: 0,
      appointment_comment: '',
      mention_values: '',
      users_mentioned: [],
      users_mentioned_comments: [],
      currentPlainComment: ''
    }

    this.textAreaTemp = null;
    this.sendCommentFlag = false;
  }

  getDescription = (f) => {
    if (f.size) {
      let size = f.size;
      let magnitude = ["B", "KB", "MB", "GB"];
      let magCount = 0;
      while (size > 1024) {
        size /= 1024;
        magCount++;
      }
      return (f.name.length > 10
        ? f.name.substring(0, 6).concat("...")
        : f.name
      ).concat(" (", size.toFixed(2), magnitude[magCount], ")");
    } else {
      return String(f.name).length > 10
        ? String(f.name).substring(0, 10) + "... "
        : f.name;
    }
  };

  handleChangeLocal = (e) => {

    if (this.sendCommentFlag) {

      if (this.state.currentComment !== '') {
        if (this.props.newConversation) {
          this.props.sendComment({users: this.state.users_mentioned_comments, message: this.state.currentPlainComment});
        } else {
          this.props.sendComment({users: this.state.users_mentioned_comments, reply_message: this.state.currentPlainComment});
        }
        this.textAreaTemp = null;
        this.sendCommentFlag = false;
        this.setState({menuType: 0, showInput: false, users_mentioned: [], users_mentioned_comments: [], currentPlainComment: '', currentComment: ''});
      }

    } else {
      this.textAreaTemp = e;
      this.props.handleChange(e);
    }

  }

  getCommentInput = () => {
    return(
      <div className="comment comment-input">
        <div className="fav-comments" style={{display: this.props.showFavComments ? "" : "none"}}>
          <div className="panel-title">
            Mensajes favoritos
          </div>
          <div className="message-container">
            {
              this.props.favComments.map(fC => (
                <div className="option">
                  <RadioInput 
                    title={fC.comment}
                    onClick={() => this.props.handleChange({target: {name: "favMsg", value: fC.id}})}
                      // this.setState({
                      //   favMsg: fC.id,
                      // })}
                    selected={this.props.favMsg}
                    value={fC.id}
                    name="selectedFavComment"
                  />
                  <img 
                    alt="" 
                    src="/images/bin.png" 
                    onClick={() => this.props.deleteFavoriteComment(fC.id)}
                  />
                  {/* <img alt="" src="/images/edit-transparent.svg"/> */}
                </div>
              ))  
            }
          </div>
          <div className="new-container">
            <RadioInput 
              title={""}
              onClick={() => this.props.handleChange({target: {name: "favMsg", value: -1}})}
                // this.setState({
                //   favMsg: -1,
                // })}
              selected={this.props.favMsg}
              value={-1}
              name="selectedFavComment"
            />
            <textarea 
                name="newFav"
                value={this.props.newFav}
                onChange={this.props.handleChange}
                style={{resize: "none"}}
                placeholder="Nuevo mensaje favorito"
              />
          </div>
          <div className="actions">
            <Button 
              text={'Aplicar'}
              type={'primary'}
              onClick={this.props.sendFav}
            />
          </div>
        </div>
        <div className="avatar-container">
          <img
            className={this.props.userData.profile_image ? "user-avatar" : ""}
            src={this.props.userData.profile_image
              ? getUrlsEnv().files.concat(this.validate_profile_image_url(this.props.userData.profile_image))
              : "/images/menu/user-no-circle.svg"
            }
            alt=""
            width={this.props.userData.profile_image ? "35px" : "29px"}
          />
        </div>
        <div style={{width: 'calc(100% - 100px)', height: '100%'}}>
          <MentionsInput
            style={mentionStyle}
            value={this.state.currentComment}
            onChange={(e, newValue, newPlainTextValue, mentions) => {
              e.target.name = 'currentComment';

              let mentionsId = mentions.map(item => parseInt(item.id));

              this.setState({ currentComment: e.target.value, currentPlainComment: newPlainTextValue, users_mentioned_comments: mentionsId}, () => {
                this.handleChangeLocal(e);
              });
            }}
            placeholder={this.props.newConversation ? "Nueva conversación" : "Dejar un comentario"}
            allowSuggestionsAboveCursor={true}
            onKeyDown={ e => {
              if (e.keyCode === 13 && !e.shiftKey) {
                this.sendCommentFlag = true;
              }
            }}
          >

            <Mention
              displayTransform={(id, display) => `@${display.replaceAll(' ', '')}`}
              appendSpaceOnAdd
              trigger="@"
              data={ (query, callback) => {

                if (query.length < 3) {
                  callback([]);
                }

                this.props.cancelMultipleRequests();
                this.props.getUsers({
                  order_id: this.props.order_id,
                  name: `${query}`,
                  isMultipleCancels: true
                },{
                  onSuccess: (r) => {
                    callback(
                      (r.users || []).map( item => {
                        item.display = item.full_name;
                        return item;
                      })
                    );
                  }
                })
              }}
              style={{
                color: 'blue',
              }}
            />
          
          </MentionsInput>
        </div>

        {/* <textarea
          name="currentComment"
          value={this.props.currentComment}
          onChange={this.handleChangeLocal}
          style={{
            resize: 'none',
            border: '2px solid #EBEEF0',
            borderRadius: 8,
            paddingTop: 8,
            paddingLeft: 8
          }}
          placeholder={this.props.newConversation ? "Nueva conversación" : "Dejar un comentario"}
        /> */}
        {
          this.props.commentFiles.map((cF, i) => {
            return <Chip
              key={i}
              id={i}
              active={true}
              className="white small"
              name="files"
              description={this.getDescription(cF)}
              onClick={() => {}}
              prefix={{
                element: (
                  <SVGIcon
                    name={icons.imageFile}
                    fill="#657795"
                    viewBox="0 -1 16 16"
                  />
                ),
              }}
              suffix={{
                element: (
                  <div onClick={() => this.props.handleRemoveDocument()}>
                    {" "}
                    <SVGIcon
                      name={icons.cross}
                      fill="#A7B6D6"
                      viewBox="0 3 16 16"
                    />
                  </div>
                ),
              }}
            />
          })
        }
        <FileSelector
          name={"files"}
          acceptTypes={[acceptTypes.imagesAndPdf]}
          onChange={this.props.handleAddDocument}
          // allowMultiple={true}
        >
          <AttachIcon style={{display: ""}} className="attach-icon"/>
        </FileSelector>
        <SendIcon className="send-icon" onClick={() => {
          if (this.props.newConversation) {
            this.props.sendComment({users: this.state.users_mentioned_comments, message: this.state.currentPlainComment});
          } else {
            this.props.sendComment({users: this.state.users_mentioned_comments, reply_message: this.state.currentPlainComment});
          }

          this.setState({menuType: 0, showInput: false, users_mentioned: [], users_mentioned_comments: [], currentPlainComment: '', currentComment: ''});
        }}/>
        <FavMarker style={{display: ""}} className="fav-icon" onClick={() => this.props.handleChange({target:{name:"showFavComments", value: !this.props.showFavComments}})}/>
      </div>
    );
  }

  transformComment = (comment) => {

    let componentsToShow = [];
    let commentArray = comment.split(' ');

    commentArray.map( item => {
      if (item.includes('#') || item.includes('@')) {
        componentsToShow.push(<div style={{color: 'blue'}}>{`${item}`}</div>);
      } else {
        componentsToShow.push(`${item}`);
      }
      componentsToShow.push(<div style={{width: 6}}/>);
    });

    return(
      <div style={{display: 'flex'}}> {componentsToShow} </div>
    );

  }

  getMenu = () => {
    if (this.state.menuType === 0) {
      return this.getMainMenuComments();
    } else {
      return this.getLoadAppointment();
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleMentionChange = (e) => {
    this.setState({ mention_values: e.target.value });
  }

  buildShortAppointmentMessage = () => {
    let requestText = this.props.infoData.operation === 'Carga' ? '#SolicitudCambioCitaCarga' : '#SolicitudCambioCitaDescarga';
    let dateText = `${moment(this.props.infoData.date).format('HH:mm DD/MMM/YYYY')}`
    let motiveText = `#Motivo${this.props.infoData.motive.label.replaceAll(' ','')}`

    return `${requestText} a ${dateText} ${motiveText}`
  }

  buildAppointmentMessage = (users) => {
    let requestText = this.props.infoData.operation === 'Carga' ? '#SolicitudCambioCitaCarga' : '#SolicitudCambioCitaDescarga';
    let dateText = `${moment(this.props.infoData.date).format('HH:mm DD/MMM/YYYY')}`
    let motiveText = `#Motivo${this.props.infoData.motive.label.replaceAll(' ','')}`

    let users_text = '';
    (users || []).map(item => {
      let fullName = `${item.full_name}`.replaceAll(' ', '');
      users_text = `@${fullName} ${users_text}`
    });

    return `${requestText} a ${dateText} ${motiveText} ${users_text} ${this.state.appointment_comment}`
  }

  validate_profile_image_url = (profile_image) => {

    if (typeof profile_image === 'string' && profile_image.length > 0 && profile_image[0] === '/') {
      return profile_image.substring(1);
    } else {
      return profile_image;
    }
  
  }

  getLoadAppointment = () => {
    return(
      <LoadMenuContainer>
        <div style={{height: 60, width: '10%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <img
            className={this.props.userData.profile_image ? "user-avatar" : ""}
            src={this.props.userData.profile_image
              ? getUrlsEnv().files.concat(this.validate_profile_image_url(this.props.userData.profile_image))
              : "/images/menu/user-no-circle.svg"
            }
            alt=""
            width={"48px"}
            style={{borderRadius: '50%'}}
          />
        </div>
        <div style={{height: 60, width: '10%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>

          {
            this.props.infoData &&
            <MotiveInfo>
              {moment(this.props.infoData.date).format('DD MMM HH:mm')}
            </MotiveInfo>
          }
          
          <MainMenuButton
            onClick={() => {
              if (this.props.showAssignAppointment) {
                this.props.showAssignAppointment(this.state.menuType === 1)
              }
            }}
          >{this.state.menuType === 1 ? 'Cita de carga' : 'Cita de descarga'}</MainMenuButton>
        </div>
        <div style={{height: 60, width: '10%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <MotiveInfo>
            {this.props.infoData?.motive?.label ?? ''}
          </MotiveInfo>
        </div>
        <div style={{height: 48, width: '25%', display: 'grid', marginRight: 25, marginLeft: 25}}>
          <MentionsInput 
            style={mentionStyle}
            value={this.state.mention_values}
            onChange={this.handleMentionChange}
            placeholder={"Añade usuarios"}
            allowSuggestionsAboveCursor={true}
          >
            <Mention
              displayTransform={(id, display) => `@${display.replaceAll(' ', '')}`}
              appendSpaceOnAdd
              trigger="@"
              data={ (query, callback) => {

                if (query.length < 3) {
                  callback([]);
                }

                this.props.cancelMultipleRequests();
                this.props.getUsers({
                  order_id: this.props.order_id,
                  name: `${query}`,
                  isMultipleCancels: true
                },{
                  onSuccess: (r) => {
                    callback(
                      (r.users || []).map( item => {
                        item.display = item.full_name
                        return item;
                      })
                    );
                  }
                })
              }}
              style={{
                color: 'blue',
              }}
              onAdd={(id, display, startPos, endPos) => {
                let user_temp = (this.props.users_found.users || []).filter(item => `${item.id}` === `${id}`)

                if (user_temp.length > 0) {
                  this.state.users_mentioned.push(user_temp[0]);
                }
              }}
            />
          </MentionsInput>
        </div>
        <div style={{height: 48, width: '35%', display: 'grid'}}>
          <textarea
            name="appointment_comment"
            value={this.state.appointment_comment}
            onChange={this.handleChange}
            style={{
              resize: "none",
              border: '2px solid #EBEEF0',
              borderRadius: 8,
            }}
            placeholder={"Dejar un comentario (Opcional)"}
          />
        </div>
        <div style={{height: 60, width: '10%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <MainMenuButton
            style={(this.props.infoData === null || this.state.users_mentioned.length === 0) ? {color: 'gray', border: '1px solid gray'} : {}}
            onClick={() => {
              if (this.props.infoData !== null && this.state.users_mentioned.length !== 0 && this.props.sendCommentAppointment) {
                let users_mentioned = this.state.users_mentioned.filter(item => this.state.mention_values.includes(item.full_name));
                let message_to_send = this.buildAppointmentMessage(users_mentioned);
                let short_message = this.buildShortAppointmentMessage()
                
                this.props.sendCommentAppointment(message_to_send, users_mentioned, short_message);

                if (this.props.resetInfo) {
                  this.props.resetInfo(); 
                }

                this.setState({menuType: 0, showInput: false, users_mentioned: [], users_mentioned_comments: [], currentPlainComment: '', currentComment: '', mention_values: '', appointment_comment: ''});
              }
            }}
          >{'Enviar'}</MainMenuButton>

          <div style={{width: 8}}/>

          <MainMenuButton
            onClick={() => {
              this.setState({menuType: 0}); 
              if (this.props.resetInfo) {
                this.props.resetInfo();
              }
            }}
          >{'Cancelar'}</MainMenuButton>
        </div>
      </LoadMenuContainer>
    );
  }

  getMainMenuComments = () => {
    return(
      <MainMenuContainer>

        <MainMenuButton
          onClick={() => this.setState({showInput: true})}
        >{'Nueva conversación'}</MainMenuButton>

        {
          (this.props.isAbleToChangeAppointment || false) && 
          <>
            <MainMenuLink
              onClick={() => this.setState({menuType: 1})}
            >
              <div
                style={{
                  textDecoration: 'underline'
                }}
              >{'Solicitud de cambio de cita de carga'}</div>
              <RequestText style={{visibility: this.props.isCargoOwner ? 'visible' : 'hidden'}}>{`Pendientes: ${this.props.requests.load.pending} Aceptados: ${this.props.requests.load.accepted}  Cancelados: ${this.props.requests.load.cancelled}`}</RequestText>

            </MainMenuLink>

            <MainMenuLink
              onClick={() => this.setState({menuType: 2})}
            >
              <div
                style={{
                  textDecoration: 'underline'
                }}
              >{'Solicitud de cambio de cita de descarga'}</div>

              <RequestText style={{visibility: this.props.isCargoOwner ? 'visible' : 'hidden'}}>{`Pendientes: ${this.props.requests.unload.pending} Aceptados: ${this.props.requests.unload.accepted}  Cancelados: ${this.props.requests.unload.cancelled}`}</RequestText>
            </MainMenuLink> 
          </>
        }

      </MainMenuContainer>
    );
  }

  render() {
    return (
      this.state.showInput ? 
      <div
        style={{display: 'flex'}}
      >
        <div style={{width: '100%'}}>{this.getCommentInput()}</div>
        {
          this.props.newConversation &&
          <CloseButton
            onClick={() => this.setState({showInput: false})}
          >
            <img 
              src='/images/close-icon.svg' 
              alt='' 
              style={{width: 20, height: 20}}
            />
          </CloseButton>
        }
      </div> : 
      this.getMenu()
    )
  }
}

const mapStateToProps = (state) => {
  const users_found = getEntityItems(state, "ORDER.USERS")

  return {
    users_found
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: (params, opt) => dispatch( readEntities("ORDER.USERS", params, opt) ),
    cancelMultipleRequests: () => dispatch(cancelMultipleRequests())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommentInput);

const LoadMenuContainer = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
`;

const MotiveInfo = styled.div`
  color: rgb(61, 119, 247);
  text-decoration: underline;
  text-align: center;
  font-size: 15px;
  }
`;

const CloseButton = styled.div`
  height: 20px;
  width: 20px;
  margin-top: 15px;
  margin-left: 10px;
  margin-right: 20px;
  &:hover {
    cursor: pointer;
  }
`;

const MainMenuContainer = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
`;

const MainMenuButton = styled.div`
  border-radius: 8px;
  border: 1px solid rgb(61, 119, 247);
  padding: 5px;
  color: rgb(61, 119, 247);
  font-size: 15px;
  &:hover {
    font-size: 16px;
    cursor: pointer;
  }
`;

const MainMenuLink = styled.div`
  color: rgb(61, 119, 247);
  margin-left: 40px;
  font-size: 15px;
  &:hover {
    font-size: 16px;
    cursor: pointer;
  }
`;

const RequestText = styled.div`
  color: gray;
  font-size: 12px;
`;